import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveTrades } from 'pages/trading-terminal-page/trading-chart/api/get-active-trades';
import { SmartTrade } from '../types/new-terminal-schema';

export const setActiveTrades = createAsyncThunk<SmartTrade[], void, { rejectValue: string; }>(
  'newTerminal/setActiveTrades',
  async (_, {
    rejectWithValue, 
  }) => {
    try {
      const trades = await getActiveTrades();
      
      return trades as SmartTrade[];
    } catch (error) {
      return rejectWithValue('error');
    }
  },
);
