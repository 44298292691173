import { Box } from '@mui/material';
import { createDemoExchangeAccount } from 'entities/exchange/api/create-demo-exchange-account';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { getExchangeData } from 'entities/exchange/model/selectors/get-exchange-data/get-exchange-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount, ExchangeCode } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { deleteExchange } from 'features/delete-exchange/api/delete-exchange';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlatformCard } from 'widgets';
import { EMPTY_ARRAY } from 'shared/consts/common-constants';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { useQuery } from 'shared/hooks';
import { PlusGreen } from 'shared/icons';
import { MainButton, Title } from 'shared/ui';
import {
  ConnectNewExchange,
  EmptySubscription,
} from 'shared/ui/modals';
import { UpdateExchange } from 'shared/ui/modals/update-exchange';
import { connectButton, list, title, titleWrapper, wrapper } from '../styles';
import { UpdateApiKeysModalProps } from '../types/update-keys.types';

const getExchangeName: Record<ExchangeCode, string> = {
  binance: 'Binance',
  bybit: 'Bybit',
  okx: 'OKX',
  gateio: 'Gate.io',
  'crypto-com': 'Crypto.com',
  demo: 'Demo',
  bitmart: 'BitMart',
};

interface ConnectedExchangeAccountListPageProps {
  exchangeConnectionStatus?: boolean;
  exchangeConnectionMessage?: string;
}

export const ConnectedExchangeAccountListPage = (props: ConnectedExchangeAccountListPageProps) => {
  const {
    exchangeConnectionStatus,
    exchangeConnectionMessage,
  } = props;

  const [exchangeToUpdate, setExchangeToUpdate] = useState<string>('');
  const [updateApiKeysModalOpen, setUpdateApiKeysModalOpen] = useState<boolean>(false);
  const [exchangeToDelete, setExchangeToDelete] = useState<string | null>(null);
  const [exchangeUuidToUpdateApiKeys, setExchangeUuidToUpdateApiKeys] = useState<string>('');
  const [exhangeTitleToUpdateApiKeys, setExhangeTitleToUpdateApiKeys] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEmptySubscriptionsOpen, setIsEmptySubscriptionsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalDescription, setModalDescription] = useState<string>('');
  const [modalButtonTitle, setModalButtonTitle] = useState<string>('');
  const [modalButtonAction, setModalButtonAction] = useState<any>(null);
  const [modalType, setModalType] = useState<'error' | 'success'>('success');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const isCryptoComConnected = query.get('mt') === 'crypto-connected';
  const isCryptoComUpdated = query.get('mt') === 'crypto-updated';

  const connectedExchanges = useSelector(getExchangeData);
  const isDemoMode = useSelector(getIsDemoMode);

  const isDemoAccount = useMemo(() => {
    return connectedExchanges.find((connectedExchange) => (connectedExchange.exchangeAccountUuid === exchangeToDelete) && connectedExchange.exchangeCode === 'demo');
  }, [connectedExchanges, exchangeToDelete]);

  const handleCloseEmptySubscriptionsModal = () => setIsEmptySubscriptionsOpen(false);

  useEffect(() => {
    if (exchangeConnectionStatus === true) {
      handleOpenEmptySubscriptionsModal(
        'Account connected',
        'Pro-level trading with limitless opportunities is just a click away!',
        'Okay',
        () => {
          setIsEmptySubscriptionsOpen(false);
        },
      );
    } else if (exchangeConnectionStatus === false) {
      let text = '';
      switch (exchangeConnectionMessage) {
      case 'failed to create account':
        text = 'Account should have balance and no other active Fast API';
        break;
      case 'Maximum number of active accounts reached. Please upgrade subscription':
        text = 'Maximum connected accounts reached, upgrade subscription';
        break;
      default:
        text = exchangeConnectionMessage || '';
        break;
      }

      handleOpenEmptySubscriptionsModal(
        'Connection failed',
        text,
        'Okay',
        () => {
          setIsEmptySubscriptionsOpen(false);
        },
        true,
      );
    }
  }, [exchangeConnectionStatus]);

  const handleAddExchangeModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenEmptySubscriptionsModal = (title: string, description: string, buttonTitle: string, buttonAction?: () => void, isError?: boolean) => {
    setModalTitle(title);
    setModalDescription(description);
    setModalButtonTitle(buttonTitle);
    setIsEmptySubscriptionsOpen(true);
    setModalButtonAction(() => {
      return buttonAction;
    });
    setModalType(isError ? 'error' : 'success');
  };

  const handleOpenDeleteModal = (exchangeAccountUuid: string) => {
    setExchangeToDelete(exchangeAccountUuid);

    handleOpenEmptySubscriptionsModal(
      'Delete account?',
      'Ensure related bots are deleted and trades are completed',
      'Delete',
      () => {
        setIsEmptySubscriptionsOpen(false);
        isDemoAccount ? handleDeleteDemoExchange(exchangeAccountUuid) : handleDeleteExchange(exchangeAccountUuid);
      },
    );
  };

  const handleOpenUpdateApiKeysModal = (props: UpdateApiKeysModalProps) => {
    const {
      exchangeAccountUuid, exchangeCode, exchangeTitle, 
    } = props;
    setExchangeUuidToUpdateApiKeys(exchangeAccountUuid);
    setExhangeTitleToUpdateApiKeys(exchangeTitle);
    setExchangeToUpdate(exchangeCode); 
    setUpdateApiKeysModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setExchangeToDelete(null);
    setIsEmptySubscriptionsOpen(false);
  };

  const handleCloseUpdateApiKeysModal = () => {
    setUpdateApiKeysModalOpen(false);
  };

  const handleDeleteExchange = async (exchangeAccountUuid: string) => {
    if (exchangeToDelete || exchangeAccountUuid) {
      try {
        const deleteExchangeResponse = await deleteExchange(exchangeToDelete || exchangeAccountUuid);
        handleCloseDeleteModal();

        if (!deleteExchangeResponse.success) {
          let text = '';
          switch (deleteExchangeResponse?.data?.message) {
          case 'Cannot delete account because bots are not deleted':
            text = 'Can’t delete the account because related bots are not deleted';
            break;
          case 'Cannot delete account because there are active positions':
            text = 'Can’t delete the account because there are related active trades';
            break;
          default:
            text = deleteExchangeResponse?.data?.message;
            break;
          }

          handleOpenEmptySubscriptionsModal(
            'Deletion failed',
            text,
            'Okay',
            () => {
              setIsEmptySubscriptionsOpen(false);
            },
            true,
          );
          return;
        }

        handleOpenEmptySubscriptionsModal(
          'Account is deleted',
          'No worries, it\'s just a link to your account. Create a new one!',
          'Okay',
          () => {
            setIsEmptySubscriptionsOpen(false);
          },
        );
        
        dispatch(exchangeActions.deleteExchange(exchangeToDelete || exchangeAccountUuid));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteDemoExchange = async (exchangeAccountUuid: string) => {
    handleCloseDeleteModal();

    const deleteExchangeResponse = await deleteExchange(exchangeToDelete! || exchangeAccountUuid!);
    if (!deleteExchangeResponse.success) {
      toast.error('Cannot delete account');
      return;
    }

    handleOpenEmptySubscriptionsModal(
      'Account is re-created',
      '',
      'Okay',
      () => {
        setIsEmptySubscriptionsOpen(false);
      },
    );

    if (!isDemoAccount) {
      return;
    }

    const demoAccountResponse = await createDemoExchangeAccount();
    if (!demoAccountResponse?.data?.fields) {
      toast.error('Cannot reset demo account');
      return;
    }
    
    const createdAccounts = await setExchangeData(getSkyrexUuid()!);
    dispatch(exchangeActions.setAllExchanges(createdAccounts));
  };

  const handleOpenTerminalPage = () => {
    navigate('/manual-trading/trading-terminal');
  };

  const handleOpenExchangePage = (id: string) => {
    navigate(`/my-accounts/${id}`);
  };

  useEffect(() => {
    if (!(isCryptoComConnected || isCryptoComUpdated)) {
      return;
    }
    
    handleOpenEmptySubscriptionsModal(
      'Request completed',
      'Crypto.com API issued. Contact support if account isn’t linked',
      'Okay',
      () => {
        setIsEmptySubscriptionsOpen(false);
      },
    );
  }, [isCryptoComConnected, isCryptoComUpdated]);
  
  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={title}>My exchange accounts</Title>

        <ConnectNewExchange 
          isModalOpen={isModalOpen} 
          setIsModalOpen={setIsModalOpen} 
        />

        {!isDemoMode && (
          <MainButton 
            type='primary' 
            ghost 
            styles={connectButton} 
            onClick={handleAddExchangeModal}
          >
            <Box 
              display='flex' 
              alignItems='center' 
              gap={1}
            >
              {PlusGreen}
              Connect new exchange
            </Box>
          </MainButton>
        )}
      </Box>

      <UpdateExchange
        isModalView={false}
        platform={exchangeToUpdate}
        isUpdateModalOpen={updateApiKeysModalOpen}
        closeExchangeModal={handleCloseUpdateApiKeysModal}
        exchangeAccountUuid={exchangeUuidToUpdateApiKeys}
        currentExchangeTitle={exhangeTitleToUpdateApiKeys}
      />

      <EmptySubscription 
        isOpen={isEmptySubscriptionsOpen} 
        handleClose={handleCloseEmptySubscriptionsModal}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        modalButtonTitle={modalButtonTitle}
        modalButtonAction={modalButtonAction}
        isError={modalType === 'error'}
      />

      <Box sx={list}>
        {connectedExchanges.map((exchange: ExchangeAccount) => (
          <PlatformCard
            key={exchange.exchangeAccountUuid}
            exchangeCode={exchange.exchangeCode}
            isDemoAccount={exchange.exchangeCode === 'demo'}
            title={exchange.accountName}
            usdtDailyChangePercent={exchange.usdtDailyChangePercent}
            usdtDailyChangeValue={exchange.usdtDailyChangeValue}
            btcDailyChangePercent={exchange.btcDailyChangePercent}
            btcDailyChangeValue={exchange.btcDailyChangeValue}
            description={`${getExchangeName[exchange.exchangeCode]} Spot`}
            totalUsdt={exchange.totalUsdt}
            balance={{
              index: 0,
              max: 100,
            }}
            exchangeAccountUuid={exchange.exchangeAccountUuid}
            total={{
              usd: exchange.totalUsdt ?? 'no value',
              btc: exchange.totalBtc ?? 'no value',
            }}
            balances={exchange.balances ?? EMPTY_ARRAY}
            trade={handleOpenTerminalPage}
            view={handleOpenExchangePage}
            onDelete={handleOpenDeleteModal}
            onUpdateApiKeys={handleOpenUpdateApiKeysModal}
          />
        ))}
      </Box>
    </Box>
  );
};
