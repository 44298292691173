export const LIMIT = 'Limit';
export const LIMIT_VALUE = 'LIMIT';
export const CONDITIONAL = 'Conditional';
export const CONDITIONAL_VALUE = 'CONDITIONAL';
export const MARKET = 'Market';
export const MARKET_VALUE = 'MARKET';
export const CONDLIMIT = 'Cond.Limit';
export const CONDLIMIT_VALUE = 'CONDITIONAL_LIMIT';
export const CONDMARKET = 'Cond.Market';
export const CONDMARKET_VALUE = 'CONDITIONAL_MARKET';
export const USELESS_SWITCHER_AVERAGE = 'Average';
export const USELESS_SWITCHER_FIXED = 'Fixed';


export const BASE_ORDER_TITLE = 'Skip base order (use existing funds)';
export const COINS_TOGGLER = 'coinsToggler';


export const BUY_SIDE = 'BUY';
export const SELL_SIDE = 'SELL';


export const ORDER_PRICE_LABEL = 'Order price';

export const TRIGGER_PRICE_LABEL = 'Trigger price';

// side options

export const SIDE_OPTION_SWITCHER = 'exchangeOption';

export const FIELD_TOTAL = 'total';
export const FIELD_UNITS = 'units';

// base order section

export const BASE_ORDER_SECTION_TYPE = 'baseOrder';

export const BASE_ORDER_ORDER_PRICE_FIELD_NAME = 'baseOrder.orderPrice';
export const BASE_ORDER_TRIGGER_PRICE_FIELD_NAME = 'baseOrder.triggerPrice';



//additional entry section

export const FIRST_ENTRY_SWITCHER = 'firstEntrySwitcher';
export const ADDITIONAL_ENTRY_SECTION_TYPE = 'addEntry';
export const ADDITIONAL_ENTRY_TOGGLER_TITLE = 'Additional entry';
export const SAFETY_ORDERS_LABEL = 'Safety orders';
export const ADDITIONAL_ENTRY_TOGGLER = 'additionalEntryToggler';


//take profit section 
export const TAKE_PROFIT_TOGGLER_TITLE = 'Take profit';
export const TAKE_PROFIT_SECTION_TYPE = 'takeProfit';
export const TAKE_PROFIT_USELESS_SWITCHER = 'takeProfitUselessSwitcher';
export const TAKE_PROFIT_SWITCHER = 'takeProfitSwitcher';
export const TAKE_PROFIT_TOGGLER = 'takeProfitToggler';
export const TAKE_PROFIT_ORDERS_LABEL = 'Take Profit orders';


//stop loss section
export const STOP_LOSS_TOGGLER_TITLE = 'Stop loss';
export const STOP_LOSS_SECTION_TYPE = 'stopLoss';
export const STOP_LOSS_USELESS_SWITCHER = 'stopLossUselessSwitcher';
export const STOP_LOSS_SWITCHER = 'stopLossSwitcher';
export const STOP_LOSS_TOGGLER = 'stopLossToggler';


export const SELECT_OPTIONS = [
  {
    value: 'LAST',
    label: 'Last',
  },
  {
    value: 'BID',
    label: 'Bid',
  },
  {
    value: 'ASK',
    label: 'Ask',
  },
];

export const STOP_LOSS_SWITCHER_OPTIONS = [
  {
    label: CONDLIMIT,
    value: CONDLIMIT_VALUE,
  },
  {
    label: CONDMARKET,
    value: CONDMARKET_VALUE,
  },
];
  
  
export const FIRST_ENTRY_SWITCHER_OPTIONS = [
  {
    label: LIMIT,
    value: LIMIT_VALUE,
  },
  {
    label: CONDLIMIT,
    value: CONDLIMIT_VALUE,
  },
  {
    label: CONDMARKET,
    value: CONDMARKET_VALUE,
  },
];
  
  
export const USELESS_SWITCHER_OPTIONS = [
  {
    label: USELESS_SWITCHER_FIXED,
    value: USELESS_SWITCHER_FIXED,
  },
  {
    label: USELESS_SWITCHER_AVERAGE,
    value: USELESS_SWITCHER_AVERAGE,
  },

];
  
export const TAKE_PROFIT_SWITCHER_OPTIONS = [
  {
    label: LIMIT,
    value: LIMIT_VALUE,
  },
  {
    label: CONDMARKET,
    value: CONDMARKET_VALUE,
  },
];
  
  
export const BOS_SLIDER_DEFAULT_VALUE = 0;
  
export const COINS_EXIST_SLIDER_DEFAULT_VALUE = 100;
  
  
  
export const LABEL_ORDER_PRICE = 'Order price';
  
export const LABEL_BOUGHT_PRICE = 'Bought price';

export const LABEL_SOLD_PRICE = 'Sold price';

export const LABEL_TOTAL = 'Total';

export const LABEL_VOLUME = 'Volume';

export const LABEL_UNITS = 'Units';

export const ADD_TARGET_BUTTON_TITLE = '+ Split target';

export const ADD_ENTRY_BUTTON_TITLE = '+ Add entry';


// validation

export const nonZeroValue = 'Value cannot be zero';


export const BASIC_INPUTS_DEFAULT_SLIDER_VALUE = 10;
