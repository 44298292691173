
import { Box } from '@mui/material';
import { Button } from 'antd';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { SideProps } from 'pages/manual-trading/trading-terminal/types/order-panel.types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ConfirmTransaction, Text, Title, Tooltip } from 'shared/ui';
import { useFormSubmission } from '../../../hooks/use-form-submission';
import { Fields } from '../../fields';
import { AdditionalEntry, StopLoss, TakeProfit } from '../components';
import { useBaseOrderInputsLogic } from '../hooks/base-order-inputs';
import { useAdditionalEntryInputsLogic } from '../hooks/use-additional-entry-inputs';
import { useEntryEffects } from '../hooks/use-entry-effects';
import { useErrorEffects } from '../hooks/use-error-effects';
import { useFormUpdates } from '../hooks/use-form-updates';
import { usePriceEffects } from '../hooks/use-price-effects';
import { useSectionTogglers } from '../hooks/use-section-togglers';
import { useStopLossInputsLogic } from '../hooks/use-stop-loss-inputs';
import { useSymbolEffects } from '../hooks/use-symbol-effects';
import { useTakeProfitInputsLogic } from '../hooks/use-take-profit-inputs';
import { InfoIcon, Wallet } from '../icons';
import { inner, price, priceWrapper, title, tooltipText, wrapper } from '../styles';

export const Buy = (props: SideProps) => {
  const {
    currentSymbol,
    userWallet = {
      free: '0',
    },
    userWalletBaseAsset = {
      free: '0',
    },
    exchangeAccountUuid,
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const limitLastPrice = useSelector(getLimitLastPrice);
    
  const {
    setValue, watch, getValues, setError, trigger, 
  } = useFormContext();
  
  const isSkipBaseOrder = watch('skipBaseOrder');
  
  const {
    formState: {
      isModalVisible, confirmationData, collectedData, 
    },
    handlers: {
      handleFormSubmit, setIsModalVisible, isFormValidExceptAdditionalEntries, 
    },
  } = useFormSubmission({
    isSkipBaseOrder,
    exchangeAccountUuid,
  });
      
  const {
    setIsSkipAdditionalEntry,
    setIsSkipTakeProfit,
    setIsSkipStopLoss,
  } = useSectionTogglers();
  
  // При изменении символа автоматически обновляет:
  // - базовый актив
  // - котируемый актив
  // - сбрасывает дополнительные настройки
  useSymbolEffects({
    currentSymbol,
  });
  
  const orderType = watch('orderType');
  
  const baseOrderInputsLogic = useBaseOrderInputsLogic(
    currentSymbol, 
    userWallet, 
    userWalletBaseAsset, 
    watch, 
    setValue,
    isSkipBaseOrder,
    chartLastPrice,
    limitLastPrice,
  );
  
  const {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
    setInitialValues,
  } = baseOrderInputsLogic;
  
  const additionalEntryInputsLogic = useAdditionalEntryInputsLogic(
    currentSymbol, 
    userWallet, 
    userWalletBaseAsset, 
    watch, 
    setValue,
    getValues,
    setError,
    isSkipBaseOrder,
  );
  
  const {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  } = additionalEntryInputsLogic;
  
  const takeProfitInputsLogic = useTakeProfitInputsLogic(currentSymbol, watch, setValue, getValues, setError);
  
  const {
    handleTakeProfitOrderPricePercentChange,
    handleTakeProfitTriggerPriceChange,
    handleTakeProfitOrderPriceChange,
    handleAddTakeProfitEntry,
    handleRemoveTakeProfitEntry,
    updateTakeProfitValues,
  } = takeProfitInputsLogic;
  
  const stopLossInputsLogic = useStopLossInputsLogic(currentSymbol, watch, setValue);
  
  const {
    handleStopLossOrderPricePercentChange,
    handleStopLossTriggerPriceChange,
    handleStopLossOrderPriceChange,
    updateStopLossValues,
  } = stopLossInputsLogic;
  
  useEntryEffects();
  
  useEffect(() => {
    setInitialValues();
    trigger();
  }, [orderType, chartLastPrice, isSkipBaseOrder, setInitialValues]);
    
  usePriceEffects({
    chartLastPrice,
    limitLastPrice,
    currentSymbol,
    userWallet,
  });
  
  useFormUpdates({
    updateAdditionalEntryValues,
    updateTakeProfitValues,
    updateStopLossValues,
  });
  
  useErrorEffects();
  
  return (
    <>
      <Box sx={wrapper} component='form' onSubmit={handleFormSubmit}>
        <Box sx={inner}>
          <Title styles={title}>
            {isSkipBaseOrder ? `Bought ${currentSymbol?.quoteAsset}` : `Buy ${currentSymbol?.quoteAsset}`}
          </Title>

          <Box sx={priceWrapper}>
            <Box
              width={12}
              height={12}
            >
              {Wallet}
            </Box>

            <Text
              type='success'
              styles={price}
            >
              {!isSkipBaseOrder ? `${
                formatByPrecisionAndTrim(
                  userWallet?.free?.toString(), 
                  currentSymbol?.quoteAssetPrecision, 
                  Number(currentSymbol.minNotional), 
                  Number(currentSymbol.maxNotional),
                )}
                  ${currentSymbol?.quoteAsset}`
                : `${
                  formatByPrecisionAndTrim(
                    userWalletBaseAsset?.free?.toString(),
                    currentSymbol?.baseAssetPrecision,
                    Number(currentSymbol.lotMin),
                    Number(currentSymbol.lotMax),
                  )}
                  ${currentSymbol?.baseAsset}`
              }
            </Text> 

            <Tooltip
              title={(
                <Box maxWidth={188}>
                  <Title
                    level={5}
                    styles={tooltipText}
                  >
                    {currentSymbol?.quoteAsset} amount currently <br /> available on Exchange account
                  </Title>
                </Box>
              )}
            >
              <Box
                width={12}
                height={12}
              >
                {InfoIcon}
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <Fields
          isSkipBaseOrder={isSkipBaseOrder}
          onOrderPriceChange={handleOrderPriceChange}
          onTotalChange={handleTotalChange}
          onUnitsChange={handleUnitsChange}
          onSliderChange={handleSliderChange}
          onTriggerPriceChange={handleTriggerPriceChange}
          onSkipBaseSliderChange={handleSkipBaseSliderChange}
          onBoughtPriceChange={handleBoughtPriceChange}
          onSkipBaseUnitsChange={handleSkipBaseUnitsChange}
          onSkipBaseTotalChange={handleSkipBaseTotalChange}
        />

        <AdditionalEntry
          setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
          onAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
          onAdditionalUnitsChange={handleAdditionalUnitsChange}
          onAdditionalTotalChange={handleAdditionalTotalChange}
          onAdditionalSliderChange={handleAdditionalSliderChange}
          onAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
          onAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
          onAddEntry={handleAddEntry}
          onRemoveEntry={handleRemoveEntry}
        />

        <TakeProfit
          setIsSkipTakeProfit={setIsSkipTakeProfit}
          onTakeProfitOrderPriceChange={handleTakeProfitOrderPriceChange}
          onTakeProfitTriggerPriceChange={handleTakeProfitTriggerPriceChange}
          onTakeProfitOrderPricePercentChange={handleTakeProfitOrderPricePercentChange}
          onAddEntry={handleAddTakeProfitEntry}
          onRemoveEntry={handleRemoveTakeProfitEntry}
        />

        <StopLoss
          setIsSkipStopLoss={setIsSkipStopLoss}
          onStopLossTriggerPriceChange={handleStopLossTriggerPriceChange}
          onStopLossOrderPriceChange={handleStopLossOrderPriceChange}
          onStopLossOrderPricePercentChange={handleStopLossOrderPricePercentChange}
        />
        
        <Button 
          htmlType='submit'
          type='primary'
          disabled={!isFormValidExceptAdditionalEntries()}
        >
          Create trade
        </Button>
      </Box>
    
      <ConfirmTransaction
        isOpened={isModalVisible}
        closeHandler={() => setIsModalVisible(false)}
        confirmationTableData={confirmationData}
        collectedDataForSubmit={collectedData}
      />
    </>
  );
};

