// @ts-nocheck
import React from 'react';
import SliderMark from './SliderMark';
import {
  currentExchangePriceStyles,
  getReturnPercentLabelsColor,
} from './tradesConstants.js';

interface Order {
  viewType: string;
  status: string;
  orderType: string;
  limitPrice?: string;
  triggerPrice?: string;
}

interface SliderMarksReturn {
  marks: Record<string, {label: JSX.Element; style?: React.CSSProperties;}>;
  lowest: number;
  highest: number;
  entryMark: number;
}

export const getSliderMarks = (
  orders: Order[],
  currentExchangePrice: string,
  returnPercentValue: string,
  executedQuoteFirst?: string,
  executedBaseFirst?: string,
  currentSymbol?: any,
  trade?: any,
): SliderMarksReturn => {
  const marks: Record<
    string,
    {label: JSX.Element; style?: React.CSSProperties;}
  > = {};
  const entryHeight = 15;
  let belowEntryHeight = entryHeight + 10; // Start 10px below the entryHeight
  
  const forceOrderStepFirst = trade?.orders?.find((el) => el.viewType === 'force_order_step_first');

  const getEntryMark = (baseOrder, quotePrice, basePrice, forceOrderStepFirst) => {
    if (baseOrder.orderType === 'MARKET' && (Number(quotePrice) === 0 || Number(basePrice) === 0)) {
      return '';
    }
    
    if (baseOrder.status === 'completed' || forceOrderStepFirst?.status === 'completed') {
      return Number(
        (
          (parseFloat(quotePrice!) / parseFloat(basePrice!))
        )?.toFixed(currentSymbol?.chartPrecision),
      );
    } else if (
      baseOrder.orderType === 'LIMIT' ||
      baseOrder.orderType === 'CONDITIONAL_LIMIT'
    ) {
      return parseFloat(baseOrder.limitPrice!);
    } else {
      return parseFloat(baseOrder.triggerPrice!);
    }
  };

  const labelMap: Record<string, string> = {
    additional_base_order: 'AE',
    base_order: 'Entry',
    stop_loss: 'SL',
    take_profit: 'TP',
  };
  
  const baseOrder = orders?.find((el) => el.viewType === 'base_order');

  const entryMark = getEntryMark(baseOrder, executedQuoteFirst, executedBaseFirst, forceOrderStepFirst);

  marks[entryMark] = {
    label: (
      <SliderMark
        label='Entry'
        value={entryMark ? entryMark?.toString() : ''}
        distance={entryHeight}
        isTakeProfit={false}
      />
    ),
  };
  
  
  const getPriceFromOrderType = (order) => {
    return order.orderType === 'LIMIT' || order.orderType === 'CONDITIONAL_LIMIT'
      ? parseFloat(order.limitPrice!)
      : parseFloat(order.triggerPrice!);
  };
  
  const filteredOrders = orders.filter((el) => (el.status === 'new' || el.status === 'active' || el.status === 'await_send') && el.viewType !== 'base_order');
  
  const ordersBelowEntry = filteredOrders
    ?.filter((order) => {
      const price = getPriceFromOrderType(order);
      return price < entryMark;
    })
    .sort((orderA, orderB) => {
      const priceA = getPriceFromOrderType(orderA);
      const priceB = getPriceFromOrderType(orderB);
      return priceA - priceB;
    });

  const ordersAboveEntry = filteredOrders
    ?.filter((order) => {
      const price = getPriceFromOrderType(order);
      return price > entryMark;
    })
    .sort((orderA, orderB) => {
      const priceA = getPriceFromOrderType(orderA);
      const priceB = getPriceFromOrderType(orderB);
      return priceA - priceB;
    });

  // Process orders below entryPositionOnRange
  for (let i = ordersBelowEntry.length - 1; i >= 0; i--) {
    // Start from the closest to entryPositionOnRange and move to the lowest
    const order = ordersBelowEntry[i];
    const price = getPriceFromOrderType(order);
    marks[price] = {
      label: (
        <SliderMark
          label={labelMap[order.viewType]}
          value={price.toString()}
          distance={belowEntryHeight}
          isTakeProfit={order.viewType === 'take_profit'}
        />
      ),
    };
    belowEntryHeight += 10;
  }

  // Process orders above entryPositionOnRange
  ordersAboveEntry.forEach((order) => {
    const price = getPriceFromOrderType(order);
    marks[price] = {
      label: (
        <SliderMark
          label={labelMap[order.viewType]}
          value={price.toString()}
          distance={belowEntryHeight}
          isTakeProfit={order.viewType === 'take_profit'}
        />
      ),
    };
    belowEntryHeight += 10;
  });
  
  marks[parseFloat(currentExchangePrice).toFixed(
    currentSymbol?.chartPrecision,
  )] = {
    style: currentExchangePriceStyles,
    label: (
      <SliderMark
        label={(isNaN(parseFloat(returnPercentValue))
          ? 0
          : parseFloat(returnPercentValue)
        ).toFixed(2)}
        value={parseFloat(currentExchangePrice).toFixed(
          currentSymbol?.chartPrecision,
        )}
        labelColor={getReturnPercentLabelsColor(returnPercentValue)}
        isExchangePrice={true}
        distance={30}
      />
    ),
  };
  
  const prices = Object.keys(marks).map(parseFloat);
  const lowest = Math.min(...prices);
  const highest = Math.max(...prices);
  
  if (marks[lowest]) {
    
    marks[lowest] = {
      ...marks[lowest],
      label: (
        <SliderMark
          isExchangePrice={marks[lowest].label.props.isExchangePrice}
          label={marks[lowest].label.props.label} // Reuse existing label
          value={lowest.toString() ?? ''}
          distance={marks[lowest].label.props.distance}
          isTakeProfit={false} // Lowest point, set isTakeProfit as false
          exchangeSide={marks[lowest].label.props.isExchangePrice ? false : true}
          labelColor={marks[lowest].label.props.labelColor}
        />
      ),
    };
  }
  if (marks[highest]) {
    
    marks[highest] = {
      ...marks[highest],
      label: (
        <SliderMark
          isExchangePrice={marks[highest].label.props.isExchangePrice}
          label={marks[highest].label.props.label} // Reuse existing label
          value={highest.toString() ?? ''}
          distance={marks[highest].label.props.distance}
          isTakeProfit={true} // Highest point, set isTakeProfit as true
          exchangeSide={marks[highest].label.props.isExchangePrice ? true : false}
          labelColor={marks[highest].label.props.labelColor}
        />
      ),
    };
  }
  
  return {
    marks, lowest, highest, entryMark, 
  };
};


export const getModalContent = (action, returnValue, returnValuePercent, quoteCurrency, isOpenTrade, onClickConfirm) => {
  let title;
  let actionDescriptions;

  const titleStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '335px',
    height: '44px',
  };

  const titleSpanStyle = {
    fontWeight: 400,
    fontSize: '20px',
    textAlign: 'center',
  };

  switch (action) {
  case 'close_by_market':    
    title = (
      <div style={titleStyle}>
        <span style={titleSpanStyle}>
          Are you sure you want to close position by Market?
        </span> 
      </div>
    );
    actionDescriptions = [
      'Close position on Skyrexio',
      'Cancel unfilled orders',
      'Close position on exchange',
    ];
    break;
  case 'close_by_limit':
    title = (
      <div style={titleStyle}>
        <span style={titleSpanStyle}>
          Are you sure you want to close position by Limit?
        </span> 
      </div>
    );
    actionDescriptions = [
      'Close position on Skyrexio',
      'Cancel unfilled orders',
      'Close position on exchange',
    ];
    break;
  case 'open_by_limit':
    title = (
      <div style={titleStyle}>
        <span style={titleSpanStyle}>
          Are you sure you want to open position by Limit?
        </span> 
      </div>
    );
    actionDescriptions = [
      'Cancel unfilled base order volume',
      'Open unfilled volume by limit at current price',
    ];
    break;
  case 'open_by_market':
    title = (
      <div style={titleStyle}>
        <span style={titleSpanStyle}>
          Are you sure you want to open position by Market?
        </span> 
      </div>
    );
    actionDescriptions = [
      ' Cancel unfilled base order volume',
      'Open unfilled volume by market at current price',
    ];
    break;
  case 'cancel':
    title = (
      <div style={titleStyle}>
        <span style={titleSpanStyle}>
          Are you sure you want to cancel position?
        </span> 
      </div>
    );
    actionDescriptions = [
      'Cancel position on Skyrexio',
      'Cancel unfilled orders',
      'Leave assets on exchange',
    ];
    break;
  default:
    title = '';
    actionDescriptions = [];
  }
  return {
    title: title,
    actionDescription: actionDescriptions,
    currentReturnString: 'Current return: ',
    currentExchangePriceString: 'Current exchange price: ',
    currentReturnValue: returnValue,
    currentReturnPercent: returnValuePercent,
    quoteCurrency: quoteCurrency,
    isOpenTrade: isOpenTrade,
    confirmButton: {
      text: 'Confirm',
      onClick: onClickConfirm,
    },
    cancelButton: {
      text: 'Back',
      // Optional cancel logic here
    },
  };
};
