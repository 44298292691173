import { fetchBinanceBars, fetchBybitBars, fetchOkxBars, fetchGateBars, fetchCryptocomBars } from '../api/get-exchange-bars';

interface GetBarsProps {
    symbol: any;
    fromDate: any;
    toDate: any;
    chartInterval: any;
    limit: any;
    exchange: any;
    instId?: any;
    gateSymbol?: any;
}

export async function getBars(dataForFetch: GetBarsProps) {
  const {
    exchange,
  } = dataForFetch;
  
  let ohlcv;
  

  switch (exchange.toLowerCase()) {
  case 'binance':
    ohlcv = await fetchBinanceBars(dataForFetch);
    break;
  case 'bybit':
    ohlcv = await fetchBybitBars(dataForFetch);
    break;
  case 'okx':
    ohlcv = await fetchOkxBars(dataForFetch);
    break;
  case 'gate':
    ohlcv = await fetchGateBars(dataForFetch);
    break;
  case 'crypto-com':
    ohlcv = await fetchCryptocomBars(dataForFetch);
    break;
  default:
    throw new Error(`Exchange ${exchange} is not supported.`);
  }
  
  return ohlcv;
}
  
