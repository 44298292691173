import { ConfigProvider, Table, Typography } from 'antd';
import React from 'react';
import './OrderConfirmTable.css';
import { useSelector } from 'react-redux';
import { getAllExchangeSymbols } from '../../../../../entities/terminal/model/selectors/get-all-exchange-symbols/get-all-exchange-symbols.ts';
import { CONDLIMIT_VALUE, CONDMARKET_VALUE, LIMIT_VALUE } from '../../ExchangeForm/exchange-form.constants.js';
import {
  tradesTableStyles,
  tradesTitleSideBuyStyle,
  tradesTitleSideSellStyle,
  tradesTitleStyle,
} from '../../ExchangeForm/exchange-form.styles.ts';

const {
  Title, 
} = Typography;

const valueStyle = {
  whiteSpace: 'nowrap', fontWeight: 400, 
};

// Define the column structure for the tables
const columns = [
  {
    width: '30%',
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '30%',
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '20%',
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '20%',
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
];

const tpSlColumns = [
  {
    width: '30%',
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '30%',
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '20%',
    title: 'Percent',
    dataIndex: 'percent',
    key: 'percent',
    render: (percentValue) => {
      const numericPercent = parseFloat(percentValue.replace('%', ''));

      const color =
        numericPercent > 0
          ? '#2ECD99'
          : numericPercent < 0
            ? '#F32222'
            : 'gray';

      const displayValue = numericPercent.toFixed(2) + '%';
      return <span style={{
        ...valueStyle, color: color, 
      }}>{ numericPercent >= 0 ? `+${displayValue}` : `${displayValue}`}</span>;
    },
  },
  {
    width: '20%',
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
];

const slColumns = [
  {
    width: '30%',
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '30%',
    title: '',
    dataIndex: 'volume',
    key: 'volume',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
  {
    width: '20%',
    title: 'Percent',
    dataIndex: 'percent',
    key: 'percent',
    render: (percentValue) => {
      const numericPercent = parseFloat(percentValue.replace('%', ''));

      const color =
        numericPercent > 0
          ? '#2ECD99'
          : numericPercent < 0
            ? '#F32222'
            : 'gray';

      const displayValue = numericPercent.toFixed(2) + '%';
      return <span style={{
        ...valueStyle, color: color, 
      }}>{displayValue}</span>;
    },
  },
  {
    width: '20%',
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value) => {
      return <span style={valueStyle}>{value}</span>;
    },
  },
];

const orderPriority = {
  base_order: 1,
  additional_base_order: 2,
  take_profit: 3,
  stop_loss: 4,
};

const viewTypeMapping = {
  base_order: 'Base order',
  additional_base_order: 'Additional base orders',
  take_profit: 'Take profit targets',
  stop_loss: 'Stop loss',
};

const sideMapping = {
  BUY: 'Buy',
  SELL: 'Sell',
};

const typeMapping = {
  CONDITIONAL_LIMIT: 'Cond.limit',
  CONDITIONAL_MARKET: 'Cond.market',
  LIMIT: 'Limit',
  MARKET: 'Market',
  SKIP_FIRST_STEP: 'Skip',
};

const statusMapping = {
  active: 'active',
  completed: 'completed',
  request_completed: 'pending',
  new: 'pending',
  await_send: 'pending',
};

const getTitleStyle = (side) => {
  return side === 'BUY' ? tradesTitleSideBuyStyle : tradesTitleSideSellStyle;
};

export const OrderConfirmTable = ({
  trade, 
}) => {

  const exchangeSymbols = useSelector(getAllExchangeSymbols);

  const symbol = trade.baseSymbol + trade.quoteSymbol;

  const currentSymbol = exchangeSymbols.find((el) => el.symbol === symbol);

  const baseOrderSide = trade.baseOrder.side;
  const additionalBaseOrdersSide = baseOrderSide;

  const tpSlSide = baseOrderSide === 'BUY' ? 'SELL' : 'BUY';

  const getBaseOrderPriceByType = (trade) => {
    const baseOrder = trade?.baseOrder;
    const isBuySide = baseOrder.side === 'BUY';
    
    switch (baseOrder.type) {
    case LIMIT_VALUE:
      return `${baseOrder.limitPrice} ${currentSymbol?.quoteAsset}`;
    case CONDLIMIT_VALUE:
      return `${baseOrder.limitPrice} ${currentSymbol?.quoteAsset}`;
    case CONDMARKET_VALUE:
      return `${baseOrder.triggerPrice} ${currentSymbol?.quoteAsset}`;
    case 'SKIP_FIRST_STEP':  
      //какой asset выбратьь если сторона бай?
      return isBuySide ? `${baseOrder.price} ${currentSymbol?.quoteAsset}` : `${baseOrder.price} ${currentSymbol?.quoteAsset}`;
    default:
      return '';
    }
  };
  
  const getBaseOrderPriceString = (trade) => {
    const baseOrder = trade?.baseOrder;
    if (baseOrder.type === 'MARKET') {
      return 'Market';
    } else {
      return getBaseOrderPriceByType(trade);
    }
  };
  
  const getBaseOrderUnits = (trade) => {
    if (trade.baseOrder.type === 'SKIP_FIRST_STEP') {
      const isSideBuy = trade.baseOrder.side === 'BUY';
      //какой asset выбратьь если сторона бай?
      return isSideBuy ? `${trade?.baseOrder?.units} ${currentSymbol?.baseAsset}` : `${trade?.baseOrder?.units} ${currentSymbol?.baseAsset}`;
    } else {
      return `${trade?.baseOrder?.unitsBase} ${currentSymbol?.baseAsset}`;
    }
  };
  
  
  const baseOrder = {
    key: Date.now().toString() + Math.random(),
    price: getBaseOrderPriceString(trade),
    units: getBaseOrderUnits(trade),
    // units: `${trade?.baseOrder?.unitsBase} ${currentSymbol?.baseAsset}`,
    //tofix
    total: `${trade?.baseOrder?.total} ${currentSymbol?.quoteAsset}`,
    type: typeMapping[trade.baseOrder.type],
    side: trade?.baseOrder?.side,
  };
  
  const getAddEntryPriceByType = (trade) => {
        
    switch (trade.type) {
    case LIMIT_VALUE:
      return `${trade.limitPrice} ${currentSymbol?.quoteAsset}`;
    case CONDLIMIT_VALUE:
      return `${trade.limitPrice} ${currentSymbol?.quoteAsset}`;
    case CONDMARKET_VALUE:
      return `${trade.triggerPrice} ${currentSymbol?.quoteAsset}`;
    default:
      return '';
    }
  };
  
  const addEntryOrders = trade?.additionalBaseOrders?.map((el, index) => {
    const data = {
      key: Date.now().toString() + Math.random(),
      price:  getAddEntryPriceByType(el),
      units: `${el.unitsBase} ${currentSymbol?.baseAsset}`,
      //tofix
      total: `${el.total} ${currentSymbol?.quoteAsset}`,
      type: typeMapping[el.type],
      side: el.side,
    };
    return data;
  });
  
  const getTakeProfitPriceByType = (trade) => {
    switch (trade.type) {
    case LIMIT_VALUE:
      return `${trade.limitPrice} ${currentSymbol?.quoteAsset}`;
    case CONDMARKET_VALUE:
      return `${trade.triggerPrice} ${currentSymbol?.quoteAsset}`;
    default:
      return '';
    }
  };
  
  const takeProfitOrders = trade?.takeProfits?.map((el, index) => {
    const data = {
      key: Date.now().toString() + Math.random(),
      price: getTakeProfitPriceByType(el),
      percent: `${el.percent}%`,
      //tofix
      volume: `${el.volume}%`,
      type: typeMapping[el.type],
      side: el.side,
    };
    return data;
  });
  
  const stopLossOrder = {
    key: Date.now().toString() + Math.random(),
    price:
      trade?.stopLoss?.type === 'CONDITIONAL_LIMIT'
        ? `${trade?.stopLoss?.limitPrice} ${currentSymbol?.quoteAsset}`
        : `${trade?.stopLoss?.triggerPrice} ${currentSymbol?.quoteAsset}`,
    percent:
      trade?.stopLoss?.type === 'CONDITIONAL_LIMIT'
        ? `${trade?.stopLoss?.limitPricePercent}%`
        : `${trade?.stopLoss?.triggerPercent}%`,
    //tofix
    volume: trade?.stopLoss?.volume,
    type: typeMapping[trade?.stopLoss?.type],
    side: trade?.stopLoss?.side,
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: tradesTableStyles,
        },
      }}
    >
      <div
        style={{
          overflowX: 'auto',
          maxHeight: '500px',
          //   overflowY: "auto",
        }}
      >
        {trade.baseOrder && (
          <div key='base_order' style={{
            marginTop: '10px', 
          }}>
            <Title style={{
              ...tradesTitleStyle, marginLeft: '15px', 
            }} level={4}>
              {viewTypeMapping.base_order}
              <span style={getTitleStyle(baseOrder.side)}>
                {' ' + sideMapping[baseOrder.side]}
              </span>
            </Title>
            <Table
              style={{
                marginTop: '15px', 
              }}
              columns={columns}
              dataSource={[baseOrder]}
              pagination={false}
            />
          </div>
        )}

        {trade.additionalBaseOrders?.length > 0 && (
          <div key='additional_base_order' style={{
            marginTop: '40px', 
          }}>
            <Title style={{
              ...tradesTitleStyle, marginLeft: '15px', 
            }} level={4}>
              {viewTypeMapping.additional_base_order}
              <span style={getTitleStyle(baseOrder.side)}>
                {' ' + sideMapping[baseOrder.side]}
              </span>
            </Title>
            <div style={{
              marginTop: '15px', 
            }}>
              <Table
                columns={columns}
                dataSource={addEntryOrders}
                pagination={false}
              />
            </div>
          </div>
        )}

        {trade.takeProfits?.length > 0 && (
          <div key='take_profits' style={{
            marginTop: '40px', 
          }}>
            <Title style={{
              ...tradesTitleStyle, marginLeft: '15px', 
            }} level={4}>
              {viewTypeMapping.take_profit}
              <span style={getTitleStyle(tpSlSide)}>
                {' ' + sideMapping[tpSlSide]}
              </span>
            </Title>
            <div style={{
              marginTop: '15px', 
            }}>
              <Table
                columns={tpSlColumns}
                dataSource={takeProfitOrders}
                pagination={false}
              />
            </div>
          </div>
        )}

        {trade.stopLoss && (
          <div key='stop_loss' style={{
            marginTop: '40px', 
          }}>
            <Title style={{
              ...tradesTitleStyle, marginLeft: '15px', 
            }} level={4}>
              {viewTypeMapping.stop_loss}
              <span style={getTitleStyle(tpSlSide)}>
                {' ' + sideMapping[tpSlSide]}
              </span>
            </Title>
            <Table
              style={{
                marginTop: '15px', 
              }}
              columns={slColumns}
              dataSource={[stopLossOrder]}
              pagination={false}
            />
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};
