import { Modal } from 'antd';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { Binance } from 'widgets/exchanges/binance';
import { BitMart } from 'widgets/exchanges/bitmart';
import { Bybit } from 'widgets/exchanges/bybit';
import { Crypto } from 'widgets/exchanges/crypto';
import { Exchange } from 'widgets/exchanges/exchange/ui/exchange';
import { Gate } from 'widgets/exchanges/gate';
import { Okx } from 'widgets/exchanges/okx';
import { modal, modalChildren } from '../styles';

export const UpdateExchange = (props: UpdateExchangeProps & {
  platform: string;
  isUpdateModalOpen: boolean;
}) => {

  const {
    platform,
    isUpdateModalOpen, 
    closeExchangeModal, 
    exchangeAccountUuid,
    currentExchangeTitle, 
  } = props;

  const getPlatform = () => {
    const unifiedExchanges = ['binance', 'okx', 'bybit'];
    
    if (unifiedExchanges.includes(platform)) {
      return (
        <Exchange
          exchangeType={platform}
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    }
    
    switch (platform) {
    case 'binance':
      return (
        <Binance
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    case 'bybit':
      return (
        <Bybit 
          isModalView 
          isUpdate 
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    case 'okx':
      return (
        <Okx
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    case 'crypto-com':
      return (
        <Crypto
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    case 'gateio':
      return (
        <Gate
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    case 'bitmart':
      return (
        <BitMart
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    default:
      return null;
    }
  };

  return (
    <Modal
      onCancel={closeExchangeModal}
      destroyOnClose={true}
      open={isUpdateModalOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      width='100%'
      centered={true}
    >
      {getPlatform()}
    </Modal>
  );
};
