//@ts-nocheck

import { useEffect, useState } from 'react';
import { Loader } from 'shared/ui/index.ts';
import { ActiveTradesHeader } from './ActiveTradesHeader/ActiveTradesHeader.jsx';
import { ActivePositionBlock } from './activeTrades.tsx';


export const TradeContainer = ({
  sortedTrades,
  fetchTrigger,
}) => {
  const [trigger,setTrigger] = useState(false);
    
    
  useEffect(() => {
    setTrigger(fetchTrigger);
  }, [fetchTrigger]);
    
  
  if (trigger) {
    <Loader isContentOverflow={true} />;
  }
    
  return (
        
    <div style={{
      paddingTop: '24px', paddingBottom: '24px', marginTop: '20px', 
    }}>
      <ActiveTradesHeader />
      {sortedTrades?.map((el, index) => (
        <div
          key={index}
          style={{
            marginTop: '20px',
          }}
        >
          <ActivePositionBlock
            key={index}
            tradingPair={el?.baseSymbol + '/' + el?.quoteSymbol}
            startDate={new Date(el?.createdDate)}
            currentExchangePrice={el?.currentExchangePrice}
            orders={el?.orders}
            // flagCancelledTrade={flagCancelledTrade}
            //когда только размещен и не исполнен может быть null
            executedBaseFirst={el?.executedBaseFirst}
            executedQuoteFirst={el?.executedQuoteFirst}
            returnPercentValue={el?.returnPercent}
            trade={el}
            currentStatus={70}
          />
        </div>
      ))}
      
      {sortedTrades?.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            fontSize: '24px',
            fontWeight: 400,
            marginTop: '30px',
          }}
        >
          No active positions
        </div>
      )}
    </div>
        
        
  );
}; 
