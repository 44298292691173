import { Box } from '@mui/material';
import { showActionButtons } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { useState } from 'react';
import { useModalContent } from 'shared/hooks/use-modal-content';
import { useModalState } from 'shared/hooks/use-modal-state';
import { Loader } from 'shared/ui';
import { Modal } from 'shared/ui/modal/ui/modal';
import { useMenuItems } from '../../../hooks/use-menu-items';
import { useTradeActions } from '../../../hooks/use-trade-actions';
import { BriefTradeData } from '../../brief-trade-data/brief-trade-data';
import {
  wrapper,
} from '../styles';
import { ActiveTradeProps } from '../types/active-trade.types';
import { TradeActions } from './trade-actions';

export const ActiveTrade = ({
  trade,
  tradeSymbol,
  chartLastPrice,
}: ActiveTradeProps) => {

  const {
    modalState, openModal, closeModal, 
  } = useModalState();

  const [isLoading, setIsLoading] = useState(false);
    
  const showActionResult = (status: 'success' | 'error', message: string) => {
    openModal({
      type: 'actionResult',
      status,
      message,
    });
  };
  
  const tradeActions = useTradeActions({
    tradeUuid: trade.smartTradeUuid,
    closeModal,
    showActionResult,
    setIsLoading,
  });
  
  const menuItems = useMenuItems({
    trade,
    openModal,
    ...tradeActions,
  });
  
  const {
    renderModalContent, 
  } = useModalContent({
    modalState,
    chartLastPrice,
    onClose: closeModal,
  });
    
  return (
    <Box sx={wrapper}>
          
      {isLoading && <Loader isContentOverflow={true} />}

      <BriefTradeData
        trade={trade}
        tradeSymbol={tradeSymbol}
      />
      
      {showActionButtons(trade) ? (
        <TradeActions
          menuItems={menuItems} 
          onUpdate={tradeActions.handleUpdateTrade} 
        />
      ) : <div style={{
        width: '100px', 
      }} />}
      
      <Modal
      // @TODO перенести компонент modal ant внутрь этой самописной модалки
        isOpen={modalState.isOpen}
        onClose={closeModal}
        width={modalState.type === 'actionResult' ? 300 : 400}
      >
        {renderModalContent()}
      </Modal>
    </Box>
  );
};
