import { Modal, Button } from 'antd';
import React from 'react';
import './Modal.css';

export const CustomModal = ({
  isOpen,
  onClose,
  title,
  actionDescription,
  confirmButton,
  cancelButton,
  currentReturnString,
  currentExchangePriceString,
  currentReturnValue,
  currentReturnPercent,
  quoteCurrency,
  isOpenTrade,
}) => {
  const footerStyle = {
    footer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const getPriceColor = (isOpenTrade, currentReturnValue) => {
    if (!isOpenTrade) {
      return currentReturnValue >= 0 ? '#2ECD99' : '#F32222';
    }
    return 'black';
  };
  
  return (
    <Modal
      // className="custom-modal"
      styles={footerStyle}
      title={title}
      width={450}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '327px',
            height: '42px',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            style={{
              width: '158px',
              height: '42px',
              borderRadius: '5px',
            }}
            key='back'
            onClick={onClose}
          >
            <span
              style={{
                color: '#2ECD99',
              }}
            >
              {' '}
              {cancelButton.text}{' '}
            </span>
          </Button>
          <Button
            style={{
              width: '158px',
              height: '42px',
              borderRadius: '5px',
              backgroundColor: '#2ECD99',
            }}
            key='submit'
            type='primary'
            onClick={confirmButton.onClick}
          >
            {confirmButton.text}
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={{
            margin: '20px 0',
            fontWeight: 400,
            fontSize: 12,
            textAlign: 'center',
            fontFamily: 'Poppins',
          }}
        >
          {isFinite(currentReturnValue) && currentReturnValue !== '' &&
          <div>
            <span>{isOpenTrade ? currentExchangePriceString : currentReturnString} </span>
            <span
              style={{
                color: getPriceColor(isOpenTrade, currentReturnValue),
              }}
            >
              {`${currentReturnValue} ${quoteCurrency}`}
            </span>
            {currentReturnPercent && (
              <span
                style={{
                  color: getPriceColor(isOpenTrade, currentReturnValue),
                }}
                    
              >{` (${currentReturnPercent})`}</span>
            )}
          </div>
          }
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start', // Center the content vertically and horizontally
            width: isOpenTrade ? '370px' : '314px', // Use the full width of the parent
            height: '110px',
            padding: '25px 33px', // Maintain padding
            boxSizing: 'border-box', // Include padding in the width and height
            backgroundColor: '#EBEBEB', // Add a background color
            fontFamily: 'Poppins',
            fontSize: 12,
          }}
        >
          <span style={{
            textAlign: 'left', marginTop: '5px', 
          }}>
            This action will:
          </span>

          <ul style={{
            textAlign: 'left', marginTop: '5px', 
          }}>
            {' '}
            {actionDescription.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};
