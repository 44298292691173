// @ts-nocheck

import {Toggler} from "../../../shared/ui/Toggler/Toggler.tsx";
import {BaseOrderInputs} from "./BaseOrderInputs/BaseOrderInputs";
import {FC, useEffect, useState} from "react";
import {CurrencyWallet} from "../CurrencyWallet/CurrencyWallet.tsx";
import React from "react";
import {coinsTogglerTextStyle} from "./BaseOrderInputs/base-order-section.styles.js";
import {marginTopSmall} from "../exchange-form.styles.ts";
import {BASE_ORDER_TITLE, COINS_TOGGLER} from "../exchange-form.constants.js";
import {useSelector} from "react-redux";
import { Spin } from 'antd';
import { getChartLastPrice } from "../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts";
import { getCurrentSymbol } from "../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts";

interface WalletProps {
  availableCoinsCurrencyName: string;
  availableCoinsAmount: number;
}

interface BaseOrderSectionProps {
  coinsAmount: WalletProps;
  desiredCoin: string;
}

export const BaseOrderSection: FC<BaseOrderSectionProps> = ({
  formDisabled
}) => {
  const currentSymbol = useSelector(getCurrentSymbol);
  const lastPrice = useSelector(getChartLastPrice);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
        setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
}, [currentSymbol]);    
    
return (
  <div style={{
    position: 'relative',
  }}>
    {isLoading && (
      <div style={{
        position: 'absolute',
        top: '170px',
        left: '100px',
        zIndex: 2
      }}>
        <Spin size="large" />
      </div>
    )}
    <div style={{...marginTopSmall, marginLeft: "3px", ...(isLoading ? { filter: 'blur(1px)' } : {}) }}>
      <Toggler 
      disabled={formDisabled} 
      togglerElementSectionName={COINS_TOGGLER} 
      title={BASE_ORDER_TITLE} 
      titleStyle={coinsTogglerTextStyle} 
      />
    </div>

    <div style={{...marginTopSmall, marginLeft: "3px", ...(isLoading ? { filter: 'blur(2px)' } : {}) }}>
      <CurrencyWallet />
    </div>

    <div style={{marginLeft: "3px", ...marginTopSmall, ...(isLoading ? { filter: 'blur(2px)' } : {}) }}>
      <BaseOrderInputs 
      formDisabled={formDisabled} 
      chartLastPrice={lastPrice} 
      />
    </div>
  </div>
);
};

