// @ts-nocheck
import { InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getBaseOrderPrice } from '../../../../../../../entities/terminal/model/selectors/get-base-order-price/get-base-order-price.ts';
import { getCurrentSymbol } from '../../../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getWalletData } from '../../../../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data.ts';
import { OrderPriceGroup } from '../../../OrderPriceGroup/OrderPriceGroup.tsx';
import { RangeContainer } from '../../../RangeContainer/RangeContainer';
import { SectionsTriggerPriceGroup } from '../../../TriggerPriceGroup/SectionsTriggerPriceGroup.jsx';
import { TriggerPriceGroup } from '../../../TriggerPriceGroup/TriggerPriceGroup.jsx';
import COLORS from '../../../colors';
import '../../../OrderPriceGroup/OrderPriceGroup.css';
import {
  roundToTickSize,
  roundUnitsToTickSize,
} from '../../../exchange-form-utils.js';
import {
  ADDITIONAL_ENTRY_SECTION_TYPE,
  ADDITIONAL_ENTRY_TOGGLER,
  FIELD_TOTAL,
  FIELD_UNITS,
  LABEL_ORDER_PRICE,
  LABEL_TOTAL,
  LABEL_UNITS,
} from '../../../exchange-form.constants.js';
import { basicInputStyle } from '../../../exchange-form.styles.ts';
import debounce from 'lodash.debounce';
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';

const {
  Text, 
} = Typography;

interface BasicInputsProps {
  labelOrder: string;
  labelTotal: string;
  labelUnits: string;
  baseOrderValue: number;
  freeCoin: number;
  disabled: boolean;
  sliderName: string;

  totalFieldName: string;
  unitsFieldName: string;
  orderPriceFieldName: string;

  chartLastPrice: number;

  addEntryEnabled: boolean | undefined;
  isCondMarket: boolean | undefined;
  isCondLimit: boolean | undefined;
  triggerPriceInputControlName: string;
  selectControlName: string;
  orderPricePercentFieldName: string;
}

export const SectionBasicInputs: React.FC<BasicInputsProps> = ({
  chartLastPrice,
  fieldDisabled,
  disabled,
  sliderName,
  totalFieldName,
  unitsFieldName,
  orderPriceFieldName,
  addEntryEnabled,
  isCondMarket,
  isCondLimit,
  triggerPriceInputControlName,
  selectControlName,
  orderPricePercentFieldName,
}) => {
  
  const {
    control, setValue, getValues, watch, 
  } = useFormContext();
  const currentSymbol = useSelector(getCurrentSymbol);
  const baseOrderPrice = useSelector(getBaseOrderPrice);
  const walletData = useSelector(getUserWalletSelectedSymbol);
  const freeCoin = walletData?.free ?? 0;

  const defaultPrice = baseOrderPrice * 0.95;

  const handleFormatBaseAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = currentSymbol.baseAssetPrecision;
    
    const formattedValue = Number(value).toFixed(precision);
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    const formattedValue = Number(value).toFixed(precision);
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleParseNumber = (value: string) => {
    return value.replace(/[^0-9.]/g, '');
  };

  const [newSliderValue, setNewSliderValue] = useState(0);
  const defaultSliderValue = 10;

  useEffect(() => {
    const defaultOrderPriceValues = roundToTickSize(
      defaultPrice,
      currentSymbol.priceTickSize,
    );
    const defaultTotalValues = roundToTickSize(
      freeCoin * 0.1,
      currentSymbol.priceTickSize,
    );
    const newUnits = roundUnitsToTickSize(
      defaultTotalValues / defaultOrderPriceValues,
      currentSymbol.lotTickSize,
    );

    setValue(orderPriceFieldName, defaultOrderPriceValues);
    setValue(unitsFieldName, newUnits);
  }, [chartLastPrice]);

  const handleChangeOrderPrice = (event) => {
    const newOrderPrice = event.target.value;
    setValue(orderPriceFieldName, newOrderPrice, {
      shouldValidate: true, 
    });
    setValue(
      unitsFieldName,
      getNewUnitsValueSection(getValues(totalFieldName), newOrderPrice),
      {
        shouldValidate: true, 
      },
    );
  };

  const handleChangeTotal = (newTotal) => {
    const roundedTotal = roundToTickSize(newTotal, currentSymbol.priceTickSize);
    const orderPriceValues = getValues(orderPriceFieldName);

    setValue(totalFieldName, roundedTotal, {
      shouldValidate: true, 
    });
    setValue(
      unitsFieldName,
      roundUnitsToTickSize(
        roundedTotal / orderPriceValues,
        currentSymbol.lotTickSize,
      ),
      {
        shouldValidate: true, 
      },
    );
    setNewSliderValue(Math.round((roundedTotal / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedTotal / freeCoin) * 100));
  };

  const handleChangeUnits = (newUnits) => {
    const roundedUnits = roundUnitsToTickSize(
      newUnits,
      currentSymbol.lotTickSize,
    );
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedTotal = roundToTickSize(
      roundedUnits * orderPriceValues,
      currentSymbol.priceTickSize,
    );

    setValue(unitsFieldName, roundedUnits, {
      shouldValidate: true, 
    });

    setValue(totalFieldName, roundedTotal, {
      shouldValidate: true, 
    });

    setNewSliderValue(Math.round((roundedTotal / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedTotal / freeCoin) * 100));
  };

  const debouncedHandleSliderChange = debounce((sliderValue) => {
    const newTotal = Math.min((sliderValue / 100) * freeCoin);
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedTotal = roundToTickSize(newTotal, currentSymbol.priceTickSize);
    setValue(totalFieldName, roundedTotal, {
      shouldValidate: true, 
    });

    const newUnitsValue = roundUnitsToTickSize(
      newTotal / getValues(orderPriceFieldName),
      currentSymbol.lotTickSize,
    );

    setValue(
      unitsFieldName,
      roundUnitsToTickSize(
        newTotal / orderPriceValues,
        currentSymbol.lotTickSize,
      ),
      {
        shouldValidate: true, 
      },
    );
  }, 300);

  const validateZeroValue = (value) => {
    return value !== 0 || 'Value cannot be zero';
  };

  const validateMinUnitsValue = (value) => {
    return (
      value >= currentSymbol.lotMin ||
      `Units should be greater than ${currentSymbol.lotMin ?? 0}`
    );
  };

  const minTotalValidation = (value) => {
    return (
      value >= Number(currentSymbol.minNotional) ||
      `Order size should be greater than ${currentSymbol.minNotional}`
    );
  };

  const maxTotalValidation = (value) => {
    return (
      value <= Number(currentSymbol.maxNotional) ||
      `Order size should be less than ${currentSymbol.maxNotional}`
    );
  };

  const maxFreeCoinValidation = (value) => {
    return (
      value <= freeCoin ||
      `Value cannot exceed ${freeCoin} ${walletData?.symbol}`
    );
  };

  return (
    <>
      {addEntryEnabled && (
        <>
          {isCondMarket ? (
            <SectionsTriggerPriceGroup
              priceInputControlName={triggerPriceInputControlName}
              selectControlName={selectControlName}
              baseOrderValue={chartLastPrice}
              defaultPrice={defaultPrice}
            />
          ) : isCondLimit ? (
            <>
              <SectionsTriggerPriceGroup
                priceInputControlName={triggerPriceInputControlName}
                selectControlName={selectControlName}
                baseOrderValue={chartLastPrice}
                defaultPrice={defaultPrice}
              />
              <div>
                <Text
                  style={{
                    fontSize: 12, color: COLORS.primary, 
                  }}
                  type='secondary'
                >
                  {LABEL_ORDER_PRICE}
                </Text>
                <OrderPriceGroup
                  orderPriceControlName={orderPriceFieldName}
                  percentageControlName={orderPricePercentFieldName}
                  chartLastPrice={chartLastPrice}
                  defaultPrice={defaultPrice}
                  totalFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_TOTAL}`}
                  unitsFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_UNITS}`}
                />
              </div>
            </>
          ) : (
            <div>
              <Text
                style={{
                  fontSize: 12, color: COLORS.primary, 
                }}
                type='secondary'
              >
                {LABEL_ORDER_PRICE}
              </Text>
              <OrderPriceGroup
                orderPriceControlName={orderPriceFieldName}
                percentageControlName={orderPricePercentFieldName}
                chartLastPrice={chartLastPrice}
                totalFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_TOTAL}`}
                unitsFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_UNITS}`}
                defaultPrice={defaultPrice}
              />
            </div>
          )}
        </>
      )}

      {!addEntryEnabled && (
        <>
          <Text style={{
            fontSize: 12, color: COLORS.primary, 
          }} type='secondary'>
            {LABEL_ORDER_PRICE}
          </Text>
          <div>
            <Controller
              name={orderPriceFieldName}
              control={control}
              defaultValue={chartLastPrice}
              rules={{
                required: 'Order price is required',
                validate: validateZeroValue,
              }}
              render={({
                field, fieldState: {
                  error, 
                }, 
              }) => (
                <>
                  <InputNumber
                    {...field}
                    style={{
                      ...basicInputStyle,
                      border: error ? '1px solid red' : '', // Add red border if there's an error
                    }}
                    disabled={disabled}
                    size='small'
                    controls={false}
                    suffix={currentSymbol.quoteAsset}
                    formatter={handleFormatQuoteAsset}
                    parser={handleParseNumber}
                    onChange={handleChangeOrderPrice}
                  />
                  {error && (
                    <Text style={{
                      color: 'red', fontSize: 10, 
                    }}>
                      {error.message}
                    </Text>
                  )}
                </>
              )}
            />
          </div>
        </>
      )}

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_UNITS}
      </Text>
      <div>
        <Controller
          name={unitsFieldName}
          control={control}
          defaultValue={roundToTickSize(
            (freeCoin * 0.1) / defaultPrice,
            currentSymbol.lotTickSize,
          )}
          rules={{
            required: 'Units field is required',
            validate: {
              notZero: validateZeroValue,
              minValue: validateMinUnitsValue,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                min={0}
                max={freeCoin}
                size='small'
                controls={false}
                suffix={currentSymbol.baseAsset}
                formatter={handleFormatBaseAsset}
                parser={handleParseNumber}
                onChange={handleChangeUnits}
              />
              {error && (
                <Text style={{
                  color: 'red', fontSize: 10, 
                }}>
                  {error.message}
                </Text>
              )}
            </>
          )}
        />
      </div>

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_TOTAL}
      </Text>
      <div>
        <Controller
          name={totalFieldName}
          control={control}
          defaultValue={roundToTickSize(
            freeCoin * 0.1,
            currentSymbol.priceTickSize,
          )}
          rules={{
            required: 'Total field is required',
            validate: {
              notZero: validateZeroValue,
              maxFreeCoin: maxFreeCoinValidation,
              minValue: minTotalValidation,
              maxValue: maxTotalValidation,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                size='small'
                max={freeCoin}
                controls={false}
                suffix={currentSymbol.quoteAsset}
                formatter={handleFormatQuoteAsset}
                parser={handleParseNumber}
                onChange={handleChangeTotal}
              />
              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
          )}
        />
      </div>
      <RangeContainer
        name={sliderName}
        defaultValue={defaultSliderValue}
        onSliderChange={debouncedHandleSliderChange}
        valueNew={newSliderValue}
      />
    </>
  );
};
