import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  getExchangeAccountNameBeingUpdated, 
  getExchangeUuidBeingUpdated, 
  getSkyrexUuid, 
  removeExchangeAccountName, 
  removeExchangeAccountUuid, 
} from 'shared/helpers/storage-helper';
import { Loader } from 'shared/ui';
import { updateOauth } from '../api/update-oauth';

function updateOAuthComponent(exchangeCode: string, pathSegment: string) {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [loading, setLoading] = useState<boolean>(true);
    
    const skyrexUserUuid = getSkyrexUuid();
    const chosenExchangeUuid = getExchangeUuidBeingUpdated();
    const chosenAccountName = getExchangeAccountNameBeingUpdated();
    
    const handleSendOauth = async (exchangeAccountUuid: string, userAccessToken: string, exchangeAccountTitle: string) => {
      try {
        setLoading(true);

        const dataForOauth = {
          exchangeAccountUuid,
          userAccessToken,
          accountName: exchangeAccountTitle,
          exchangeCode,
        };
        
        const oauthResponse = await updateOauth(dataForOauth);
        if (!oauthResponse.success) {
          navigate('/my-accounts', {
            state: {
              success: false,
            },
          });
          return;
        }
        
        navigate('/my-accounts', {
          state: {
            success: true,
          },
        });
        return;
      } catch (error) {
        navigate('/my-accounts', {
          state: {
            success: false,
          },
        });
        return;
      } finally {
        removeExchangeAccountName();
        removeExchangeAccountUuid();
        setLoading(false);
      }
    };

    useEffect(() => {
      const {
        pathname,
        search,
      } = location;
      if (pathname.includes(pathSegment)) {
        
        const params = new URLSearchParams(search);
        const code = params.get('code');
        
        handleSendOauth(chosenExchangeUuid!, code!, chosenAccountName!);
      }
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

function updateOAuthCryptoComComponent() {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const skyrexUserUuid = getSkyrexUuid();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      navigate('/my-accounts?mt=crypto-updated');
      setLoading(false);
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

export const UpdateOauthBinance = updateOAuthComponent('binance', '/oauth/update/binance');
export const UpdateOauthBybit = updateOAuthComponent('bybit', '/oauth/update/bybit');
export const UpdateOauthOkx = updateOAuthComponent('okx', '/oauth/update/okx');
// export const UpdateOauthCryptoCom = updateOAuthCryptoComComponent('My cryptocom', 'crypto-com', '/oauth/crypto-com');
export const UpdateOauthCryptoCom = updateOAuthCryptoComComponent();
