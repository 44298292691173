import { Box } from '@mui/material';
import { Dropdown, Input } from 'antd';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LineChart } from 'widgets';
import { updateExchange } from 'widgets/exchanges/api/update-exchange';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { CheckIcon, CloseIcon, EditIcon, More, SecondaryInfo, Update } from 'shared/icons';
import { MainButton, Text, Title } from 'shared/ui';
import { updateExchangeTitle } from '../../exchanges/api/update-exchange-title';
import { Error, Loading, MoreButtonContent } from '../components';
import { formatChangeText, getTextType } from '../helpers/platform-card-helpers';
import { IPlatformCardProps } from '../interfaces';
import {
  action,
  background,
  changesRight,
  changes as changesStyles,
  description as descriptionStyles,
  info,
  more as moreStyles,
  title as titleStyles,
  total as totalStyles,
  update,
} from '../styles';

export const PlatformCard = (props: IPlatformCardProps) => {
  const {
    title,
    exchangeCode,
    description,
    total,
    totalUsdt,
    usdtDailyChangePercent,
    usdtDailyChangeValue,
    btcDailyChangePercent,
    btcDailyChangeValue,
    error,
    exchangeAccountUuid,
    balances,
    trade,
    view,
    onDelete,
    onUpdateApiKeys,
    isDemoAccount,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updatedTitle, setUpdatedTitle] = useState<string>('');
  const sortedBalances = useMemo(() => balances.slice().sort((a, b) => {
    const totalA = parseFloat(a.usdt.total);
    const totalB = parseFloat(b.usdt.total);
    return totalB - totalA;
  }), [balances]);

  const dispatch = useDispatch();

  const showEmptyExchangeCard = balances?.length === 0;

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const responseForUpdatedExchange = await updateExchange(exchangeAccountUuid);
      if (responseForUpdatedExchange.success) {
        const accountForUpdate = responseForUpdatedExchange
          .data
          .accounts
          .find((account: ExchangeAccount) => account.exchangeAccountUuid === exchangeAccountUuid);

        const payload = {
          exchangeAccountUuid,
          data: accountForUpdate,
        };

        dispatch(exchangeActions.updateExchange(payload));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getContent = () => {
    if (showEmptyExchangeCard) {
      return <Error bybit={exchangeCode === 'bybit'} />;
    }

    return (
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text type='secondary' styles={totalStyles}>
            Total:
          </Text>

          <Text type='secondary' styles={totalStyles}>
            <strong>
              $
              {currencyFormatter(+total.usd, 2)}
            </strong>
            {' '}
            /
            {currencyFormatter(+total.btc, 6)}
            {' '}
            BTC
          </Text>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={1}
        >
          <Text type='secondary' styles={changesStyles}>
            24h changes:
          </Text>

          <Text
            type='secondary'
            styles={changesRight}
          >
            <Text
              type={getTextType(+usdtDailyChangeValue)}
              styles={changesRight}
            >
              {formatChangeText(
                +usdtDailyChangeValue,
                +usdtDailyChangePercent,
              )}
            </Text>
            {' '}
            /
            {' '}
            <Text
              type={getTextType(+btcDailyChangeValue)}
              styles={changesRight}
            >
              {formatChangeText(
                +currencyFormatter(+btcDailyChangeValue, 6),
                +btcDailyChangePercent,
              )}
            </Text>
          </Text>
        </Box>
      </Box>
    );
  };
  
  const onTitleEdit = () => {
    setUpdatedTitle(title);
    setIsEdit(true);
  };

  const onTitleEditCancel = () => {
    setIsEdit(false);
    setUpdatedTitle(title);
  };

  const onTitleSave = async () => {
    if (updatedTitle === title) {
      return onTitleEditCancel();
    }

    if (updatedTitle.replace(/ /g, '') === '') {
      return onTitleEditCancel();
    }

    setIsLoading(true);

    try {
      const responseForUpdatedExchange = await updateExchangeTitle(exchangeAccountUuid, updatedTitle);
      if (responseForUpdatedExchange.success) {
        await handleUpdate();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    setIsEdit(false);
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUpdatedTitle(e.target.value);
  };

  const renderTitle = useMemo(() => {
    if (isEdit) {
      return (
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
        >
          <Input
            placeholder=''
            value={updatedTitle}
            onChange={onTitleChange}
            autoFocus={true}
            size='middle'
            style={{
              width: '100%',
              flex: 1,
              maxWidth: '160px',
              padding: '3px 0',
            }}
          />

          <Box
            display='flex'
            style={{
              cursor: 'pointer',
            }}
            onClick={onTitleEditCancel}
          >
            {CloseIcon}
          </Box>

          <Box
            display='flex'
            style={{
              cursor: 'pointer',
            }}
            onClick={onTitleSave}
          >
            {CheckIcon}
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
        >
          <Box maxWidth={180}>
            <Title
              level={5}
              styles={titleStyles}
              overflow={{
                tooltip: title,
              }}
            >
              {title}
            </Title>
          </Box>

          <Box
            display='flex'
            onClick={onTitleEdit}
            style={{
              cursor: 'pointer',
            }}
          >{EditIcon}</Box>
        </Box>

        <div
          onClick={handleUpdate}
          style={update}
        >
          {Update}
        </div>
      </Box>
    );
  }, [title, isEdit, updatedTitle]);

  const chartRender = useMemo(() => (
    <LineChart items={sortedBalances} />
  ), [sortedBalances]);

  const getAction = () => {
    if (!isDemoAccount) {
      return (
        <Dropdown
          trigger={['hover', 'click']}
          placement='topRight'
          dropdownRender={() => (
            <MoreButtonContent 
              handleDelete={() => onDelete(exchangeAccountUuid)} 
              handleUpdate={() => onUpdateApiKeys({
                exchangeAccountUuid, 
                exchangeCode, 
                exchangeTitle: title,
              })} 
            />
          )}
        >
          <MainButton styles={moreStyles}>
            {More}
          </MainButton>
        </Dropdown>
      );
    }

    return (
      <MainButton
        styles={action}
        onClick={() => onDelete(exchangeAccountUuid)}
        size='middle'
      >
        Reset
      </MainButton>
    );
  };

  const render = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <Box
          display='flex'
          flexDirection='column'
          gap={3}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            flex={1}
          >
            <Box
              display='flex'
              flexDirection='column'
              gap={1}
              flex={1}
            >
              {renderTitle}

              <Text
                type='secondary'
                styles={descriptionStyles}
              >
                {description}
              </Text>
            </Box>
          </Box>

          {!showEmptyExchangeCard && (
            <Box display='flex' flexDirection='column' gap='8px'>
              {chartRender}

              <Box display='flex' alignItems='center' gap={1}>
                {+totalUsdt < 10 ? (
                  <>
                    {SecondaryInfo}
                    <Text type='secondary' styles={info}>
                      Balance is less than $10. Deposit funds to start trading
                    </Text>
                  </>
                ) : (
                  <Box sx={{
                    visibility: 'hidden',
                  }}>
                    {SecondaryInfo}
                    <Text type='secondary' styles={info}>
                      {''}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display='flex' alignItems='center' gap={1} />
            </Box>
          )}
        </Box>

        {getContent()}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box
            display='flex'
            alignItems='center'
            gap={1}
          >
            {!error && (
              <MainButton
                styles={action}
                onClick={trade}
                size='middle'
              >
                Trade
              </MainButton>
            )}

            <MainButton
              styles={action}
              onClick={() => view(exchangeAccountUuid)}
              size='middle'
            >
              View
            </MainButton>
          </Box>

          {getAction()}
        </Box>
      </>
    );
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      sx={background}
      gap={4}
      height={306}
    >
      {render()}
    </Box>
  );
};
