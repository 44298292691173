import { calculatePercentDifference, formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';

export const useStopLossInputsLogic = (currentSymbol: any, watch: any, setValue: any) => {

  const orderPrice = watch('orderPrice');
  const isStopLossEnabled = watch('isStopLossEnabled');
  const handleStopLossTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
        
    if (!isNaN(triggerPrice)) {
      setValue('stopLossTriggerPrice', triggerPrice.toString());
            
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('stopLossTriggerPricePercent', percentDifference);
      } else {
        setValue('stopLossTriggerPricePercent', '0');
      }
          
      // Здесь можно добавить дополнительную логику, если необходимо
    }
        
  }, [setValue, orderPrice, calculatePercentDifference]);
      
      
  const handleStopLossOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
        
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('stopLossOrderPricePercent', percentDifference.toString());
    }
        
  }, [setValue, orderPrice, calculatePercentDifference]);
    
  const handleStopLossOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
        
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newStopLossOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedStopLossOrderPrice = formatByPrecisionAndTrim(newStopLossOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('stopLossOrderPrice', formattedStopLossOrderPrice);
    }
  };
    
  const updateStopLossValues = useCallback(() => {
    if (isStopLossEnabled) {
      return; 
    }
  
    const currentOrderPrice = parseFloat(orderPrice);
    const currentStopLossOrderPrice = currentOrderPrice * 0.9;
    const percentDifference = calculatePercentDifference(currentStopLossOrderPrice, currentOrderPrice);
  
    const formattedStopLossOrderPrice = formatByPrecisionAndTrim(
      currentStopLossOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
  
    const roundedPercentDifference = Math.round(percentDifference);
  
    setValue('stopLossOrderPrice', formattedStopLossOrderPrice);
    setValue('stopLossTriggerPrice', formattedStopLossOrderPrice);
    setValue('stopLossOrderPricePercent', roundedPercentDifference.toString());
    setValue('stopLossTriggerPricePercent', roundedPercentDifference.toString());

  }, [isStopLossEnabled, orderPrice, setValue, calculatePercentDifference, currentSymbol?.quoteAssetPrecision]);

  return {
    handleStopLossTriggerPriceChange,
    handleStopLossOrderPriceChange,
    handleStopLossOrderPricePercentChange,
    updateStopLossValues,
  };
};
