export const openTradeCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'entry1',
    'price': '{{close}}',
    'sourceUuid': sourceUuid,
    'secretToken': secretToken,
  };
};

export const additinalEntryCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'entry2',
    'price': '{{close}}',
    'sourceUuid': sourceUuid,
    'secretToken': secretToken,
  };
};

export const closeTradeCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'close',
    'price': '{{close}}',
    'sourceUuid': sourceUuid || '',
    'secretToken': secretToken || '',
  };
};

export const strategyEntry = (sourceUuid?: string, secretToken?: string) => {
  return `alert_message = '{"base": "' + syminfo.basecurrency + '", "quote": "' + syminfo.currency + '", "position": "entry1", "price": "' + str.tostring(close) + '", "sourceUuid": "${sourceUuid}", "secretToken": "${secretToken}", "timestamp": "' + str.tostring(timenow) + '"}'`;
};

export const strategyExit = (sourceUuid?: string, secretToken?: string) => {
  return `alert_message = '{"base": "' + syminfo.basecurrency + '", "quote": "' + syminfo.currency + '", "position": "close", "price": "' + str.tostring(close) + '", "sourceUuid": "${sourceUuid}", "secretToken": "${secretToken}", "timestamp": "' + str.tostring(timenow) + '"}'`;
};

export const otherSendRequest = () => {
  return {
    'base': 'Symbol to trade',
    'quote': 'Market of the bot',
    'position': 'Action to take e.g. \'entry1\' to open trade',
    'price': 'Price to execute order',
    'sourceUuid': 'Identifier of your bot',
    'secretToken': 'Protection token of your bot',
    'timestamp': 'Current time in UNIX format',
  };
};
