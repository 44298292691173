import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmptySubscription } from 'shared/ui';
import { getChartLastPrice } from '../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { getCurrentSymbol } from '../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getWalletData } from '../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data.ts';
import { terminalActions } from '../../../../entities/terminal/model/slices/terminal-slice.ts';
// import {
// add_user_manual_trade,
// set_current_symbol,
// set_userwallet_base_asset,
// set_userwallet_trading_data,
// } from '../../_dispatch/user.js';
// import { send_order_form } from '../../axios/user.js';
import { getSkyrexUuid } from '../../../../shared/helpers/storage-helper.ts';
import { Loader } from '../../../../shared/ui/loader/ui/loader.tsx';
import { classNames } from '../../shared/lib/classNames/classNames';
import { Button as ClassicButton } from '../../shared/ui/Button/Button';
import { GenericSwitcher } from '../ExchangeForm/widgets/GenericSwitcher/GenericSwitcher.jsx';
// import { GenericSwitcher } from '../../widgets/GenericSwitcher/GenericSwitcher';
import { OrderConfirmTable } from '../ExchangeTable/OrderConfirmTable/OrderConfirmTable.jsx';
// import { OrderConfirmTable } from '../ExchangeTable/OrderConfirmTable/OrderConfirmTable.jsx';
import { AdditionalEntrySection } from './AdditionalEntrySection/AdditionalEntrySection';
import { TakeProfitSection } from './TakeProfitSection/TakeProfitSection';
import './ExchangeForm.css';
import { StopLossSection } from './StopLossSection/StopLossSection';
import { ConfigProvider, Modal } from 'antd';
import { BaseOrderSection } from './BaseOrderSection/BaseOrderSection.tsx';
import { checkTradeCreated, getTradeRichInfo } from './api/check-trade-created.ts';
import { createNewTrade } from './api/create-new-trade.ts';
import { roundToTickSize } from './exchange-form-utils.js';
import {
  CONDLIMIT_VALUE,
  CONDMARKET_VALUE,
  SIDE_OPTION_SWITCHER,
} from './exchange-form.constants.js';
import {
  basicSectionsLayout,
  buttonsWrapperStyle,
  failureModalButtonStyle,
  inputFontSize,
  sliderStyles,
  successModalBodyContainer,
  successModalButtonContainer,
  successModalButtonStyle,
  successModalTextStyle,
  switchStyles,
  tableStyles,
  verifyButtonStyles,
} from './exchange-form.styles.ts';
import { BASE_ORDER_SWITCHER_FIELD_NAME } from './BaseOrderSection/BaseOrderInputs/BaseOrderInputs.jsx';
import { Button } from 'antd';

export const ExchangeForm = ({
  exchangeAccountUuid,
  onFormSubmitSuccess,
  formDisabled,
}) => {
  
  const methods = useForm();
  const {
    handleSubmit, watch, setValue, 
  } = methods;
  const [formData, setFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [isFailureCreateModalOpen, setIsFailureCreateModalOpen] = useState(false);
  const [isTakeProfitErrorModalOpen, setIsTakeProfitErrorModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  
  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const showFailureModal = () => {
    setIsFailureModalOpen(true);
  };

  const onFailure = () => {
    setIsModalOpen(false);
    showFailureModal();
  };

  const onSuccess = () => {
    setIsModalOpen(false);
    showSuccessModal();
  };

  const dispatch = useDispatch();

  const lastPrice = useSelector(getChartLastPrice);
  const userWallet = useSelector(getWalletData);

  //TODO: take userdata from current state
  const user_id = exchangeAccountUuid;

  const skyrexUuid = getSkyrexUuid();
  

  const currentSymbol = useSelector(getCurrentSymbol);
  
  const userWalletData = userWallet?.find(
    (el) => el.symbol === currentSymbol?.quoteAsset,
  );

  const userWalletBaseAsset = userWallet?.find(
    (el) => el.symbol === currentSymbol?.baseAsset,
  );

  const defaultWalletData = {
    symbol:'USDT',
    free:'1000',
    locked:'41.22646840',
    total:'1000',
    share:'6.31909493',
  };
  
  const defaultSymbol = {
    symbol: 'BTCUSDT',
    quoteAsset: 'USDT',
    baseAsset: 'BTC',
    priceTickSize: 0.01,
    lotTickSize: 0.001,
  };
  useEffect(() => {
    setValue(BASE_ORDER_SWITCHER_FIELD_NAME, 'LIMIT');
    dispatch(terminalActions.setUserWalletCurrentTradingSymbol(userWalletData));
    dispatch(terminalActions.setUserWalletBaseAsset(userWalletBaseAsset));
  }, [currentSymbol, userWalletData, lastPrice, dispatch, setValue]);

  const selectedOption = watch(SIDE_OPTION_SWITCHER, 'buy');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleCreateTradeClick = (event) => {
    event.preventDefault();
    handleSubmit(
      (data) => {
        const transformedData = getMappedDataForModal(data);
        if (transformedData === false) {
          setIsTakeProfitErrorModalOpen(true);
          return;
        }
        setFormData(transformedData);
        setIsModalOpen(true);
      },
      (errors) => {
        console.log('Validation Errors:', errors);
      },
    )();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsFailureModalOpen(false);
    setIsTakeProfitErrorModalOpen(false);
  };

  const handleVerifyTrade = async () => {
    try {
      setIsLoading(true);
      await handleSubmit(onSubmit)();
    } catch (error) {
      console.error('Trade Submission Error:', error);
    } finally {
      onSuccess();
      setIsLoading(false);
    }
  };

  const getMappedDataForModal = (data) => {
    let additionalBaseOrders;
    if (data.addEntryArray && data.addEntryArray.length > 0) {
      // Map addEntryArray to additionalBaseOrders when addEntryArray is present and not empty
      additionalBaseOrders = data.addEntryArray.map((entry) => ({
        side: data?.exchangeOption.toUpperCase(),
        total: parseFloat(entry.total),
        type: data.firstEntrySwitcher.toUpperCase(),
        ...(data.firstEntrySwitcher === 'LIMIT' && {
          limitPrice: roundToTickSize(
            entry.price,
            currentSymbol?.priceTickSize,
          ),
        }),
        ...(data.firstEntrySwitcher === 'CONDITIONAL_LIMIT' && {
          limitPrice: roundToTickSize(
            entry.price,
            currentSymbol?.priceTickSize,
          ),
          triggerPrice: roundToTickSize(
            entry.triggerPrice,
            currentSymbol?.priceTickSize,
          ),
          typeTriggerPrice: entry?.triggerPriceType?.value,
        }),
        ...(data.firstEntrySwitcher === 'CONDITIONAL_MARKET' && {
          triggerPrice: roundToTickSize(
            entry.triggerPrice,
            currentSymbol?.priceTickSize,
          ),
          typeTriggerPrice: entry?.triggerPriceType?.value,
        }),
        unitsBase: roundToTickSize(entry.units, currentSymbol?.lotTickSize),
      }));
    } else {
      // Use addEntry for additionalBaseOrders when addEntryArray is empty or not present
      additionalBaseOrders = [
        {
          side: data.exchangeOption.toUpperCase(),
          type: data.firstEntrySwitcher.toUpperCase(),
          total: parseFloat(data?.addEntry?.total),
          ...(data.firstEntrySwitcher === 'LIMIT' && {
            limitPrice: roundToTickSize(
              data?.addEntry?.orderPrice,
              currentSymbol?.priceTickSize,
            ),
          }),
          ...(data.firstEntrySwitcher === 'CONDITIONAL_LIMIT' && {
            limitPrice: roundToTickSize(
              data?.addEntry?.orderPrice,
              currentSymbol?.priceTickSize,
            ),
            triggerPrice: roundToTickSize(
              data?.addEntry?.triggerPrice,
              currentSymbol?.priceTickSize,
            ),
            typeTriggerPrice: data?.addEntry?.select?.value,
          }),
          ...(data.firstEntrySwitcher === 'CONDITIONAL_MARKET' && {
            triggerPrice: roundToTickSize(
              data?.addEntry?.triggerPrice,
              currentSymbol?.priceTickSize,
            ),
            typeTriggerPrice: data?.addEntry?.select?.value,
          }),
          // limitPrice: parseFloat(data?.addEntry?.orderPrice),
          unitsBase: roundToTickSize(
            data?.addEntry?.units,
            currentSymbol?.lotTickSize,
          ),
        },
      ];
    }
    let takeProfits;
    if (data.takeProfitArray && data.takeProfitArray.length > 0) {
      // Map addEntryArray to additionalBaseOrders when addEntryArray is present and not empty
      takeProfits = data.takeProfitArray.map((takeProfit) => ({
        side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
        type: data.takeProfitSwitcher,
        ...(data.takeProfitSwitcher === 'LIMIT' && {
          limitPrice: parseFloat(takeProfit?.price),
        }),
        ...(data.takeProfitSwitcher === 'CONDITIONAL_MARKET' && {
          triggerPrice: parseFloat(takeProfit?.price),
        }),
        /// TOFIX rename data before at collecting not mapping
        volume: parseFloat(takeProfit.total),
        percent: parseFloat(takeProfit.percent),
      }));
    } else {
      // Use addEntry for additionalBaseOrders when addEntryArray is empty or not present
      takeProfits = [
        {
          side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
          type: data.takeProfitSwitcher,
          ...(data.takeProfitSwitcher === 'LIMIT' && {
            limitPrice: parseFloat(data.takeProfit?.orderPrice),
          }),
          ...(data.takeProfitSwitcher === 'CONDITIONAL_MARKET' && {
            triggerPrice: parseFloat(data.takeProfit?.triggerPrice),
          }),
          percent: data.takeProfit?.orderPricePercent,
          volume: parseFloat(data.takeProfit?.volume),
        },
      ];
    }

    let stopLoss = {
      side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
      type: data.stopLossSwitcher,
      typeTriggerPrice: data.stopLoss?.select?.value,
      triggerPrice: parseFloat(data.stopLoss?.triggerPrice),
      triggerPercent: data.stopLoss?.triggerPricePercent,
      limitPricePercent: parseFloat(data.stopLoss?.orderPricePercent),
      ...(data.stopLossSwitcher === 'CONDITIONAL_LIMIT' && {
        limitPrice: parseFloat(data.stopLoss?.orderPrice),
      }),
    };
    const getBaseOrder = (data) => {
      const coinsExist = data.coinsToggler === true;
      const coinsExistType = 'SKIP_FIRST_STEP';
      const sideBuy = data.exchangeOption === 'BUY';
      const coinsExistBaseOrder = {
        baseOrder: {
          side: data.exchangeOption.toUpperCase(),
          type: coinsExistType,
          //total в терминал не передаем никогда в терминал
          total: roundToTickSize(
            parseFloat(data?.coinsExist?.total),
            currentSymbol?.priceTickSize,
          ),
          ...(sideBuy && {
            /// для модалки price, для формы - boughtPrice
            price: parseFloat(data?.coinsExist?.boughtPrice),
            
            // для модалки units для формы - unitsBase
            units: roundToTickSize(
              data?.coinsExist?.units,
              currentSymbol?.lotTickSize,
            ),
          }),
          ...(!sideBuy && {
            /// для модалки price, для формы - soldPrice
            price: parseFloat(data?.coinsExist?.soldPrice),
            // для модалки units для формы - unitsQuote
            units: roundToTickSize(
              data?.coinsExist?.units,
              currentSymbol?.lotTickSize,
            ),
          }),
        },
      };

      const coinsNotExistBaseOrder = {
        baseOrder: {
          side: data.exchangeOption.toUpperCase(),
          type: data.baseOrderSwitcher.toUpperCase(),
          //total в терминал не передаем никогда
          total: parseFloat(data.baseOrder.total),
          ...(data.baseOrderSwitcher === 'LIMIT' && {
            limitPrice: parseFloat(data.baseOrder.orderPrice),
          }),
          ...(data.baseOrderSwitcher === 'CONDITIONAL' && {
            ...(data.conditionalSwitcher === 'LIMIT' && {
              type: CONDLIMIT_VALUE,
              limitPrice: parseFloat(data.baseOrder.orderPrice),
              triggerPrice: parseFloat(data.baseOrder.triggerPrice),
              typeTriggerPrice: data.baseOrder?.select?.value,
            }),
            ...(data.conditionalSwitcher === 'MARKET' && {
              type: CONDMARKET_VALUE,
              typeTriggerPrice: data.baseOrder?.select?.value,
              triggerPrice: parseFloat(data.baseOrder.triggerPrice),
            }),
          }),
          unitsBase: roundToTickSize(
            data?.baseOrder?.units,
            currentSymbol?.lotTickSize,
          ),
        },
      };
      
      return coinsExist ? coinsExistBaseOrder : coinsNotExistBaseOrder;
    };

    const transformedData = {
      form: {
        baseSymbol: currentSymbol.baseAsset,
        quoteSymbol: currentSymbol.quoteAsset,
        exchangeAccountUuid: user_id,
        ...(getBaseOrder(data)),
        ...(data.additionalEntryToggler && {
          additionalBaseOrders: additionalBaseOrders,
        }),
        ...(data.stopLossToggler && {
          stopLoss: stopLoss,
        }),
        ...(data.takeProfitToggler && {
          takeProfits: takeProfits,
        }),
      },
    };
    
    const totalPercent = data.takeProfitArray?.reduce(
      (acc, curr) => acc + parseFloat(curr.total || 0),
      0,
    );

    const takeProfitEnabled = data?.takeProfitToggler;
    
    if (takeProfitEnabled && data?.takeProfitArray?.length !== 0 && totalPercent !== 100) {
      return false; // Stop the submission if the validation fails
    }

    return transformedData;
  };

  const uselessSwitcherMapping = {
    Average: 'FROM_AVERAGE',
    Fixed: 'FIXED',
  };

  const onSubmit = async (data) => {
    let additionalBaseOrders;
    if (data.addEntryArray && data.addEntryArray.length > 0) {
      // Map addEntryArray to additionalBaseOrders when addEntryArray is present and not empty
      additionalBaseOrders = data.addEntryArray.map((entry) => ({
        side: data?.exchangeOption.toUpperCase(),
        type: data.firstEntrySwitcher.toUpperCase(),
        ...(data.firstEntrySwitcher === 'LIMIT' && {
          limitPrice: roundToTickSize(
            entry.price,
            currentSymbol?.priceTickSize,
          ),
        }),
        ...(data.firstEntrySwitcher === 'CONDITIONAL_LIMIT' && {
          limitPrice: roundToTickSize(
            entry.price,
            currentSymbol?.priceTickSize,
          ),
          triggerPrice: roundToTickSize(
            entry.triggerPrice,
            currentSymbol?.priceTickSize,
          ),
          typeTriggerPrice: entry?.triggerPriceType?.value,
        }),
        ...(data.firstEntrySwitcher === 'CONDITIONAL_MARKET' && {
          triggerPrice: roundToTickSize(
            entry.triggerPrice,
            currentSymbol?.priceTickSize,
          ),
          typeTriggerPrice: entry?.triggerPriceType?.value,
        }),
        unitsBase: roundToTickSize(entry.units, currentSymbol?.lotTickSize),
      }));
    } else {
      // Use addEntry for additionalBaseOrders when addEntryArray is empty or not present
      additionalBaseOrders = [
        {
          side: data.exchangeOption.toUpperCase(),
          type: data.firstEntrySwitcher.toUpperCase(),
          ...(data.firstEntrySwitcher === 'LIMIT' && {
            limitPrice: roundToTickSize(
              data?.addEntry?.orderPrice,
              currentSymbol?.priceTickSize,
            ),
          }),
          ...(data.firstEntrySwitcher === 'CONDITIONAL_LIMIT' && {
            limitPrice: roundToTickSize(
              data?.addEntry?.orderPrice,
              currentSymbol?.priceTickSize,
            ),
            triggerPrice: roundToTickSize(
              data?.addEntry?.triggerPrice,
              currentSymbol?.priceTickSize,
            ),
            typeTriggerPrice: data?.addEntry?.select?.value,
          }),
          ...(data.firstEntrySwitcher === 'CONDITIONAL_MARKET' && {
            triggerPrice: roundToTickSize(
              data?.addEntry?.triggerPrice,
              currentSymbol?.priceTickSize,
            ),
            typeTriggerPrice: data?.addEntry?.select?.value,
          }),
          // limitPrice: parseFloat(data?.addEntry?.orderPrice),
          unitsBase: roundToTickSize(
            data?.addEntry?.units,
            currentSymbol?.lotTickSize,
          ),
        },
      ];
    }
    
    let takeProfits;
    if (data.takeProfitArray && data.takeProfitArray.length > 0) {
      // Map addEntryArray to additionalBaseOrders when addEntryArray is present and not empty
      takeProfits = data.takeProfitArray.map((takeProfit) => ({
        side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
        type: data.takeProfitSwitcher,
        ...(data.takeProfitSwitcher === 'LIMIT' && {
          limitPrice: parseFloat(takeProfit?.price),
        }),
        ...(data.takeProfitSwitcher === 'CONDITIONAL_MARKET' && {
          triggerPrice: parseFloat(takeProfit?.price),
          typeTriggerPrice: 'LAST',
        }),
        priceRecalculation: uselessSwitcherMapping[data.takeProfitUselessSwitcher],
        /// TOFIX rename data before at collecting not mapping
        // volume: parseFloat(takeProfit.total),
        // TOFIX берем значение ползунка перед отправкой в терминал
        percent: parseFloat(takeProfit.total),
        
      }));
    } else {
      // Use addEntry for additionalBaseOrders when addEntryArray is empty or not present
      takeProfits = [
        {
          side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
          type: data.takeProfitSwitcher,
          ...(data.takeProfitSwitcher === 'LIMIT' && {
            limitPrice: parseFloat(data.takeProfit?.orderPrice),
          }),
          ...(data.takeProfitSwitcher === 'CONDITIONAL_MARKET' && {
            triggerPrice: parseFloat(data.takeProfit?.triggerPrice),
            typeTriggerPrice: 'LAST',
          }),
          priceRecalculation: uselessSwitcherMapping[data.takeProfitUselessSwitcher],
          //  data.takeProfit?.orderPricePercent только для модалки, но поле percent должно брать значение volume перед отправкой в терминал
          percent: parseFloat(data.takeProfit?.volume),
          //должно быть percent для терминала
          // volume: parseFloat(data.takeProfit?.volume),
        },
      ];
    }
    
    
    let stopLoss = {
      side: data.exchangeOption === 'BUY' ? 'SELL' : 'BUY',
      type: data.stopLossSwitcher,
      typeTriggerPrice: data.stopLoss?.select?.value,
      priceRecalculation: uselessSwitcherMapping[data.stopLossUselessSwitcher],
      triggerPrice: parseFloat(data.stopLoss?.triggerPrice),
      ...(data.stopLossSwitcher === 'CONDITIONAL_LIMIT' && {
        limitPrice: parseFloat(data.stopLoss?.orderPrice),
      }),
    };

    const getBaseOrder = (data) => {
      const coinsExist = data.coinsToggler === true;

      const coinsExistType = 'SKIP_FIRST_STEP';
      const sideBuy = data.exchangeOption === 'BUY';
      const coinsExistBaseOrder = {
        baseOrder: {
          side: data.exchangeOption.toUpperCase(),
          type: coinsExistType,
          //total в терминал не передаем никогда в терминал
          // total: roundToTickSize(
          //   data?.coinsExist?.total,
          //   currentSymbol?.priceTickSize
          // ),
          ...(sideBuy && {
            /// для модалки price, для формы - boughtPrice
            boughtPrice: parseFloat(data?.coinsExist?.boughtPrice),
            
            // для модалки units для формы - unitsBase
            unitsBase: roundToTickSize(
              data?.coinsExist?.units,
              currentSymbol?.lotTickSize,
            ),
          }),
          ...(!sideBuy && {
            /// для модалки price, для формы - soldPrice
            soldPrice: parseFloat(data?.coinsExist?.soldPrice),
            // для модалки units для формы - unitsQuote (берется из total)
            unitsQuote: data?.coinsExist?.total,
          }),
        },
      };
      
      const coinsNotExistBaseOrder = {
        baseOrder: {
          side: data.exchangeOption.toUpperCase(),
          type: data.baseOrderSwitcher.toUpperCase(),
          //total в терминал не передаем никогда
          // total: parseFloat(data.baseOrder.total),
          ...(data.baseOrderSwitcher === 'LIMIT' && {
            limitPrice: parseFloat(data?.baseOrder?.orderPrice),
          }),
          ...(data.baseOrderSwitcher === 'CONDITIONAL' && {
            ...(data.conditionalSwitcher === 'LIMIT' && {
              type: CONDLIMIT_VALUE,
              limitPrice: parseFloat(data.baseOrder?.orderPrice),
              triggerPrice: parseFloat(data.baseOrder?.triggerPrice),
              typeTriggerPrice: data.baseOrder?.select?.value,
            }),
            ...(data.conditionalSwitcher === 'MARKET' && {
              type: CONDMARKET_VALUE,
              typeTriggerPrice: data.baseOrder?.select?.value,
              triggerPrice: parseFloat(data.baseOrder?.triggerPrice),
            }),
          }),
          unitsBase: roundToTickSize(
            data?.baseOrder?.units,
            currentSymbol?.lotTickSize,
          ),
        },
      };
      
      return coinsExist ? coinsExistBaseOrder : coinsNotExistBaseOrder;
    };
    
    const transformedData = {
      form: {
        baseSymbol: currentSymbol.baseAsset,
        quoteSymbol: currentSymbol.quoteAsset,
        exchangeAccountUuid: user_id,
        ...(getBaseOrder(data)),
        // Add additionalBaseOrders, stopLoss, and takeProfits conditionally
        ...(data.additionalEntryToggler && {
          additionalBaseOrders: additionalBaseOrders,
        }),
        ...(data.stopLossToggler && {
          stopLoss: stopLoss,
        }),
        ...(data.takeProfitToggler && {
          takeProfits: takeProfits,
        }),
      },
    };

    const userData = {
      skyrexUserUuid: skyrexUuid,
      exchangeAccountUuid: user_id,
      baseSymbol: currentSymbol.baseAsset,
      quoteSymbol: currentSymbol.quoteAsset,
    };

    // Combine transformedData and userData into a single object
    const combinedData = {
      form: transformedData.form,
      userData: userData,
    };
    
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    
    try {
      
      const responseFromCreateNewTrade = await createNewTrade(combinedData);
      
      if (responseFromCreateNewTrade.success) {
        
        const dataForCheckRequest = {
          smartTradeUuids: [responseFromCreateNewTrade.data.smartTradeUuid],
        };
        
        
        await delay(2000); 
        
        //TODO: rewrite to send requests until status will be !new
        //cover all possible statuses
        const responseFromCheckTrade = await checkTradeCreated(dataForCheckRequest); 
        
        if (responseFromCheckTrade.data.smartTrades[0].status === 'cancelled') {
          onFailure();
          // return false;
          return;
        }
        const responseTradeRichInfo = await getTradeRichInfo(dataForCheckRequest);

        dispatch(terminalActions.setAddNewTrade(responseTradeRichInfo.data.smartTrades[0])); 
        //TODO: add trade to active trades array with dispatch
        // dispatch(add_user_manual_trade(response.data[0]));
        // return true;
      } else {
        // onFailure();
        if (responseFromCreateNewTrade.data.message === 'Maximum number of active trades reached. Please upgrade subscription') {
          setIsFailureCreateModalOpen(true);
        }

        return false;
      }

      // Handle successful form submission (e.g., showing a success message)
    } catch (error) {
      // onFailure();
    }
  };

  const buyStyle = {
    backgroundColor:
      selectedOption === 'BUY' ? 'rgba(46, 205, 153, 1)' : 'transparent',
    color: selectedOption === 'BUY' ? 'white' : 'rgba(58, 58, 60, 0.4)',
    marginLeft: '3px',
    marginBottom: '2px',
    marginTop: '2px',
  };

  const sellStyle = {
    backgroundColor:
      selectedOption === 'SELL' ? 'rgba(243, 34, 34, 1)' : 'transparent',
    color: selectedOption === 'SELL' ? 'white' : 'rgba(58, 58, 60, 0.4)',
    marginRight: '3px',
    marginBottom: '2px',
    marginTop: '2px',
  };

  const sideOptions = [
    {
      label: 'Buy',
      value: 'BUY',
      style: buyStyle,
    },
    {
      label: 'Sell',
      value: 'SELL',
      style: sellStyle,
    },
  ];

  if (!currentSymbol) {
    <Loader isContentOverflow={true} />;
  } 
  
  return (
    <FormProvider {...methods}>
      <ConfigProvider
        theme={{
          components: {
            Slider: sliderStyles,
            Switch: switchStyles,
            Input: inputFontSize,
            InputNumber: inputFontSize,
            Table: tableStyles,
            Divider: {
              marginLG: 10,
            },
            Spin: {
              colorPrimary: '#2ECD99',
            },
            Modal: {
              borderRadiusLG: 16,
            },
          },
        }}
      >
        <div
          style={{
            width: '250px',
            height: '550px',
            overflowX: 'visible',
            // overflowX: "hidden",
          }}
        >
          <form
            onKeyDown={handleKeyDown}
            style={{
              width: '243px',
              height: '550px',
              overflowY: 'scroll',
              overflowX: 'visible',
            }}
          >
            <div style={basicSectionsLayout}>
              <GenericSwitcher
                options={sideOptions}
                controlName={SIDE_OPTION_SWITCHER}
              />
              <BaseOrderSection formDisabled={formDisabled}/>
              <AdditionalEntrySection formDisabled={formDisabled} />
              <TakeProfitSection formDisabled={formDisabled}/>
              <StopLossSection formDisabled={formDisabled}/>
            </div>

            <ClassicButton
              style={{
                marginTop: '15px', 
                cursor: formDisabled ? 'not-allowed' : 'pointer',
              }}
              disabled={formDisabled}
              className={classNames('CreateTradeButton', {}, ['button-class'])}
              onClick={handleCreateTradeClick}
            >
              {formDisabled ? 'No balance' : 'Create Trade'}
            </ClassicButton>
          </form>

          <Modal
            centered={true}
            width={500}
            title='Сonfirm your transaction'
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            mask={true}
            footer={[
              <div style={buttonsWrapperStyle}>
                <Button
                  key='verify'
                  type='primary'
                  disabled={isLoading}
                  loading={isLoading}
                  style={verifyButtonStyles}
                  onClick={handleVerifyTrade}
                  className='verifyButtonStyles'
                >
                  Create trade
                </Button>
              </div>,
            ]}
          >
            <OrderConfirmTable trade={formData?.form} />{' '}
          </Modal>

          <Modal
            centered
            width={400}
            open={isSuccessModalOpen}
            onCancel={closeModal}
            footer={[
              <div style={successModalButtonContainer}>
                <Button
                  style={successModalButtonStyle}
                  key='submit'
                  type='primary'
                  onClick={closeModal}
                >
                  OK
                </Button>
              </div>,
            ]}
          >
            <div style={successModalBodyContainer}>
              <div style={successModalTextStyle}>
                Position successfully created
              </div>
            </div>
          </Modal>

          <Modal
            centered
            width={400}
            open={isFailureModalOpen}
            onCancel={closeModal}
            footer={[
              <div style={successModalButtonContainer}>
                <Button
                  style={successModalButtonStyle}
                  key='submit'
                  type='primary'
                  onClick={closeModal}
                >
                  OK
                </Button>
              </div>,
            ]}
          >
            <div style={successModalBodyContainer}>
              <div style={successModalTextStyle}>Failed to create position, check if trading permissions are enabled for the account</div>
            </div>
          </Modal>

          <Modal
            centered
            width={400}
            open={isTakeProfitErrorModalOpen}
            onCancel={closeModal}
            footer={[
              <div style={successModalButtonContainer}>
                <Button
                  style={successModalButtonStyle}
                  key='submit'
                  type='primary'
                  onClick={closeModal}
                >
                  OK
                </Button>
              </div>,
            ]}
          >
            <div style={successModalBodyContainer}>
              <div style={successModalTextStyle}>
                Take profits volume should be 100%
              </div>
            </div>
          </Modal>

          <EmptySubscription
            isOpen={isFailureCreateModalOpen} 
            handleClose={() => {
              setIsFailureCreateModalOpen(false);
            }}
            modalTitle='Upgrade plan'
            modalDescription='Maximum quantity of active Smart trades is reached'
            modalButtonTitle='Upgrade'
            modalButtonAction={() => {
              navigate('/subscriptions');
            }}
            isError={true}
          />
        </div>
      </ConfigProvider>
    </FormProvider>
  );
};
