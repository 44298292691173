// @ts-nocheck

import { Input, InputNumber, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getUserWalletBaseAsset } from '../../../../../../../entities/terminal/model/selectors/get-user-wallet-base-asset/get-user-wallet-base-asset.ts';
import { getWalletData } from '../../../../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data.ts';
import { terminalActions } from '../../../../../../../entities/terminal/model/slices/terminal-slice.ts';
import { RangeContainer } from '../../../RangeContainer/RangeContainer';
import COLORS from '../../../colors';
import '../../../OrderPriceGroup/OrderPriceGroup.css';
import {
  roundToTickSize,
  roundUnitsToTickSize,
} from '../../../exchange-form-utils.js';
import {
  CONDITIONAL_VALUE,
  LABEL_ORDER_PRICE,
  LABEL_TOTAL,
  LABEL_UNITS,
  MARKET_VALUE,
} from '../../../exchange-form.constants.js';
import { basicInputStyle } from '../../../exchange-form.styles.ts';
import debounce from 'lodash.debounce';
import {
  BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  BASE_ORDER_SWITCHER_FIELD_NAME,
} from '../BaseOrderInputs.jsx';
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';

const {
  Text, 
} = Typography;

interface BasicInputsProps {
  labelOrder: string;
  labelTotal: string;
  labelUnits: string;
  baseOrderValue: number;
  freeCoin: number;
  disabled: boolean;
  sliderName: string;

  totalFieldName: string;
  unitsFieldName: string;
  orderPriceFieldName: string;

  chartLastPrice: number;

  addEntryEnabled: boolean | undefined;
  isCondMarket: boolean | undefined;
  isCondLimit: boolean | undefined;
  triggerPriceInputControlName: string;
  selectControlName: string;
  orderPricePercentFieldName: string;
  isMarketOrder: boolean | undefined;
}

export const SellBasicInputs: React.FC<BasicInputsProps> = ({
  chartLastPrice,
  formDisabled,
  disabled,
  sliderName,
  totalFieldName,
  unitsFieldName,
  orderPriceFieldName,
  addEntryEnabled,
  isMarketOrder,
}) => {
  const {
    control, setValue, getValues, clearErrors, watch, 
  } = useFormContext();

  const [newSliderValue, setNewSliderValue] = useState(0);

  const currentSymbol = useSelector(getCurrentSymbol);
  const walletData = useSelector(getUserWalletSelectedSymbol);
  const userWalletBaseAsset = useSelector(
    getUserWalletBaseAsset,
  );

  const baseOrderSwitcherValue = watch(BASE_ORDER_SWITCHER_FIELD_NAME);
  const isConditionalOrder = baseOrderSwitcherValue === CONDITIONAL_VALUE;

  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  );

  const isOrderPriceInvisible = conditionalOrderSwitcherValue === MARKET_VALUE;

  const freeCoin = userWalletBaseAsset?.free ?? 0;

  const validateZeroValue = (value) => {
    return value !== 0 || 'Value cannot be zero';
  };

  const validateMinUnitsValue = (value) => {
    return (
      value >= currentSymbol?.lotMin ||
      `Units should be greater than ${currentSymbol?.lotMin}`
    );
  };

  const validateMaxUnitsValue = (value) => {
    if (!currentSymbol.marketLotMax) {
      return true;
    } else {
      return (
        value <= currentSymbol.marketLotMax ||
        `Units should be less than ${currentSymbol.marketLotMax}`
      );
    }
  };

  const dispatch = useDispatch();

  const handleFormatBaseAsset = (value) => {
    if (!isFinite(value)) {
      return '0';
    }
    
    const precision = (typeof currentSymbol?.baseAssetPrecision === 'number' && currentSymbol.baseAssetPrecision > 0)
      ? currentSymbol?.baseAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  const defaultSliderValue = 10;

  useEffect(() => {
    setValue(orderPriceFieldName, chartLastPrice);

    setValue(
      unitsFieldName,
      isMarketOrder
        ? freeCoin * 0.1
        : roundToTickSize(freeCoin * 0.1, currentSymbol?.lotTickSize),
      {
        shouldValidate: true, 
      },
    );

    setValue(
      totalFieldName,
      roundToTickSize(
        freeCoin * 0.1 * chartLastPrice,
        currentSymbol?.priceTickSize,
      ),
    );

    setNewSliderValue(defaultSliderValue);
    setValue(sliderName, defaultSliderValue);
    clearErrors(totalFieldName);
  }, [chartLastPrice, isMarketOrder]);

  const getMaxTotal = () => {
    const orderPriceValues = getValues('baseOrder.orderPrice');

    return freeCoin * orderPriceValues;
  };

  const handleChangeOrderPrice = (newOrderPrice) => {
    const roundedOrderPrice = roundToTickSize(
      newOrderPrice,
      currentSymbol?.priceTickSize,
    );
    setValue(orderPriceFieldName, roundedOrderPrice, {
      shouldValidate: true, 
    });
    if (!addEntryEnabled) {
      dispatch(terminalActions.setBaseOrderPrice(roundedOrderPrice));
    }
    const unitsValue = getValues(unitsFieldName);
    const total = roundedOrderPrice * unitsValue;

    setValue(
      totalFieldName,
      roundToTickSize(total, currentSymbol?.priceTickSize),
      {
        shouldValidate: true, 
      },
    );
  };

  const handleChangeUnits = (newUnits) => {
    const roundedUnits = roundUnitsToTickSize(
      newUnits,
      currentSymbol?.lotTickSize,
    );
    setValue(unitsFieldName, isMarketOrder ? newUnits : roundedUnits, {
      shouldValidate: true,
    });

    const orderPriceValues = getValues(orderPriceFieldName);
    setNewSliderValue(Math.round((roundedUnits / freeCoin) * 100));

    setValue(sliderName, Math.round((roundedUnits / freeCoin) * 100), {
      shouldValidate: true,
    });

    setValue(
      totalFieldName,
      roundToTickSize(
        roundedUnits * orderPriceValues,
        currentSymbol?.priceTickSize,
      ),
      {
        shouldValidate: true, 
      },
    );
  };

  const handleChangeTotal = (newTotal) => {
    const roundedTotal = roundToTickSize(newTotal, currentSymbol.priceTickSize);
    const orderPriceValues = getValues(orderPriceFieldName);

    setValue(totalFieldName, roundedTotal, {
      shouldValidate: true, 
    });

    const roundedUnits = roundUnitsToTickSize(
      roundedTotal / orderPriceValues,
      currentSymbol?.lotTickSize,
    );

    setValue(
      unitsFieldName,
      isMarketOrder ? roundedTotal / orderPriceValues : roundedUnits,
      {
        shouldValidate: true, 
      },
    );

    setNewSliderValue(Math.round((roundedUnits / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedUnits / freeCoin) * 100));
  };

  const debouncedHandleSliderChange = debounce((sliderValue) => {
    const newUnits = Math.min((sliderValue / 100) * freeCoin);
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedUnits = roundToTickSize(newUnits, currentSymbol?.lotTickSize);
    setValue(unitsFieldName, roundedUnits, {
      shouldValidate: true, 
    });
    setValue(
      totalFieldName,
      roundToTickSize(
        newUnits * orderPriceValues,
        currentSymbol?.priceTickSize,
      ),
      {
        shouldValidate: true, 
      },
    );
  }, 300);

  const maxFreeCoinValidation = (value) => {
    return (
      value <= freeCoin ||
      `Value cannot exceed ${freeCoin} ${walletData?.symbol}`
    );
  };

  const totalInput = useMemo(() => (
    <>
      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_TOTAL}
      </Text>
      <div>
        <Controller
          name={totalFieldName}
          control={control}
          defaultValue={handleFormatQuoteAsset(freeCoin * 0.1 * chartLastPrice)}
          rules={{
            required: 'Total field is required',
            validate: {
              notZero: validateZeroValue,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                min={0}
                disabled={formDisabled}
                formatter={handleFormatQuoteAsset}
                controls={false}
                size='small'
                suffix={currentSymbol?.quoteAsset}
                onChange={handleChangeTotal}
              />
              {error && (
                <Text style={{
                  color: 'red', fontSize: 10, 
                }}>
                  {error.message}
                </Text>
              )}
            </>
          )}
        />
      </div>
    </>
  ), [totalFieldName, control, freeCoin, chartLastPrice, formDisabled, currentSymbol?.quoteAsset, handleChangeTotal, handleFormatQuoteAsset, validateZeroValue]);

  const unitsInput = useMemo(() => (
    <>
      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_UNITS}
      </Text>
      <div>
        <Controller
          name={unitsFieldName}
          control={control}
          defaultValue={freeCoin * 0.1}
          rules={{
            required: 'Total field is required',
            validate: {
              notZero: validateZeroValue,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                size='small'
                disabled={formDisabled}
                max={freeCoin}
                controls={false}
                suffix={currentSymbol?.baseAsset}
                formatter={handleFormatBaseAsset}
                onChange={handleChangeUnits}
              />
              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
          )}
        />
      </div>
    </>
  ), [currentSymbol.baseAsset, formDisabled]);

  return (
    <>
      {!isOrderPriceInvisible && (
        <div>
          <Text
            style={{
              fontSize: 12, color: COLORS.primary, 
            }}
            type='secondary'
          >
            {LABEL_ORDER_PRICE}
          </Text>
          <div>
            <Controller
              name={orderPriceFieldName}
              control={control}
              defaultValue={chartLastPrice}
              rules={{
                required: 'Order price is required',
                validate: validateZeroValue,
              }}
              render={({
                field, fieldState: {
                  error, 
                }, 
              }) => (
                <>
                  <InputNumber
                    {...field}
                    style={{
                      ...basicInputStyle,
                      border: error ? '1px solid red' : '',
                    }}
                    disabled={disabled || formDisabled}
                    size='small'
                    controls={false}
                    formatter={handleFormatQuoteAsset}
                    suffix={currentSymbol.quoteAsset}
                    onChange={handleChangeOrderPrice}
                  />
                  {error && (
                    <Text style={{
                      color: 'red', fontSize: 10, 
                    }}>
                      {error.message}
                    </Text>
                  )}
                </>
              )}
            />
          </div>
        </div>
      )}

      {totalInput}

      {unitsInput}

      <RangeContainer
        fieldDisabled={formDisabled}
        name={sliderName}
        defaultValue={defaultSliderValue}
        onSliderChange={debouncedHandleSliderChange}
        valueNew={newSliderValue}
      />
    </>
  );
};
