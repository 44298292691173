// @ts-nocheck

import { InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getBaseOrderPrice } from '../../../../../../../entities/terminal/model/selectors/get-base-order-price/get-base-order-price.ts';
import { getCurrentSymbol } from '../../../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getUserWalletBaseAsset } from '../../../../../../../entities/terminal/model/selectors/get-user-wallet-base-asset/get-user-wallet-base-asset.ts';
import { OrderPriceGroup } from '../../../OrderPriceGroup/OrderPriceGroup.tsx';
import { RangeContainer } from '../../../RangeContainer/RangeContainer';
import { TriggerPriceGroup } from '../../../TriggerPriceGroup/TriggerPriceGroup.jsx';
import COLORS from '../../../colors';
import '../../../OrderPriceGroup/OrderPriceGroup.css';
import {
  roundToTickSize,
  roundUnitsToTickSize,
} from '../../../exchange-form-utils.js';
import {
  ADDITIONAL_ENTRY_SECTION_TYPE,
  ADDITIONAL_ENTRY_TOGGLER,
  FIELD_TOTAL,
  FIELD_UNITS,
  LABEL_ORDER_PRICE,
  LABEL_TOTAL,
  LABEL_UNITS,
} from '../../../exchange-form.constants.js';
import { basicInputStyle } from '../../../exchange-form.styles.ts';
import debounce from 'lodash.debounce';

const {
  Text, 
} = Typography;

interface BasicInputsProps {
  labelOrder: string;
  labelTotal: string;
  labelUnits: string;
  baseOrderValue: number;
  freeCoin: number;
  disabled: boolean;
  sliderName: string;

  totalFieldName: string;
  unitsFieldName: string;
  orderPriceFieldName: string;

  chartLastPrice: number;

  addEntryEnabled: boolean | undefined;
  isCondMarket: boolean | undefined;
  isCondLimit: boolean | undefined;
  triggerPriceInputControlName: string;
  selectControlName: string;
  orderPricePercentFieldName: string;
}

const ADD_ENTRY_ORDER_PRICE_FIELD_NAME = 'addEntry.orderPrice';

const BASE_ORDER_ORDER_PRICE_FIELD_NAME = 'baseOrder.orderPrice';

export const SellSectionInputs: React.FC<BasicInputsProps> = ({
  chartLastPrice,
  fieldDisabled,
  disabled,
  sliderName,
  totalFieldName,
  unitsFieldName,
  orderPriceFieldName,
  addEntryEnabled,
  isCondMarket,
  isCondLimit,
  triggerPriceInputControlName,
  selectControlName,
  orderPricePercentFieldName,
}) => {
  const {
    control, setValue, getValues, 
    clearErrors, formState: {
      errors, 
    },
    watch, 
  } = useFormContext();

  const currentSymbol = useSelector(getCurrentSymbol);
  const baseOrderPrice = useSelector(getBaseOrderPrice);
  const userWalletBaseAsset = useSelector(
    getUserWalletBaseAsset,
  );
  const freeCoin = userWalletBaseAsset?.free ?? 0;
  
  const defaultPrice = baseOrderPrice * 1.05;
  
  const handleFormatBaseAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = currentSymbol.baseAssetPrecision;
    
    const formattedValue = Number(value).toFixed(precision);
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    const formattedValue = Number(value).toFixed(precision);
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleParseNumber = (value: string) => {
    return value.replace(/[^0-9.]/g, '');
  };

  const [newSliderValue, setNewSliderValue] = useState(0);

  useEffect(() => {
    setValue(orderPriceFieldName, roundToTickSize(defaultPrice, currentSymbol.priceTickSize));

    const newUnits = roundToTickSize(freeCoin * 0.1, currentSymbol.lotTickSize);

    setValue(unitsFieldName, newUnits, {
      shouldValidate: true, 
    });

    const newTotal = roundToTickSize(freeCoin * 0.1 * defaultPrice, currentSymbol.priceTickSize);

    setValue(totalFieldName, newTotal);
  }, [chartLastPrice]);

  const defaultSliderValue = 10;

  const handleChangeOrderPrice = (newOrderPrice) => {
    const roundedOrderPrice = roundToTickSize(newOrderPrice, currentSymbol.priceTickSize);
    const unitsValue = getValues(unitsFieldName);
    const total = roundedOrderPrice * unitsValue;
    
    setValue(orderPriceFieldName, roundedOrderPrice, {
      shouldValidate: true, 
    });
    setValue(totalFieldName, roundToTickSize(total, currentSymbol.priceTickSize), {
      shouldValidate: true,
    });
  };

  const handleChangeTotal = (newTotal) => {
    const roundedTotal = roundToTickSize(newTotal, currentSymbol.priceTickSize);
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedUnits = roundUnitsToTickSize(
      roundedTotal / orderPriceValues,
      currentSymbol.lotTickSize,
    );

    setValue(totalFieldName, roundedTotal, {
      shouldValidate: true, 
    });
    setValue(unitsFieldName, roundedUnits, {
      shouldValidate: true, 
    });
    setNewSliderValue(Math.round((roundedUnits / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedUnits / freeCoin) * 100));
  };

  const handleChangeUnits = (newUnits) => {
    const roundedUnits = roundUnitsToTickSize(newUnits, currentSymbol.lotTickSize);
    const orderPriceValues = getValues(orderPriceFieldName);
    

    setValue(unitsFieldName, roundedUnits, {
      shouldValidate: true, 
    });
    setNewSliderValue(Math.round((roundedUnits / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedUnits / freeCoin) * 100), {
      shouldValidate: true,
    });
    setValue(
      totalFieldName,
      roundToTickSize(roundedUnits * orderPriceValues, currentSymbol.priceTickSize),
      {
        shouldValidate: true, 
      },
    );
  };

  const debouncedHandleSliderChange = debounce((sliderValue) => {
    const newUnits = Math.min((sliderValue / 100) * freeCoin);
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedUnits = roundToTickSize(newUnits, currentSymbol.lotTickSize);

    setValue(unitsFieldName, roundedUnits, {
      shouldValidate: true, 
    });
    setValue(
      totalFieldName,
      roundToTickSize(newUnits * orderPriceValues, currentSymbol.priceTickSize),
      {
        shouldValidate: true, 
      },
    );
  }, 300);

  const validateZeroValue = (value) => {
    return value !== 0 || 'Value cannot be zero';
  };

  return (
    <>
      {addEntryEnabled && (
        <>
          {isCondMarket ? (
            <TriggerPriceGroup
              priceInputControlName={triggerPriceInputControlName}
              selectControlName={selectControlName}
              baseOrderValue={chartLastPrice}
              defaultPrice={defaultPrice}
            />
          ) : isCondLimit ? (
            <>
              <TriggerPriceGroup
                priceInputControlName={triggerPriceInputControlName}
                selectControlName={selectControlName}
                baseOrderValue={chartLastPrice}
                defaultPrice={defaultPrice}
              />
              <div>
                <Text
                  style={{
                    fontSize: 12, color: COLORS.primary, 
                  }}
                  type='secondary'
                >
                  {LABEL_ORDER_PRICE}
                </Text>
                <OrderPriceGroup
                  orderPriceControlName={orderPriceFieldName}
                  percentageControlName={orderPricePercentFieldName}
                  chartLastPrice={chartLastPrice}
                  defaultPrice={defaultPrice}
                  totalFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_TOTAL}`}
                  unitsFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_UNITS}`}
                  sellSide={true}
                />
              </div>
            </>
          ) : (
            <div>
              <Text
                style={{
                  fontSize: 12, color: COLORS.primary, 
                }}
                type='secondary'
              >
                {LABEL_ORDER_PRICE}
              </Text>
              <OrderPriceGroup
                orderPriceControlName={orderPriceFieldName}
                percentageControlName={orderPricePercentFieldName}
                chartLastPrice={chartLastPrice}
                totalFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_TOTAL}`}
                unitsFieldName={`${ADDITIONAL_ENTRY_SECTION_TYPE}.${FIELD_UNITS}`}
                defaultPrice={defaultPrice}
                sellSide={true}
              />
            </div>
          )}
        </>
      )}

      {!addEntryEnabled && (
        <>
          <Text style={{
            fontSize: 12, color: COLORS.primary, 
          }} type='secondary'>
            {LABEL_ORDER_PRICE}
          </Text>
          <div>
            <Controller
              name={orderPriceFieldName}
              control={control}
              defaultValue={chartLastPrice}
              rules={{
                required: 'Order price is required',
                validate: validateZeroValue,
              }}
              render={({
                field, fieldState: {
                  error, 
                }, 
              }) => (
                <>
                  <InputNumber
                    {...field}
                    style={{
                      ...basicInputStyle,
                      border: error ? '1px solid red' : '',
                    }}
                    disabled={disabled}
                    size='small'
                    controls={false}
                    suffix={currentSymbol.quoteAsset}
                    formatter={handleFormatQuoteAsset}
                    parser={handleParseNumber}
                    onChange={handleChangeOrderPrice}
                  />
                  {error && (
                    <Text style={{
                      color: 'red', fontSize: 10, 
                    }}>
                      {error.message}
                    </Text>
                  )}
                </>
              )}
            />
          </div>
        </>
      )}

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_TOTAL}
      </Text>
      <div>
        <Controller
          name={totalFieldName}
          control={control}
          defaultValue={roundToTickSize(freeCoin * 0.1 * defaultPrice)}
          rules={{
            required: 'Units field is required',
            validate: {
              notZero: validateZeroValue,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                min={0}
                size='small'
                controls={false}
                suffix={currentSymbol?.quoteAsset}
                formatter={handleFormatQuoteAsset}
                parser={handleParseNumber}
                onChange={handleChangeTotal}
              />
              {error && (
                <Text style={{
                  color: 'red', fontSize: 10, 
                }}>
                  {error.message}
                </Text>
              )}
            </>
          )}
        />
      </div>

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {LABEL_UNITS}
      </Text>
      <div>
        <Controller
          name={unitsFieldName}
          control={control}
          defaultValue={roundToTickSize(freeCoin * 0.1, currentSymbol.priceTickSize)}
          rules={{
            required: 'Total field is required',
            validate: {
              notZero: validateZeroValue,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                size='small'
                max={freeCoin}
                controls={false}
                suffix={currentSymbol.baseAsset}
                formatter={handleFormatBaseAsset}
                parser={handleParseNumber}
                onChange={handleChangeUnits}
              />
              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
          )}
        />
      </div>
      <RangeContainer
        name={sliderName}
        defaultValue={defaultSliderValue}
        onSliderChange={debouncedHandleSliderChange}
        valueNew={newSliderValue}
      />
    </>
  );
};
