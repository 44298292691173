import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Loader } from 'shared/ui';
import { connectOauth } from '../api/connect-oauth';

let link = '';

function createOAuthComponent(accountName: string, exchangeCode: string, pathSegment: string) {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);

    const skyrexUserUuid = getSkyrexUuid();

    const handleSendOauth = async (skyrexUserUuid: string, userAccessToken: string) => {
      try {
        setLoading(true);

        const dataForOauth = {
          skyrexUserUuid,
          userAccessToken,
          accountName,
          exchangeCode,
        };

        const connectionType = localStorage.getItem('connection_type');
        const prevLocation = localStorage.getItem('prev_location');
        
        if (connectionType === 'onboarding') {
          link += `${prevLocation}?mt=onboarding&status=success`;
        } else {
          link = prevLocation || '/my-accounts';
        }

        const oauthResponse = await connectOauth(dataForOauth);
        if (!oauthResponse.success) {
          navigate(link, {
            state: {
              success: false,
              message: oauthResponse?.data?.message,
            },
          });
          return;
        }
        navigate(link, {
          state: {
            success: true,
          },
        });
        return;
      } catch (error) {
        navigate(link, {
          state: {
            success: false,
            message: 'We will fix it soon, try again later or contact support',
          },
        });
        return;
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      const {
        pathname, 
        search,
      } = location;
      
      if (pathname.includes(pathSegment)) {
        
        const params = new URLSearchParams(search);
        const code = params.get('code');
        
        handleSendOauth(skyrexUserUuid!, code!);
      }
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

function createOAuthCryptoComComponent() {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const skyrexUserUuid = getSkyrexUuid();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      console.log('createOAuthCryptoComComponent', location, skyrexUserUuid);
      navigate('/my-accounts?mt=crypto-connected');
      setLoading(false);
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

export const OauthBinance = createOAuthComponent('My binance', 'binance', '/oauth/create/binance');
export const OauthBybit = createOAuthComponent('My bybit', 'bybit', '/oauth/create/bybit');
export const OauthOkx = createOAuthComponent('My okx', 'okx', '/oauth/create/okx');
// export const OauthCryptoCom = createOAuthCryptoComComponent('My cryptocom', 'crypto-com', '/oauth/crypto-com');
export const OauthCryptoCom = createOAuthCryptoComComponent();
