import { getActionResultContent, getTradeActionsContent } from 'pages/manual-trading/trading-terminal/components/active-trades/components/modal-contents.ts/modal-contents';
import { useCallback } from 'react';
import { ModalState } from './use-modal-state';

interface UseModalContentProps {
  modalState: ModalState;
  chartLastPrice: number;
  onClose: () => void;
}

export const useModalContent = ({ 
  modalState, 
  chartLastPrice, 
  onClose, 
}: UseModalContentProps) => {
  const renderModalContent = useCallback(() => {
    if (modalState.type === 'tradeActions') {
      return getTradeActionsContent({
        type: modalState.additionalData?.actionType,
        chartLastPrice,
        onClose,
        onConfirm: () => modalState.additionalData?.actionFn(),
      });
    }
    
    if (modalState.type === 'actionResult') {
      return getActionResultContent({
        status: modalState.status,
        message: modalState.message,
      });
    }
    
    return null;
  }, [modalState, chartLastPrice, onClose]);

  return {
    renderModalContent, 
  };
};
