import { Box, Hidden } from '@mui/material';
import { Button, Layout } from 'antd';
import { disableDemoAccount, enableDemoAccount } from 'entities/user/api';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { userActions } from 'entities/user/model/slices/user-slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeDemoMode, setDemoMode } from 'shared/helpers/storage-helper';
import { useQuery } from 'shared/hooks';
import {
  Burger,
  Logo,
} from 'shared/icons';
import { ChangePasswordModal, Switch } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as FailureModal,
  Onboarding,
  ConnectedExchangeAccountStatus as SuccessModal,
} from 'shared/ui/modals';
import { getInviteFriendsUrl } from '../api';
import {
  DesktopPopup,
  HorizontalMenu,
  MobilePopup,
  UserMenu,
} from '../components';
import {
  burger,
  header,
  wrapper,
} from '../styles';

export const Header = () => {
  const isDemoMode = useSelector(getIsDemoMode);
  
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [failureModalOpen, setFailureModalOpen] = useState<boolean>(false);
  const [isDemoEnabled, setIsDemoEnabled] = useState<boolean>(isDemoMode || false);
  const [isDemoLoading, setIsDemoLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();
  
  const handleCloseChangePassword = () => {
    setChangePasswordModalOpen(false);
  };
  
  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const openPopupHandler = () => {
    setIsPopupOpen((prev) => !prev);
  };
  
  const handleOpenSuccessModal = () => {
    setSuccessModalOpen(true);
  };
  
  const handleOpenFailureModal = () => {
    setFailureModalOpen(true);
  };

  const handleOpenOnboardingModal = () => {
    navigate(`${location.pathname}?mt=onboarding`);

    localStorage.setItem('connection_type', 'onboarding');
    localStorage.setItem('prev_location', location.pathname);
  };
  
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };
  
  const handleCloseFailureModal = () => {
    setFailureModalOpen(false);
  };

  const handleCloseOnboardingModal = () => {
    navigate(location.pathname);
    
    localStorage.removeItem('connection_type');
    localStorage.removeItem('prev_location');
  };

  const inviteFriends = async () => {
    const inviteFriendsUrlResponse = await getInviteFriendsUrl(window.location.href);
    if (!inviteFriendsUrlResponse.data?.url) {
      return;
    }

    window.open(inviteFriendsUrlResponse.data.url, '_self');
  };

  const switchHandler = async (value: boolean) => {
    setIsDemoEnabled(value);
    setIsDemoLoading(true);
    
    if (!value) {
      const response = await disableDemoAccount();
      setIsDemoLoading(false);
      if (!response?.data?.skyrexUserUuid) {
        toast.error('Failed to disable demo mode');
        return;
      }

      removeDemoMode();
      dispatch(userActions.setIsDemoMode(false));
      return;
    }

    const response = await enableDemoAccount();
    setIsDemoLoading(false);
    if (!response?.data?.skyrexUserUuid) {
      toast.error('Failed to enable demo mode');
      return;
    }

    setDemoMode('demo');
    dispatch(userActions.setIsDemoMode(true));
  };

  const getDemoModeHandler = async () => {
    setIsDemoEnabled(isDemoMode);
  };

  useEffect(() => {
    getDemoModeHandler();
  }, [isDemoMode]);
  
  const isOpen = query.get('mt') === 'onboarding';
  const isSuccess = query.get('status');

  return (
    <Layout.Header style={header}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={wrapper}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1.5}
        >
          <Hidden lgUp>
            <div
              onClick={openPopupHandler}
              style={burger}
            >
              {Burger}
            </div>
          </Hidden>

          <Box
            display='flex'
            alignItems='center'
          >
            {Logo}
          </Box>
        </Box>

        <HorizontalMenu inviteFriends={inviteFriends} />

        <DesktopPopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
          inviteFriends={inviteFriends}
        />

        <MobilePopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
          inviteFriends={inviteFriends}
        />

        <Hidden smDown>
          <Button
            type='primary'
            ghost={true}
            onClick={handleOpenOnboardingModal}
          >
            Product tour
          </Button>
        </Hidden>

        <Box
          display='flex'
          alignItems='center'
          gap={{
            xs: '8px',
            md: '24px',
          }}
        >
          <Switch
            value={isDemoEnabled}
            checkedChildren='Demo'
            unCheckedChildren='Demo'
            onChange={switchHandler}
            disabled={isDemoLoading}
          />

          <UserMenu handleOpenChangePasswordModal={handleOpenChangePasswordModal} />
        </Box>
      </Box>

      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        handleClose={handleCloseChangePassword}
        handleSuccess={handleOpenSuccessModal}
        handleFailure={handleOpenFailureModal}
      />
      
      <SuccessModal
        buttonTitle='Okay'
        buttonStyles={{
          color: '#2ECD99',
          border: '0.5px solid #2ECD99',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        
        isOpen={successModalOpen}
        action={handleCloseSuccessModal}
        title='Your password is updated'
        description=''
      />
      
      <FailureModal
        buttonTitle='Okay'
        buttonStyles={{
          color: 'red',
          border: '0.5px solid red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={failureModalOpen}
        action={handleCloseFailureModal}
        title='Failed to update password'
        description=''
      />
      
      <Onboarding
        isOpen={!!isOpen}
        closeHandler={handleCloseOnboardingModal}
        isSuccess={!!isSuccess}
      />
    </Layout.Header>
  );
};
