import { fetchCryptoComExchangeInfo, fetchGateIOExchangeInfo } from 'pages/trading-bots/configurator/api';
import { fetchBinanceExchangeInfo, fetchBitmartExchangeInfo, fetchBybitExchangeInfo, fetchOkxExchangeInfo } from '../api/get-exchange-symbols';

export async function getExchangeSymbols(exchangeName: string) {  
  switch (exchangeName.toLowerCase()) {
  case 'binance':
    return await fetchBinanceExchangeInfo();
  case 'bybit':
    return await fetchBybitExchangeInfo();
  case 'okx':
    return await fetchOkxExchangeInfo();
  case 'gateio':
    return await fetchGateIOExchangeInfo();
  case 'crypto-com':
    return await fetchCryptoComExchangeInfo();
  case 'demo':
    return await fetchBinanceExchangeInfo();
  case 'bitmart':
    return await fetchBitmartExchangeInfo();
  default:
    throw new Error(`Exchange ${exchangeName} is not supported.`);
  }
}
