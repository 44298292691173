import { ConfigProvider, Table } from 'antd';
import React from 'react';
import { tradesTableStyles } from '../../ExchangeForm/exchange-form.styles.ts';
import './ActiveTradesHeader.css';
export const ActiveTradesHeader = () => {
    
  const valueStyle = {
    fontSize: '15px',
    fontWeight: 400,
    color: '#3A3A3C',
  };
      
  const columns = [
    {
      width: 95,
      title: 'Pair',
      dataIndex: 'pair',
      key: 'pair',
      render: (value) => {
        return <span style={valueStyle}>{value}</span>;
      },
    },
    {
      width: 95,
      title: 'Start date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (value) => {
        return <span style={valueStyle}>{value}</span>;
      },
    },
    {
      width: 90,
      title: 'Volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (value) => {
        return (
          <span className='header-table' style={valueStyle}>
            {value}
          </span>
        );
      },
    },
    {
      width: 300,
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      render: (value) => {
        return <span style={{
          marginLeft: '20px', ...valueStyle, 
        }}>{value}</span>;
      },
    },
    {
      width: 15,
      title: 'Return',
      dataIndex: 'return',
      key: 'return',
      render: (value) => {
        return <span style={{
          marginLeft: '10px', ...valueStyle, 
        }}>{value}</span>;
      },
    },
    {
      width: 15,
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (value) => {
        return <span style={valueStyle}>{value}</span>;
      },
    },
  ];
    
    
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: tradesTableStyles,
        },
      }}
    >
      <div className='activeTradesTable'>
        <Table
          style={{
            marginLeft: '80px',
          }}
          columns={columns}
          dataSource={[{}]}
          pagination={false}
        />      
      </div>
    </ConfigProvider>

  );
};

