import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IData {
  botUuid: string;
  closedDateFrom?: string;
  closedDateTo?: string;
}
  
export const getBotStatistic = async (botUuid: string, period?: [dayjs.Dayjs | null, dayjs.Dayjs | null]): Promise<any> => {
  const formattedDateCloseFrom = period && period[0] ? dayjs(period[0]).format('YYYY-MM-DD HH:mm:ss') : null;
  const formattedDateCloseTo = period && period[1] ? dayjs(period[1]).format('YYYY-MM-DD HH:mm:ss') : null;

  const data: IData = {
    botUuid: botUuid, 
  };

  if (formattedDateCloseFrom && formattedDateCloseTo) {
    data.closedDateFrom = formattedDateCloseFrom;
    data.closedDateTo = formattedDateCloseTo;
  }

  const response = await axios.request({
    url: '/bot/calculateStatistic',
    data: data,
  });
  
  return response.data.data;
};
  
