import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import {
  button,
  inner,
  modal,
  modalChildren,
  text,
  textWrapper,
  title,
  wrapper,
} from '../styles';

interface EmptySubscriptionProps {
  isOpen: boolean;
  handleClose: () => void;
  modalTitle: string;
  modalDescription: string;
  modalButtonTitle: string;
  modalButtonAction?: () => void;
  isError?: boolean;
}

export const EmptySubscription = (props: EmptySubscriptionProps) => {
  const {
    isOpen,
    handleClose,
    modalDescription,
    modalTitle,
    modalButtonTitle,
    modalButtonAction,
    isError,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <img src={isError ? '/images/subscriptions/robot-reject.png' : '/images/subscriptions/robot-success.png'} alt='robot' />

        <Box sx={inner}>
          <Box sx={textWrapper}>
            <Title styles={title}>
              {modalTitle}
            </Title>

            <Text styles={text}>
              {modalDescription}
            </Text>
          </Box>

          <MainButton
            type='primary'
            styles={button}
            onClick={modalButtonAction}
          >
            {modalButtonTitle}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
