import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatByPrecisionAndTrim } from '../helpers/helpers';

export const useTradingForm = (chartLastPrice: number, currentSymbol: any) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedSide: 'buy',
      skipBaseOrder: false,
      currentSymbol: currentSymbol,
      baseAsset: currentSymbol?.baseAsset,
      quoteAsset: currentSymbol?.quoteAsset,
      orderPrice: chartLastPrice.toString(),
      orderType: 'limit',
      conditionalOrderType: 'limit',
      units: '',
      total: '',
      slider: 10,
      boughtPrice: '',
      triggerPrice: '',
      triggerPriceType: 'last',
      additionalOrderPrice: formatByPrecisionAndTrim((chartLastPrice * 0.95).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)),
      additionalOrderPricePercent: '',
      additionalTriggerPricePercent: '',
      additionalSlider: 10,
      additionalUnits: '',
      additionalTotal: '',
      additionalOrderType: 'limit',
      additionalTriggerPriceType: 'last',
      additionalEntries: [],
      takeProfitEntries: [],
      stopLossPriceRecalculation: 'average',
      takeProfitPriceRecalculation: 'average',
      takeProfitOrderPrice: formatByPrecisionAndTrim((chartLastPrice * 1.1).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)),
      takeProfitTriggerPrice: formatByPrecisionAndTrim((chartLastPrice * 1.1).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)),
      takeProfitOrderType: 'limit',
      takeProfitOrderPricePercent: '',  
      takeProfitTriggerPricePercent: '',
      takeProfitTriggerPriceType: 'last',
      takeProfitSlider: 100,
      stopLossTriggerPrice: formatByPrecisionAndTrim((chartLastPrice * 0.9).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)),
      stopLossOrderPrice: formatByPrecisionAndTrim((chartLastPrice * 0.9).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)),
      stopLossOrderPricePercent: '',
      stopLossTriggerPricePercent: '',
      stopLossOrderType: 'cond.limit',
      stopLossTriggerPriceType: 'last',
      conditionalSegment: '',
      additionalEntryDuplicatePriceError: '',
      takeProfitDuplicatePriceError: '',
      additionalTriggerPrice: '',
      addEntryEnabled: false,
      takeProfitEnabled: false,
      stopLossEnabled: false,
    },
  });

  const {
    watch, 
  } = methods;
  const selectedSide = watch('selectedSide');

  useEffect(() => {
    // Для additional order
    const additionalMultiplier = selectedSide === 'buy' ? 0.95 : 1.05;
    methods.setValue('additionalOrderPrice', formatByPrecisionAndTrim((chartLastPrice * additionalMultiplier).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));

    // Для take profit - противоположная логика относительно выбранной стороны
    const takeProfitMultiplier = selectedSide === 'buy' ? 1.1 : 0.9;
    methods.setValue('takeProfitOrderPrice', formatByPrecisionAndTrim((chartLastPrice * takeProfitMultiplier).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
    methods.setValue('takeProfitTriggerPrice', formatByPrecisionAndTrim((chartLastPrice * takeProfitMultiplier).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));

    // Для stop loss - противоположная логика относительно take profit
    const stopLossMultiplier = selectedSide === 'buy' ? 0.9 : 1.1;
    methods.setValue('stopLossOrderPrice', formatByPrecisionAndTrim((chartLastPrice * stopLossMultiplier).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
    methods.setValue('stopLossTriggerPrice', formatByPrecisionAndTrim((chartLastPrice * stopLossMultiplier).toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
  }, [
    selectedSide, 
    chartLastPrice, 
    currentSymbol.quoteAssetPrecision, 
    currentSymbol.priceMin, 
    currentSymbol.priceMax,
    methods,
  ]);
  
  return methods;
};


// const currentSymbol = {
//   symbol: 'SOLUSDT',
//   baseAsset: 'SOL',
//   quoteAsset: 'USDT',
    
//   quoteAssetPrecision: 2, // usdt - конвертированный до разряда priceTickSize
//   priceTickSize: '0.01000000', // округление до разряда (quoteAssetPrecision)
//   priceMin: '0.01000000', // минимальная цена (минимальное значение orderPrice)
//   priceMax: '1000000.00000000', // максимальное значение цены
//   minNotional: '5.00000000', // минимальный размер total для любого ордера
//   maxNotional: '9000000.00000000', // максимальный размер total для любого ордера
//   lotMax: '90000.00000000', // максимальный размер units для limit order 
//   lotMin: '0.00100000', // минимальный размер units для limit order 
//   baseAssetPrecision: 3, // SOL Units - конвертированный до разряда lotTickSize
//   lotTickSize: '0.00100000', // округление до разряда ( baseAssetPrecision)
//   marketLotMax: '60781.99265416', // максимальный размер units для market order
//   marketLotMin: '0.00000000', // минимальный размер units для market order
//   chartPrecision: 2, // цена на графике (usdt)
//   baseCurrencyChartPrecision: 3, // то же, что и baseAssetPrecision
//   limitLastPrice: 60061.99,
//   chartLastPrice: 60146,
//   baseOrderPrice: 60146,
// };
