import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import './GenericSwitcher.css';
import { ADDITIONAL_ENTRY_TOGGLER, SIDE_OPTION_SWITCHER, STOP_LOSS_TOGGLER, TAKE_PROFIT_TOGGLER } from '../../exchange-form.constants';

export const GenericSwitcher = ({
  options, containerStyle, controlName, 
}) => {
  const {
    control, setValue, watch, 
  } = useFormContext();
  const selectedOption = watch(controlName, options[0].value);

  const handleOptionClick = (optionValue) => {
    setValue(controlName, optionValue);
    if (controlName === SIDE_OPTION_SWITCHER) {
      setValue(ADDITIONAL_ENTRY_TOGGLER, false);
      setValue(TAKE_PROFIT_TOGGLER, false);
      setValue(STOP_LOSS_TOGGLER, false);
    }
  };

  return (
    <Controller
      name={controlName}
      control={control}
      defaultValue={options[0].value}
      render={({
        field, 
      }) => (
        <div className='generic-switcher' style={containerStyle}>
          {options.map((option) => (
            <div
              key={option.value}
              className={`option ${
                selectedOption === option.value ? 'active' : ''
              }`}
              style={option.style}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    />
  );
};

