// @ts-nocheck

import { Input, InputNumber, Typography } from 'antd';
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getChartLastPrice } from '../../../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { getCurrentSymbol } from '../../../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getUserWalletBaseAsset } from '../../../../../../../entities/terminal/model/selectors/get-user-wallet-base-asset/get-user-wallet-base-asset.ts';
import { getWalletData } from '../../../../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data.ts';
import { RangeContainer } from '../../../RangeContainer/RangeContainer';
import COLORS from '../../../colors';
import { roundToTickSize } from '../../../exchange-form-utils.js';
import {
  BUY_SIDE,
  COINS_EXIST_SLIDER_DEFAULT_VALUE,
  LABEL_BOUGHT_PRICE,
  LABEL_SOLD_PRICE,
  LABEL_TOTAL,
  LABEL_UNITS,
  SELL_SIDE,
  SIDE_OPTION_SWITCHER,
} from '../../../exchange-form.constants';
import {
  coinsExistInputStyle,
} from '../../../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

interface ExistingCoinsInputsProps {
  labelBought: string;
  labelSold: string;
  labelTotal: string;
  labelUnits: string;
  baseOrderValue: number;
}

const FORM_NAME_PREFIX = 'coinsExist';
const FORM_NAME_BOUGHT_PRICE = `${FORM_NAME_PREFIX}.boughtPrice`;
const FORM_NAME_SOLD_PRICE = `${FORM_NAME_PREFIX}.soldPrice`;
const FORM_NAME_TOTAL = `${FORM_NAME_PREFIX}.total`;
const FORM_NAME_UNITS = `${FORM_NAME_PREFIX}.units`;
const FORM_NAME_VOLUME = `${FORM_NAME_PREFIX}.volume`;

export const CoinsExistInputs: React.FC<ExistingCoinsInputsProps> = ({
  //move to state
  baseOrderValue,
  formDisabled,
}) => {
  const {
    control, watch, setValue, getValues, 
  } = useFormContext();
  const [newSliderValue, setNewSliderValue] = useState(0);
  const currentSymbol = useSelector(getCurrentSymbol);
  const walletData = useSelector(getUserWalletSelectedSymbol);
  const userWalletBaseAsset = useSelector(
    getUserWalletBaseAsset,
  );

  const handleFormat = (value) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: currentSymbol.baseAssetPrecision,
      useGrouping: false,
    }).format(+value);
    return formattedValue;
  };

  const handleFormatUnits = (value) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: currentSymbol.baseAssetPrecision,
      useGrouping: false,
    }).format(value);
    return formattedValue;
  };
  const side = watch(SIDE_OPTION_SWITCHER, BUY_SIDE);
  
  const isSellSide = side === SELL_SIDE;
  
  
  const lastPrice = useSelector(getChartLastPrice);

  const freeCoin = userWalletBaseAsset?.free ?? 0;

  const [defaultPriceValue, setDefaultPriceValue] = useState(baseOrderValue);

  useEffect(() => {
    setDefaultPriceValue(lastPrice);
    setValue(FORM_NAME_BOUGHT_PRICE, 
      roundToTickSize(lastPrice, currentSymbol?.priceTickSize), 
      {
        shouldValidate: true,
      });
    setValue(
      FORM_NAME_TOTAL,
      roundToTickSize(baseOrderValue * freeCoin, currentSymbol?.priceTickSize),
      {
        shouldValidate: true, 
      },
    );
    setValue(FORM_NAME_UNITS, getDefaultUnits(freeCoin, currentSymbol?.priceTickSize), {
      shouldValidate: true,
    });
  }, [lastPrice]);




  // const roundToTickSize = (value, tickSize) => {
  //   const tickSizeNumber = Number(tickSize);
  //   return Math.round(value / tickSizeNumber) * tickSizeNumber;
  // };
  
  // const roundToTickSize = (value, tickSize) => {
  //   const tickSizeNumber = Number(tickSize);
  //   return Math.round(value / tickSizeNumber) * tickSizeNumber;
  // };

  const getDefaultUnits = (value, tickSize) => {
    const tickSizeNumber = Number(tickSize);
    return Math.min(value / tickSizeNumber) * tickSizeNumber;
  };

  const handleChangeBoughtPrice = (value) => {
    const roundedValue = roundToTickSize(value, currentSymbol?.priceTickSize);
    setValue(FORM_NAME_BOUGHT_PRICE, roundedValue);
    const unitsValue = getValues(FORM_NAME_UNITS);
    setValue(
      FORM_NAME_TOTAL,
      roundToTickSize(roundedValue * unitsValue, currentSymbol?.priceTickSize),
    );
  };

  const handleChangeUnits = (value) => {
    const roundedValue = roundToTickSize(value, currentSymbol?.priceTickSize);

    setValue(FORM_NAME_UNITS, roundedValue, {
      shouldValidate: true, 
    });

    const boughtPriceValue = getValues(FORM_NAME_BOUGHT_PRICE);
    setValue(FORM_NAME_TOTAL, boughtPriceValue * roundedValue, {
      shouldValidate: true,
    });

    const newSliderValueFromUnits = Math.round((roundedValue / freeCoin) * 100);
    setNewSliderValue(newSliderValueFromUnits);
    setValue(FORM_NAME_VOLUME, String(newSliderValueFromUnits), {
      shouldValidate: true,
    });
  };

  const debouncedHandleSliderChange = debounce((sliderValue) => {
    setValue(FORM_NAME_UNITS, Math.min((sliderValue / 100) * freeCoin), {
      shouldValidate: true,
    });

    setValue(
      FORM_NAME_TOTAL,

      roundToTickSize(
        getValues(FORM_NAME_BOUGHT_PRICE) *
          Math.min((sliderValue / 100) * freeCoin),
        currentSymbol?.priceTickSize,
      ),
    );
  }, 300);

  return (
    <>
      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {isSellSide ? LABEL_SOLD_PRICE : LABEL_BOUGHT_PRICE}
      </Text>
      <div>
        <Controller
          name={isSellSide ? FORM_NAME_SOLD_PRICE : FORM_NAME_BOUGHT_PRICE}
          control={control}
          defaultValue={defaultPriceValue}
          rules={{
            required: 'Price field is required',
            validate: {
              notZero: (value) => value !== 0 || 'Value cannot be zero',
              // minValue: (value) => value >= Number(currentSymbol.priceTickSize) || `Order size is less than minimal`
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={coinsExistInputStyle}
                step={currentSymbol?.priceTickSize}
                formatter={handleFormat}
                min={0}
                disabled={formDisabled}
                controls={false}
                size='small'
                suffix={currentSymbol.quoteAsset}
                onChange={handleChangeBoughtPrice}
              />
              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
          )}
        />
      </div>

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {isSellSide ? LABEL_UNITS : LABEL_TOTAL}
      </Text>
      <div>
        <Controller
          name={isSellSide ? FORM_NAME_UNITS : FORM_NAME_TOTAL}
          control={control}
          defaultValue={baseOrderValue * freeCoin}
          rules={{
            required: 'Price field is required',
            validate: {
              notZero: (value) => value !== 0 || 'Value cannot be zero',
              // minValue: (value) => value >= Number(tickSize) || `Order size is less than minimal`
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            
            <>
              <InputNumber
                {...field}
                style={coinsExistInputStyle}
                formatter={handleFormat}
                controls={false}
                min={0}
                size='small'
                suffix={currentSymbol.quoteAsset}
                disabled={formDisabled}
              />
            
              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
            
         
          )}
        />
      </div>

      <Text style={{
        fontSize: 12, color: COLORS.primary, 
      }} type='secondary'>
        {isSellSide ? LABEL_TOTAL : LABEL_UNITS}
      </Text>
      <div>
        <Controller
          name={isSellSide ? FORM_NAME_TOTAL : FORM_NAME_UNITS}
          control={control}
          defaultValue={freeCoin}
          rules={{
            required: 'Units field is required',
            validate: {
              notZero: (value) => value !== 0 || 'Value cannot be zero',
              // minValue: (value) =>
              //   value >= Number(tickSize) || `Order size is less than minimal`,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <>
              <InputNumber
                {...field}
                style={coinsExistInputStyle}
                min={0}
                controls={false}
                max={freeCoin}
                size='small'
                disabled={formDisabled}
                suffix={currentSymbol.baseAsset}
                formatter={handleFormatUnits}
                onChange={handleChangeUnits}
              />

              <Text style={{
                color: 'red', fontSize: 10, 
              }}>{error?.message}</Text>
            </>
          )}
        />
      </div>

      <RangeContainer
        fieldDisabled={formDisabled}
        name={FORM_NAME_VOLUME}
        defaultValue={COINS_EXIST_SLIDER_DEFAULT_VALUE}
        onSliderChange={debouncedHandleSliderChange}
        valueNew={newSliderValue}
        // coinsExistInput={FORM_NAME_VOLUME}
      />
    </>
  );
};
