export const supportedResolutions = [
  '1',
  '3',
  '5',
  '15',
  '30',
  '60',
  '120',
  '240',
  '360',
  '720',
  '1D',
  '1W',
  '1M',
];
  
export const resolutionMap = {
  1: '1m',
  3: '3m',
  5: '5m',
  15: '15m',
  30: '30m',
  60: '1h',
  120: '2h',
  240: '4h',
  360: '6h',
  720: '12h',
  '1D': '1d',
  '3D': '3d',
  '1W': '1w',
  '1M': '1M',
};

export const okxResolutionMap = {
  '1': 'candle1m',
  '3': 'candle3m',
  '5': 'candle5m',
  '15': 'candle15m',
  '30': 'candle30m',
  '60': 'candle1H',
  '120': 'candle2H',
  '240': 'candle4H',
  '360': 'candle6H',
  '720': 'candle12H',
  '1D': 'candle1D',
  '3D': 'candle3D',
  '1W': 'candle1W',
  '1M': 'candle1M',
};
    
export const bybitResolutionMap = {
  '1D': 'D',
  '1W': 'W',
  '1M': 'M',
};
