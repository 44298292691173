const COLORS = {
  primary: '#757577',
  primaryHover: '#757577',
  primaryBorderHover: '#757577',
  primaryBgHover: '#757577',
  activeColor: '#757577',
  borderColor: '#757577',
  activeBorderColor: '#757577',
  trackHoverBg: '#757577',
  trackBg: '#757577',
};
  
export default COLORS;
  
