import { Slider, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  rangeContainerInputBoxStyles,
  rangeContainerSliderStyles,
} from '../exchange-form.styles.ts';


export const RangeContainer = ({
  name, defaultValue, onSliderChange, valueNew, maxValue, fieldDisabled,
}) => {
  const [max, setMax] = useState(100);

  const {
    control, getValues, 
  } = useFormContext();
  const [sliderValue, setSliderValue] = useState(defaultValue);

  const isTakeProfit = name === 'takeProfit.volume';
  
  // Immediate visual update and debounced form state update
  const handleSliderChange = (value) => {
    setSliderValue(value);
    onSliderChange(value);  // Call the handler passed from the parent
  };

  useEffect(() => {
    if (maxValue) {
      setMax(maxValue);
      onSliderChange(maxValue);
      setSliderValue(maxValue);
    }
  }, [maxValue]);
  
  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);
  
  useEffect(() => {
    if (valueNew !== 0) {
      setSliderValue(valueNew);
    }
  }, [valueNew]);
  
  return (
    <div style={{
      marginTop: '5px', paddingLeft: '5px', 
    }}>
      {isTakeProfit ?  <label htmlFor='total'>Volume</label> :  ''}

      <div className='range-container'>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({
            field, 
          }) => (
            <Slider
              {...field}
              style={rangeContainerSliderStyles}
              // min={0}
              max={max}
              disabled={fieldDisabled}
              onChange={handleSliderChange}
              value={sliderValue}
            />
          )}
        />

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({
            field, 
          }) => (
            <InputNumber
              {...field}
              style={rangeContainerInputBoxStyles}
              controls={false}
              prefix='%'
              size='small'
              disabled={fieldDisabled}
              max={max}
              onChange={handleSliderChange}
              value={sliderValue}
            />
          )}
        />
      </div>
    </div>
  );
};
