import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import './EntrySection.css';
import { Typography } from 'antd';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify';
import { getBaseOrderPrice } from '../../../../../entities/terminal/model/selectors/get-base-order-price/get-base-order-price.ts';
import { getChartLastPrice } from '../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { classNames } from '../../../shared/lib/classNames/classNames';
import { SectionBasicInputs } from '../BaseOrderSection/BaseOrderInputs/BasicInputs/SectionBasicInputs.tsx';
import { SellSectionInputs } from '../BaseOrderSection/BaseOrderInputs/BasicInputs/SellSectionInputs.tsx';
import { SectionsOrderPriceGroup } from '../OrderPriceGroup/SectionsOrderPriceGroup.tsx';
import { OrdersList } from '../OrdersList/OrdersList';
import { RangeContainer } from '../RangeContainer/RangeContainer';
import { TakeProfitRange } from '../RangeContainer/TakeProfitRange.jsx';
import { SectionsTriggerPriceGroup } from '../TriggerPriceGroup/SectionsTriggerPriceGroup.jsx';
import { TriggerPriceGroup } from '../TriggerPriceGroup/TriggerPriceGroup.jsx';
import COLORS from '../colors.js';
import {
  ADDITIONAL_ENTRY_SECTION_TYPE,
  ADD_ENTRY_BUTTON_TITLE,
  ADD_TARGET_BUTTON_TITLE,
  BUY_SIDE,
  CONDLIMIT_VALUE,
  CONDMARKET_VALUE,
  FIELD_TOTAL,
  FIELD_UNITS,
  FIRST_ENTRY_SWITCHER,
  LABEL_ORDER_PRICE,
  LIMIT_VALUE,
  ORDER_PRICE_LABEL,
  SIDE_OPTION_SWITCHER,
  STOP_LOSS_SECTION_TYPE,
  STOP_LOSS_SWITCHER,
  TAKE_PROFIT_SECTION_TYPE,
  TAKE_PROFIT_SWITCHER,
  TRIGGER_PRICE_LABEL,
} from '../exchange-form.constants.js';

const {
  Text, 
} = Typography;

export const EntrySection = ({
  onAddOrder,
  onDeleteOrder,
  orders = [],
  sectionType,
  handleRemoveAllOrders,
}) => {
  const SECTION_NAME_PREFIX = sectionType;
  const SECTION_NAME_TOTAL = `${SECTION_NAME_PREFIX}.total`;
  const SECTION_NAME_UNITS = `${SECTION_NAME_PREFIX}.units`;
  const SECTION_NAME_ORDER_PRICE = `${SECTION_NAME_PREFIX}.orderPrice`;
  const SECTION_NAME_ORDER_PRICE_PERCENT = `${SECTION_NAME_PREFIX}.orderPricePercent`;
  const SECTION_NAME_TRIGGER_PRICE = `${SECTION_NAME_PREFIX}.triggerPrice`;
  const SECTION_NAME_VOLUME = `${SECTION_NAME_PREFIX}.volume`;
  const SECTION_NAME_SELECT = `${SECTION_NAME_PREFIX}.select`;

  const [ordersData, setOrdersData] = useState(orders);
  
  const lastPrice = useSelector(getChartLastPrice);

  const {
    getValues, watch, setValue, errors, 
  } = useFormContext();

  const side = watch(SIDE_OPTION_SWITCHER);

  const isBuySide = side === BUY_SIDE;

  const isSellSide = side === BUY_SIDE;

  const firstEntrySwitcherValue = watch(FIRST_ENTRY_SWITCHER);
  const takeProfitSwitcherValue = watch(TAKE_PROFIT_SWITCHER);
  const stopLossSwitcherValue = watch(STOP_LOSS_SWITCHER);

  const [sliderValue, setSliderValue] = useState(100);

  const [calculatedChartLastPrice, setCalculatedChartLastPrice] = useState(
    Number(getValues('baseOrder.orderPrice')),
  );

  // const [newSliderValue, setNewSliderValue] = useState(80)

  const baseOrderPrice = useSelector(getBaseOrderPrice);
  // const baseOrderPrice = useSelector((state) => state.global.baseOrderPrice);

  const [targetsSplit, setTargetsSplit] = useState(false);

  const handleSplitTargetsClick = () => {
    setTargetsSplit(true);
  };

  useEffect(() => {
    
    
    
    setValue('takeProfitArray', []);
  }, [targetsSplit]);
  
  
  // Logic to handle the 'X' button click
  const handleCancelSplit = () => {
    setTargetsSplit(false);
    handleRemoveAllOrders();
    // console.log(getValues('takeProfitArray'));
    
    // setValue('takeProfitArray', []);
  };

  const handleSliderChange = useCallback(
    debounce((newSliderValue) => {
      setSliderValue(newSliderValue);
      // Update related form fields here, e.g.,:
      setValue(SECTION_NAME_VOLUME, newSliderValue, {
        shouldValidate: true, 
      });
    }, 400),
    [],
  ); // Empty dependency array to ensure the debounced function is not recreated

  const sumOfTotal = orders.reduce((sum, order) => sum + order.total, 0);

  const calculateAllTargetsAreSet = () => {
    return sumOfTotal === 100;
  };

  const allTargetsAreSet = calculateAllTargetsAreSet();

  const isTakeProfitSection = sectionType === TAKE_PROFIT_SECTION_TYPE;
  const isAdditionalEntrySection =
    sectionType === ADDITIONAL_ENTRY_SECTION_TYPE;
  const isStopLossSection = sectionType === STOP_LOSS_SECTION_TYPE;

  const isCondMarket = firstEntrySwitcherValue === CONDMARKET_VALUE;
  const isCondLimit = firstEntrySwitcherValue === CONDLIMIT_VALUE;

  const takeProfitLimit = takeProfitSwitcherValue === LIMIT_VALUE;

  const isStopLossCondLimit = stopLossSwitcherValue === CONDLIMIT_VALUE;

  const takeProfitSwitcherState = getValues(TAKE_PROFIT_SWITCHER);
  
  const calculatedDefaultPrice = useMemo(() => {
    // Convert baseOrderPrice to a number to ensure proper calculations
    const numericBaseOrderPrice = Number(baseOrderPrice);

    // Determine the multiplier based on isSellSide boolean
    const multiplier = !isSellSide ? 0.9 : 1.1;

    // Return the adjusted price based on the side
    return numericBaseOrderPrice * multiplier;
  }, [isSellSide, baseOrderPrice, side, takeProfitSwitcherValue, takeProfitSwitcherState]);

  const calculatedDefaultPriceStopLoss = useMemo(() => {
    const numericBaseOrderPrice = Number(baseOrderPrice);
    const multiplier = !isSellSide ? 1.1 : 0.9;
    return numericBaseOrderPrice * multiplier;
  }, [isSellSide, baseOrderPrice, side]);

  const handleAddClick = (e) => {
    e.preventDefault();
    
    const pricevalue = getValues(SECTION_NAME_ORDER_PRICE);
    const triggerPriceValue = getValues(SECTION_NAME_TRIGGER_PRICE);
    const priceArray = getValues('takeProfitArray');
    
    const isLimitPriceAlreadyExist =
      pricevalue &&
      isTakeProfitSection &&
      priceArray?.find((el) => el.price === pricevalue);
    const isTriggerPriceAlreadyExist =
    triggerPriceValue && isTakeProfitSection && priceArray?.find((el) => el.price === triggerPriceValue);
      
    if (takeProfitSwitcherValue === LIMIT_VALUE && isLimitPriceAlreadyExist) {
      return toast.error('Choose another price value');
    }
    
    if (takeProfitSwitcherValue === CONDMARKET_VALUE && isTriggerPriceAlreadyExist) {
      return toast.error('Choose another price value');
    }

    const orderData = isTakeProfitSection
      ? {
        price: !takeProfitLimit
          ? getValues(SECTION_NAME_TRIGGER_PRICE)
          : getValues(SECTION_NAME_ORDER_PRICE),
        //тотал = значение слайдера необходимое для отправки в терминал как поле percent
        total: getValues(SECTION_NAME_VOLUME),
        //перцент = значение справа от ордер прайс
        percent: getValues(SECTION_NAME_ORDER_PRICE_PERCENT),
      }
      : {
        addEntryOrderPrice: getValues(SECTION_NAME_ORDER_PRICE),
        addEntryUnits: getValues(SECTION_NAME_UNITS),
        addEntryTotal: getValues(SECTION_NAME_TOTAL),
        addEntryTriggerPrice: getValues(SECTION_NAME_TRIGGER_PRICE),
        addEntryTypeTriggerPrice: getValues(SECTION_NAME_SELECT),
      };
    const isAnyValueZero = Object.values(orderData).some(
      (value) => value === 0,
    );

    if (isAnyValueZero) {
      toast.error('Order data values cannot be zero');
      return; // Stop execution if any value is zero
    }

    if (isTakeProfitSection) {
      if (sumOfTotal + orderData.total <= 100) {
        onAddOrder(orderData);
      } else {
        toast.error('Adding the order exceeds the limit');
      }
    } else {
      onAddOrder(orderData);
    }
  };

  return (
    <>
      <OrdersList
        orderType={sectionType}
        orders={orders}
        // ordersData={ordersData}
        onDelete={onDeleteOrder}
      />

      <div className='aes-order-size-container'>
        {isAdditionalEntrySection &&
          (!isBuySide ? (
            <SellSectionInputs
              sliderName={SECTION_NAME_VOLUME}
              addEntryEnabled={isAdditionalEntrySection}
              isCondMarket={isCondMarket}
              isCondLimit={isCondLimit}
              totalFieldName={SECTION_NAME_TOTAL}
              unitsFieldName={SECTION_NAME_UNITS}
              orderPriceFieldName={SECTION_NAME_ORDER_PRICE}
              orderPricePercentFieldName={SECTION_NAME_ORDER_PRICE_PERCENT}
              chartLastPrice={Number(calculatedChartLastPrice)}
              triggerPriceInputControlName={SECTION_NAME_TRIGGER_PRICE}
              selectControlName={SECTION_NAME_SELECT}
            />
          ) : (
            <SectionBasicInputs
              sliderName={SECTION_NAME_VOLUME}
              addEntryEnabled={isAdditionalEntrySection}
              isCondMarket={isCondMarket}
              isCondLimit={isCondLimit}
              totalFieldName={SECTION_NAME_TOTAL}
              unitsFieldName={SECTION_NAME_UNITS}
              orderPriceFieldName={SECTION_NAME_ORDER_PRICE}
              orderPricePercentFieldName={SECTION_NAME_ORDER_PRICE_PERCENT}
              chartLastPrice={Number(calculatedChartLastPrice)}
              triggerPriceInputControlName={SECTION_NAME_TRIGGER_PRICE}
              selectControlName={SECTION_NAME_SELECT}
            />
          ))}

        <div className='aes-order-size-container'>
          {isTakeProfitSection && !allTargetsAreSet && (
            <>
              {takeProfitLimit ? (
                <div>
                  <Text
                    style={{
                      fontSize: 12, color: COLORS.primary, 
                    }}
                    type='secondary'
                  >
                    {ORDER_PRICE_LABEL}
                  </Text>
                  <SectionsOrderPriceGroup
                    orderPriceControlName={SECTION_NAME_ORDER_PRICE}
                    totalFieldName={`${TAKE_PROFIT_SECTION_TYPE}.${FIELD_TOTAL}`}
                    unitsFieldName={`${TAKE_PROFIT_SECTION_TYPE}.${FIELD_UNITS}`}
                    percentageControlName={SECTION_NAME_ORDER_PRICE_PERCENT}
                    chartLastPrice={Number(calculatedChartLastPrice)}
                    defaultPrice={calculatedDefaultPrice}
                    splittedTargets={targetsSplit}
                    takeProfit={true}
                  />
                </div>
              ) : (
                <div>
                  <Text
                    style={{
                      fontSize: 12, color: COLORS.primary, 
                    }}
                    type='secondary'
                  >
                    {TRIGGER_PRICE_LABEL}
                  </Text>
                  <SectionsOrderPriceGroup
                    orderPriceControlName={SECTION_NAME_TRIGGER_PRICE}
                    totalFieldName={`${TAKE_PROFIT_SECTION_TYPE}.${FIELD_TOTAL}`}
                    unitsFieldName={`${TAKE_PROFIT_SECTION_TYPE}.${FIELD_UNITS}`}
                    percentageControlName={SECTION_NAME_ORDER_PRICE_PERCENT}
                    chartLastPrice={Number(calculatedChartLastPrice)}
                    defaultPrice={calculatedDefaultPrice}
                    splittedTargets={targetsSplit}
                    takeProfit={true}
                  />
                </div>
              )}

              {targetsSplit && (
                <TakeProfitRange
                  name={SECTION_NAME_VOLUME}
                  defaultValue={sliderValue}
                  onSliderChange={handleSliderChange}
                  valueNew={sliderValue}
                  maxValue={100 - sumOfTotal}
                />
              )}
            </>
          )}
        </div>

        {isStopLossSection && (
          <div>
            <div>
              <SectionsTriggerPriceGroup
                priceInputControlName={SECTION_NAME_TRIGGER_PRICE}
                selectControlName={SECTION_NAME_SELECT}
                baseOrderValue={Number(baseOrderPrice)}
                defaultPrice={calculatedDefaultPriceStopLoss}
                stopLoss={true}
              />
            </div>

            {isStopLossCondLimit && (
              <div style={{
                marginTop: '5px',
              }}>
                <Text
                  style={{
                    fontSize: 12, color: COLORS.primary, 
                  }}
                  type='secondary'
                >
                  {LABEL_ORDER_PRICE}
                </Text>
                <SectionsOrderPriceGroup
                  orderPriceControlName={SECTION_NAME_ORDER_PRICE}
                  percentageControlName={SECTION_NAME_ORDER_PRICE_PERCENT}
                  chartLastPrice={Number(calculatedChartLastPrice)}
                  defaultPrice={calculatedDefaultPriceStopLoss}
                  totalFieldName={`${STOP_LOSS_SECTION_TYPE}.${FIELD_TOTAL}`}
                  unitsFieldName={`${STOP_LOSS_SECTION_TYPE}.${FIELD_UNITS}`}
                  isCondLimit={true}
                  stopLoss={true}
                />
              </div>
            )}
          </div>
        )}

        {!isStopLossSection && (
          <>
            {targetsSplit ? (
              <>
                {allTargetsAreSet ? (
                  <div style={{
                    display: 'flex', justifyContent: 'center', 
                  }}>
                    <Text
                      style={{
                        fontSize: 12, color: COLORS.primary, 
                      }}
                      type='secondary'
                    >
                      All targets are set
                    </Text>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between', 
                    }}
                  >
                    <button
                      style={{
                        marginRight: '10px', 
                      }}
                      type='button'
                      onClick={handleAddClick}
                      className={classNames('aes-button', {}, [
                        'button-class',
                      ])}
                    >
                      + Add Target
                    </button>
                    <button
                      style={{
                        width: '28%', 
                      }}
                      type='button'
                      onClick={handleCancelSplit}
                      className={classNames('aes-button', {}, [
                        'button-class',
                      ])}
                    >
                      X
                    </button>
                  </div>
                )}
              </>
            ) : (
              <button
                type='button'
                onClick={
                  isTakeProfitSection
                    ? handleSplitTargetsClick
                    : handleAddClick
                }
                className={classNames('aes-button', {}, ['button-class'])}
              >
                {isTakeProfitSection
                  ? ADD_TARGET_BUTTON_TITLE
                  : ADD_ENTRY_BUTTON_TITLE}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};
