// @ts-nocheck

import { Input, InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getBaseOrderPrice } from '../../../../../entities/terminal/model/selectors/get-base-order-price/get-base-order-price.ts';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME } from '../BaseOrderSection/BaseOrderInputs/BaseOrderInputs.jsx';
import { roundToTickSize, roundUnitsToTickSize } from '../exchange-form-utils.js';
import {
  ADDITIONAL_ENTRY_TOGGLER,
  BASE_ORDER_ORDER_PRICE_FIELD_NAME,
  BASE_ORDER_TRIGGER_PRICE_FIELD_NAME,
  MARKET_VALUE,
  STOP_LOSS_TOGGLER,
  TAKE_PROFIT_SECTION_TYPE,
  TAKE_PROFIT_TOGGLER,
} from '../exchange-form.constants.js';
import {
  orderPriceInputPercentStyles,
  orderPriceInputStyles,
  priceGroupSectionContainerStyles,
} from '../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

interface OrderPriceGroupProps {
  label: string;
  orderPriceControlName: string;
  percentageControlName: string;
  totalSum: number;
  defaultValue: number;
  defaultValuePercent: number;
  baseOrderPrice: number;
  chartLastPrice: number;
  defaultSliderValue: number;
  defaultPrice: number;
  unitsFieldName: string;
  totalFieldName: string;
  sellSide: boolean | undefined;
}

export const OrderPriceGroup: React.FC<OrderPriceGroupProps> = ({
  fieldDisabled,
  orderPriceControlName,
  percentageControlName,
  chartLastPrice,
  defaultPrice,
  defaultSliderValue,
  unitsFieldName,
  totalFieldName,
  sellSide,
}) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: {
      errors, 
    },
    clearErrors,
  } = useFormContext();

  const calculatePercentage = (price, basePrice) => {
    return Math.round((price / basePrice - 1) * 100);
  };

  const baseOrderPrice = useSelector(getBaseOrderPrice);

  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  );

  const isConditional = conditionalOrderSwitcherValue === MARKET_VALUE;

  const baseOrderPriceField = watch(BASE_ORDER_ORDER_PRICE_FIELD_NAME);
  const baseOrderTriggerPriceField = watch(BASE_ORDER_TRIGGER_PRICE_FIELD_NAME);

  const currentSymbol = useSelector(getCurrentSymbol);

  const addEntryEnabled = watch(ADDITIONAL_ENTRY_TOGGLER);
  const takeProfitEnabled = watch(TAKE_PROFIT_TOGGLER);
  const stopLossEnabled = watch(STOP_LOSS_TOGGLER);

  const handleValidateTakeProfit = (value) => {
    const pricePercent = getValues(percentageControlName);

    const baseOrderTotalValue = getValues('baseOrder.total');

    const sliderValues = getValues('takeProfit.volume');

    return (
      currentSymbol.minNotional <=
        (baseOrderTotalValue * (1 + pricePercent / 100)) /
          (sliderValues / 100) || 'Order size is less than minimal'
    );
  };

  const handleValidateStopLoss = (value) => {
    const pricePercent = getValues(percentageControlName);
    const baseOrderTotalValue = getValues('baseOrder.total');

    const targetOrderSize =
      baseOrderTotalValue * (1 + pricePercent / 100 + pricePercent / 100);

    return +currentSymbol.minNotional < targetOrderSize || 'Order size is less than minimal';
  };

  const isTakeProfit = orderPriceControlName === 'takeProfit.orderPrice';
  const isStopLoss = orderPriceControlName === 'stopLoss.orderPrice';

  const handleFormatQuoteAsset = (value: number | string): string => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  useEffect(() => {
    const defaultRoundedOrderPrice = roundToTickSize(defaultPrice, currentSymbol.priceTickSize);

    setValue(orderPriceControlName, defaultRoundedOrderPrice, {
      shouldValidate: true,
    });

    const defaultPricePercent = Math.round(
      (defaultRoundedOrderPrice / baseOrderPrice - 1) * 100,
    );

    setValue(percentageControlName, defaultPricePercent, {
      shouldValidate: true,
    });
    clearErrors(totalFieldName);
  }, [baseOrderPrice, chartLastPrice]);

  const isAddEntry = orderPriceControlName === 'addEntry.orderPrice';

  useEffect(() => {
    // для изменения процентов в addEntry при изменении цены в base Order
    if (isAddEntry) {

      const addEntryOrderPriceField = getValues('addEntry.orderPrice');

      const pricePercent = Math.round(
        (addEntryOrderPriceField / baseOrderPriceField - 1) * 100,
      );

      setValue(percentageControlName, pricePercent);
    }
  }, [baseOrderPriceField]);

  useEffect(() => {
    if (addEntryEnabled && isConditional) {
      const addEntryOrderPriceField = getValues('addEntry.orderPrice');

      const pricePercent = Math.round(
        (addEntryOrderPriceField / baseOrderTriggerPriceField - 1) * 100,
      );

      setValue(percentageControlName, pricePercent);
    }
  }, [baseOrderTriggerPriceField]);

  const handleOrderPriceChange = (newOrderPrice) => {
    if (!newOrderPrice) {
      if (sellSide) {
        setValue(orderPriceControlName, 0, {
          shouldValidate: true, 
        });
        setValue(
          totalFieldName,
          0,
        );
        return;
      } else {
        setValue(orderPriceControlName, 0, {
          shouldValidate: true, 
        });
        setValue(
          unitsFieldName,
          0,
          {
            shouldValidate: true, 
          },
        );
        return;
      }
    }
    if (sellSide) {
      const orderPriceRounded = roundToTickSize(newOrderPrice, currentSymbol.priceTickSize);
      const unitsValues = getValues(unitsFieldName);
      const newPercentage = calculatePercentage(newOrderPrice, chartLastPrice);

      setValue(
        orderPriceControlName,
        roundToTickSize(newOrderPrice, currentSymbol.priceTickSize),
        {
          shouldValidate: true,
        },
      );
      setValue(
        totalFieldName,
        roundToTickSize(orderPriceRounded * unitsValues, currentSymbol.priceTickSize),
      );
      setValue(percentageControlName, newPercentage);
    } else {
      const totalValues = getValues(totalFieldName);
      const newPercentage = calculatePercentage(newOrderPrice, chartLastPrice);

      setValue(
        orderPriceControlName,
        roundToTickSize(newOrderPrice, currentSymbol.priceTickSize),
        {
          shouldValidate: true,
        },
      );

      setValue(
        unitsFieldName,
        roundUnitsToTickSize(totalValues / newOrderPrice, currentSymbol.lotTickSize),
        {
          shouldValidate: true, 
        },
      );

      setValue(percentageControlName, newPercentage);
    }
  };

  const handlePercentageChange = (newValue) => {
    clearErrors(orderPriceControlName);
    
    if (!newValue) {
      if (sellSide) {
        setValue(percentageControlName, 0,   {
          shouldValidate: true, 
        });
        setValue(orderPriceControlName, 0,   {
          shouldValidate: true, 
        });
        setValue(
          totalFieldName,
          0
          ,{
            shouldValidate: true, 
          });
        return;
      } else {
        setValue(percentageControlName, 0,   {
          shouldValidate: true, 
        });
        setValue(orderPriceControlName, 0,   {
          shouldValidate: true, 
        });
        setValue(
          unitsFieldName,
          0,
          {
            shouldValidate: true, 
          },
        );
        
        return;
      }
    }
    
    
    if (sellSide) {
      const newPercentage = Math.round(parseFloat(newValue));
      const newOrderPriceRaw = chartLastPrice * (1 + newPercentage / 100);
      const newOrderPrice = roundToTickSize(newOrderPriceRaw, currentSymbol.priceTickSize);
      const unitsValues = getValues(unitsFieldName);

      setValue(percentageControlName, newPercentage);
      setValue(orderPriceControlName, newOrderPrice);
      setValue(
        totalFieldName,
        roundToTickSize(newOrderPrice * unitsValues, currentSymbol.priceTickSize),
      );
    } else {
      const newPercentage = Math.round(parseFloat(newValue));
      const totalValues = getValues(totalFieldName);
      const newOrderPriceRaw = chartLastPrice * (1 + newPercentage / 100);
      const newOrderPrice = roundToTickSize(newOrderPriceRaw, currentSymbol.priceTickSize);

      setValue(percentageControlName, newPercentage);
      setValue(orderPriceControlName, newOrderPrice);
      setValue(
        unitsFieldName,
        roundUnitsToTickSize(totalValues / newOrderPrice, currentSymbol.lotTickSize),
        {
          shouldValidate: true, 
        },
      );
    }
  };

  const percentageFormatter = (value) => {
    if (value > 99) {
      return '>99%';
    } else {
      return `${value}%`;
    }
  };

  // Parser function to remove '%' from the displayed value for processing
  const percentageParser = (value) => value.replace('%', '');

  return (
    <>
      <div style={priceGroupSectionContainerStyles}>
        <div>
          <Controller
            name={orderPriceControlName}
            control={control}
            defaultValue={roundToTickSize(defaultPrice, currentSymbol.priceTickSize)}
            rules={{
              required: 'field is required',
              validate: {
                notZero: (value) => value !== 0 || 'Price can\'t be 0',
                // minTakeProfitValue: handleValidateTakeProfit,
                ...(isTakeProfit && {
                  minTakeProfitValue: handleValidateTakeProfit,
                }),
                ...(isStopLoss && {
                  validateStopLoss: handleValidateStopLoss,
                }),
              },
            }}
            render={({
              field, fieldState: {
                error, 
              }, 
            }) => (
              <InputNumber
                {...field}
                controls={false}
                disabled={fieldDisabled}
                style={orderPriceInputStyles}
                size='small'
                suffix={currentSymbol.quoteAsset}
                formatter={handleFormatQuoteAsset}
                onChange={handleOrderPriceChange}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name={percentageControlName}
            control={control}
            // defaultValue={defaultPercentage}
            render={({
              field, 
            }) => (
              <InputNumber
                {...field}
                style={orderPriceInputPercentStyles}
                controls={false}
                disabled={fieldDisabled}
                formatter={percentageFormatter}
                parser={percentageParser}
                // formatter={(value) => `${value}%`}
                // parser={(value) => value.replace("%", "")}
                size='small'
                onChange={handlePercentageChange}
              />
            )}
          />
        </div>
      </div>

      {/* {errors && errors.stopLoss?.orderPrice && (
        <Text style={{color: "red", fontSize: 11}}>
          {errors.stopLoss?.orderPrice?.message}
        </Text>
      )} */}
      
      {errors && errors.addEntry?.orderPrice && (
        <Text style={{
          color: 'red', fontSize: 11, 
        }}>
          {errors.addEntry?.orderPrice?.message}
        </Text>
      )}
      
    </>
  );
};
