export const roundToTickSize = (value, tickSize) => {
  // Convert tickSize to a number, in case it's not already.
  const tickSizeNumber = Number(tickSize);
  
  // Calculate the number of decimal places based on tickSize.
  // This converts tickSize to its string representation and counts the characters after the decimal point.
  const precision = tickSize?.toString().includes('.') ? tickSize?.toString().split('.')[1].length : 0;
  
  // Calculate the rounded value based on tick size.
  let roundedValue = Math.round(value / tickSizeNumber) * tickSizeNumber;
  
  // Format the rounded value to the calculated number of decimal places and convert back to number.
  // This ensures trailing zeros are removed, and we have the correct number of decimal places.
  roundedValue = +roundedValue.toFixed(precision);
  
  return roundedValue;
};
  
export const roundUnitsToTickSize = (value, tickSize) => {
  const tickSizeNumber = Number(tickSize);
  const dssd = (value / tickSizeNumber) * tickSizeNumber;
    
  if (+value < tickSize) {
    return value;
  } else {
    return Math.round(value / tickSizeNumber) * tickSizeNumber;
  }
};
  
  
export const handleFormatBaseAsset = (value, basePresicion) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: basePresicion,
    useGrouping: false,
  }).format(value);
  return formattedValue;
};

export const handleFormatQuoteAsset = (value, quotePresicion) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: quotePresicion,
    useGrouping: false,
  }).format(value);
  return formattedValue;
};
  
  
export const roundAndFormatQuoteAsset = (value, tickSize, quotePrecision) => {
  // First, round the value to the nearest tick size.
  const tickSizeNumber = Number(tickSize);
  const precision = tickSize?.toString().includes('.') ? tickSize?.toString().split('.')[1].length : 0;
  let roundedValue = Math.round(value / tickSizeNumber) * tickSizeNumber;
  roundedValue = +roundedValue.toFixed(precision);

  // Then, format the rounded value with the quote asset's precision.
  const formattedValue = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: quotePrecision,
    useGrouping: false,
  }).format(roundedValue);

  return formattedValue;
};



