import React from 'react';

export const switchStyles = {
  colorPrimary: 'rgba(46, 205, 153, 1)',
  colorPrimaryActive: 'rgba(46, 205, 153, 1)',
  colorPrimaryHover: 'rgba(46, 205, 153, 1)',
};

export const sliderStyles = {
  handleColorDisabled: '#757577',
  trackBgDisabled: '#757577',
  colorPrimaryBorderHover: '#757577',
  colorPrimaryBgHover: '#757577',
  handleColor: '#757577',
  colorPrimary: '#757577',
  handleActiveColor: '#757577',
  handleLineWidth: 3,
  handleLineWidthHover: 3,
  handleSize: 8,
  handleSizeHover: 11,
  dotBorderColor: '#757577',
  dotActiveBorderColor: '#757577',
  trackHoverBg: '#757577',
  trackBg: '#757577',
};
  
export const tableStyles = {
  borderColor: 'white',
  rowHoverBg: 'white',
  headerBg: 'white',
  headerSplitColor: 'white',
  cellFontSize: 11,
  cellPaddingBlock: 0,
  colorLink: '#757577',
  controlItemBgActive: '#757577',
  colorPrimary: '#757577',
  colorLinkHover: '#757577',
};
  
  
export const tradesTableStyles = {
  borderColor: 'white',
  rowHoverBg: 'white',
  headerBg: 'white',
  headerSplitColor: 'white',
  cellFontSize: 11,
  cellPaddingBlock: 0,
  colorLink: '#757577',
  controlItemBgActive: '#3A3A3C',
  colorPrimary: '#3A3A3C',
  colorLinkHover: '#757577',
};
  
export const inputFontSize = {
  fontSize: 11,
    
};
  
export const marginTopSmall = {
  marginTop: '10px',
};
  
export const marginTopMedium = {
  marginTop: '20px',
};
  
export const tpSlContainerStyle = {
  width: '103px',
  height: '24px',
};
  
export const basicSectionsLayout = {
  display: 'flex',
  flexDirection: 'column',
};
  
export const coinsExistInputStyle = {
  border: '1px solid rgba(58, 58, 60, 0.2)',
  height: '29px',
  width: '232px',
};
  
export const rangeContainerSliderStyles = {
  marginLeft: '5px', marginRight: '5px', width: '154px', 
};
  
export const rangeContainerInputBoxStyles = {
  border: '1px solid rgba(58, 58, 60, 0.2)',
  width: '51px',
  height: '29px',
  paddingTop: '3px',
  fontSize: '11px',
  display: 'flex',
  flexDirection: 'row-reverse',
};
  
export const orderPriceInputPercentStyles: React.CSSProperties = {
  border: '1px solid rgba(58, 58, 60, 0.2)',
  width: '51px',
  height: '29px',
  fontSize: '11px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};
  
export const orderPriceInputStyles: React.CSSProperties = {
  border: '1px solid rgba(58, 58, 60, 0.2)',
  width: '170px',
  height: '29px',
  marginTop: '5px',
};
  
export const priceGroupSectionContainerStyles: React.CSSProperties = {
  width: '232px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
};
  
export const triggerPriceGroupContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
  
export const triggerPriceInputStyles = {
  fontSize: 12,
  width: '165px',
  height: '29px',
};
  
export const triggerPriceSelectStyles = {
  width: 65,
  height: 29,
};
  
export const sectionContainerStyles = {
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '232px',
};
  
export const basicInputStyle = {
  border: '1px solid rgba(58, 58, 60, 0.2)',
  height: '29px',
  width: '232px',
};
  
export const dropDownOptionStyle = {
  color: 'black',
  cursor: 'pointer',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0em',
  textAlign: 'left',
};
  
export const optionLabelStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
  
export const tradesTitleSideSellStyle = {
  color: '#F32222',
};
  
export const tradesTitleSideBuyStyle = {
  color: '#2ECD99',
};
  
export const tradesTitleStyle = {
  height: '14px',
  width: '155px',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'left',
  fontFamily: 'Poppins',
  whiteSpace: 'nowrap',
};
  
  
export const buttonsWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'space-between',
  fontFamily: 'Poppins',
  marginTop: '30px',
};

export const successModalTextStyle = {
  fontFamily: 'Poppins',
  fontSize: 20,
  fontWeight: 400,
  marginBottom: '16px',
};
  
export const windowSizeModalTextStyle = {
  fontFamily: 'Poppins',
  fontSize: 20,
  fontWeight: 400,
  marginBottom: '36px',
  // textAlign: "center",
};

export const successModalButtonContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  marginBottom: '20px',
};
  
export const successModalButtonStyle = {
  width: '100px',
  height: '42px',
  borderRadius: '5px',
  border: '1px solid #E9E9E9',
  fontSize: '15px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: '#2ECD99',
  backgroundColor: '#ffffff',
};
  
export const connectAccountButtonStyle = {
  width: '300px',
  height: '42px',
  borderRadius: '5px',
  border: '1px solid #E9E9E9',
  fontSize: '15px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: '#2ECD99',
  backgroundColor: '#ffffff',
};
  
export const failureModalButtonStyle = {
  width: '100px',
  height: '42px',
  borderRadius: '5px',
  border: '1px solid red',
  fontSize: '15px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: '#2ECD99',
  backgroundColor: '#ffffff',
};

  
export const successModalBodyContainer = {
              
  textAlign: 'center', 
  paddingTop: '20px',
  marginBottom: '10px',
  marginTop: '20px',
};
  
export const verifyButtonStyles = {
  marginLeft: '10px',
  width: '410px',
  height: '50px',
  borderRadius: '5px',
  border: '1px solid #747474',
  fontSize: '15px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: '#747474',
  backgroundColor: '#ffffff',
};
