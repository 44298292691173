import { Table, Button, Typography, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import {
  ADDITIONAL_ENTRY_SECTION_TYPE,
  LABEL_TOTAL,
  LABEL_VOLUME,
  SAFETY_ORDERS_LABEL,
  TAKE_PROFIT_ORDERS_LABEL,
  TAKE_PROFIT_SECTION_TYPE,
} from '../exchange-form.constants';
import { marginTopMedium, marginTopSmall } from '../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

export const OrdersList = ({
  orders, onDelete, orderType, 
}) => {
  const {
    setValue, 
  } = useFormContext();
  const [dataSource, setDataSource] = useState(orders);

  const currentSymbol = useSelector(getCurrentSymbol);
  // const currentSymbol = useSelector((state) => state.global.currentSymbol);

  const currency = currentSymbol?.quoteAsset;

  useEffect(() => {
    const updatedDataSource = orders.map((order, index) => {
      const price = parseFloat(
        orderType === TAKE_PROFIT_SECTION_TYPE
          ? order.price
          : order.addEntryOrderPrice,
      );

      
      
      const total = parseFloat(
        orderType === TAKE_PROFIT_SECTION_TYPE
        // в случае тейк профита тотал = процент слайдера === значение для отправки percent в терминал
          ? order.total
          : order.addEntryTotal,
      );
      
      
      // в случае тейк профита percent = значение для отрисовки в модалке
      const percent =
        orderType === TAKE_PROFIT_SECTION_TYPE
          ? order.percent
          : null;

      
      const units = parseFloat(
        orderType === TAKE_PROFIT_SECTION_TYPE
          ? null
          : order.addEntryUnits,
      );
    
      const triggerPrice = parseFloat(
        orderType === TAKE_PROFIT_SECTION_TYPE
          ? null
          : order.addEntryTriggerPrice,
      );
    
      const triggerPriceType = orderType === TAKE_PROFIT_SECTION_TYPE
        ? null
        : order.addEntryTypeTriggerPrice;

      return {
        key: order.id ? order.id.toString() : `key-${index}`,
        price: price,
        //используется только в ТП
        // percentForModal: percent, 
        percent: percent,
        //для тейк профита тотал === volume = процент слайдера, для остальных - количество
        total: total, 
        units: units,
        triggerPrice: triggerPrice,
        triggerPriceType: triggerPriceType,
        displayPrice: `${price} ${currency}`, // formatted for display
        displayTotal:
          orderType === TAKE_PROFIT_SECTION_TYPE
            ? `${total} %`
            : `${total} ${currency}`, // formatted for display
      };
    });

    setDataSource(updatedDataSource);
    setValue(`${orderType}Array`, updatedDataSource);
  }, [orders, orderType, currency]);

  const columns = [
    {
      title: 'Price',
      dataIndex: 'displayPrice', // use displayPrice for column data
      key: 'price',
    },
    {
      title:
        orderType === ADDITIONAL_ENTRY_SECTION_TYPE
          ? LABEL_TOTAL
          : LABEL_VOLUME,
      dataIndex: 'displayTotal', // use displayTotal for column data
      key: 'total',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button type='link' onClick={() => onDelete(record.key)}>
          ×
        </Button>
      ),
    },
  ];

  return (
    <>
      {dataSource.length >
        0 && (
        <>
          <Divider style={marginTopMedium} />
          <div className='wrapper' style={marginTopSmall}>
            <Text
              strong
              style={{
                fontSize: 12, color: 'black', 
              }}
              type='secondary'
            >
              {orderType === ADDITIONAL_ENTRY_SECTION_TYPE
                ? SAFETY_ORDERS_LABEL
                : TAKE_PROFIT_ORDERS_LABEL}
            </Text>

            <Table
              style={{
                marginTop: '5px', 
              }}
              dataSource={dataSource}
              columns={columns}
              rowKey={(record) => record.key}
              pagination={false}
            />
          </div>
          <Divider />
        </>
      )}
    </>
  );
};
