import { Input, ConfigProvider, Select, Typography, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import './MultiInput.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import COLORS from '../colors';
import { roundToTickSize } from '../exchange-form-utils.js';
import { SELECT_OPTIONS, TRIGGER_PRICE_LABEL } from '../exchange-form.constants';
import {
  priceGroupSectionContainerStyles,
  triggerPriceGroupContainer,
  triggerPriceInputStyles,
  triggerPriceSelectStyles,
} from '../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

export const TriggerPriceGroup = ({
  priceInputControlName,
  selectControlName,
  baseOrderValue,
  defaultPrice,
}) => {

  const currentSymbol = useSelector(getCurrentSymbol);
  
  const {
    control, setValue, 
  } = useFormContext();
  const [triggerPercent, setTriggerPercent] = useState(0);
  const [inputValue, setInputValue] = useState(baseOrderValue.toString()); // Maintain input value as string

  const handleChange = (value) => {
    setValue(selectControlName, value);
  };

  const handleTriggerPriceChange = (newValue) => {
    // const newValue = event.target.value;
    setInputValue(newValue); // Update the local state

    // Calculate trigger percent only for valid numbers
    const numericValue = parseFloat(newValue);
    if (!isNaN(numericValue)) {
      const newTriggerPercent = (numericValue / baseOrderValue - 1) * 100;
      setTriggerPercent(Math.round(newTriggerPercent));
    }
  };
  
  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };
  
  // const handleFormatQuoteAsset = (value) => {

  //   const formattedValue = new Intl.NumberFormat('en-US', {
  //     maximumSignificantDigits: currentSymbol.quoteAssetPrecision,
  //     useGrouping: false,
  //   }).format(value);

  //   return formattedValue;
  // };
  
  useEffect(() => {
    const defaultNumericValue = parseFloat(defaultPrice);
    if (!isNaN(defaultNumericValue) && baseOrderValue > 0) {
      const newTriggerPercent = ((defaultNumericValue / baseOrderValue) - 1) * 100;
      setTriggerPercent(Math.round(newTriggerPercent));
    } else {
      setTriggerPercent(0); // Reset trigger percent if defaultPrice is not a number or baseOrderValue is not valid
    }
    setInputValue(defaultPrice.toString()); // Update when defaultPrice changes
  }, [defaultPrice, baseOrderValue]);
  
  useEffect(() => {
    setValue(priceInputControlName, parseFloat(inputValue));
  }, [inputValue, setValue, priceInputControlName]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 29,
            fontSize: 10,
            width: 20,
          },
          Select: {
            fontSize: 10,
          },
        },
      }}
    >
      <>
        <div style={triggerPriceGroupContainer}>
          <div style={priceGroupSectionContainerStyles}>
            <div>
              <Text
                style={{
                  fontSize: 12, color: COLORS.primary, 
                }}
                type='secondary'
              >
                {TRIGGER_PRICE_LABEL}
              </Text>
              <Controller
                name={priceInputControlName}
                control={control}
                defaultValue={defaultPrice}
                render={({
                  field, 
                }) => (
                  <InputNumber
                    {...field}
                    addonBefore={triggerPercent > 99 ? '>99%' : `${triggerPercent}%`}
                    size='middle'
                    suffix={currentSymbol.quoteAsset}
                    style={triggerPriceInputStyles}
                    value={inputValue}
                    controls={false}
                    // onBlur={(e) => {
                    //   const newValue = parseFloat(e.target.value) || 0;
                    //   const maxValue = baseOrderValue;
                    //   if (newValue > maxValue) {
                    //     setValue(priceInputControlName, maxValue);
                    //   }
                    // }}
                    min={0}
                    max={baseOrderValue}
                    formatter={handleFormatQuoteAsset}
                    onChange={handleTriggerPriceChange}
                  />
                )}
              />
            </div>

            <Controller
              name={selectControlName}
              control={control}
              defaultValue={SELECT_OPTIONS[0]}
              render={({
                field, 
              }) => (
                <Select
                  {...field}
                  style={triggerPriceSelectStyles}
                  onChange={handleChange}
                  options={SELECT_OPTIONS}
                />
              )}
            />
          </div>
        </div>
      </>
    </ConfigProvider>
  );
};
