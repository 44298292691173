// @ts-nocheck

import React, {useEffect, useMemo, useRef, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Input, InputNumber, Typography} from "antd";
import COLORS from "../../../colors";
import {RangeContainer} from "../../../RangeContainer/RangeContainer";
import "../../../OrderPriceGroup/OrderPriceGroup.css";
import {basicInputStyle} from "../../../exchange-form.styles.ts";
import {
  BASIC_INPUTS_DEFAULT_SLIDER_VALUE,
  CONDITIONAL_VALUE,
  LABEL_ORDER_PRICE,
  LABEL_TOTAL,
  LABEL_UNITS,
  MARKET_VALUE,
  STOP_LOSS_TOGGLER,
  TAKE_PROFIT_TOGGLER,
} from '../../../exchange-form.constants.js';
import {useDispatch, useSelector} from 'react-redux';
import debounce from "lodash.debounce";
// import {set_base_order_price} from "../../../../../_dispatch/user.js";
import {
  roundToTickSize,
  roundUnitsToTickSize,
} from "../../../exchange-form-utils.js";
import {
  BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  BASE_ORDER_SWITCHER_FIELD_NAME,
} from "../BaseOrderInputs.jsx";
import { getCurrentSymbol } from '../../../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol';
import { getWalletData } from '../../../../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data';
import { getLimitLastPrice } from '../../../../../../../entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { terminalActions } from '../../../../../../../entities/terminal/model/slices/terminal-slice'
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';
import { getChosenExchange } from "entities/terminal/model/selectors/get-chosen-exchange/get-chosen-exchange";

const {Text} = Typography;

interface BasicInputsProps {
  labelOrder: string;
  labelTotal: string;
  labelUnits: string;
  baseOrderValue: number;
  freeCoin: number;
  disabled: boolean;
  sliderName: string;
  totalFieldName: string;
  unitsFieldName: string;
  orderPriceFieldName: string;
  chartLastPrice: number;
  addEntryEnabled: boolean | undefined;
  isCondMarket: boolean | undefined;
  isCondLimit: boolean | undefined;
  triggerPriceInputControlName: string;
  selectControlName: string;
  orderPricePercentFieldName: string;
  isMarketOrder: boolean | undefined;
}

export const BasicInputs: React.FC<BasicInputsProps> = ({
  chartLastPrice,
  disabled,
  sliderName,
  totalFieldName,
  unitsFieldName,
  orderPriceFieldName,
  addEntryEnabled,
  isMarketOrder,
  formDisabled,
}) => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const {control, setValue, getValues, clearErrors, watch} = useFormContext();
  const [newSliderValue, setNewSliderValue] = useState(0);

  const currentSymbol = useSelector(getCurrentSymbol);
  const walletData = useSelector(getUserWalletSelectedSymbol);
  const limitLastPrice = useSelector(getLimitLastPrice);

  const baseOrderSwitcherValue = watch(BASE_ORDER_SWITCHER_FIELD_NAME);
  const isBaseOrderMarketOrder = baseOrderSwitcherValue === MARKET_VALUE;

  const takeProfitEnabled = watch(TAKE_PROFIT_TOGGLER);
  const stopLossEnabled = watch(STOP_LOSS_TOGGLER);

  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME
  );

  const isOrderPriceInvisible = conditionalOrderSwitcherValue === MARKET_VALUE;

  const freeCoin = walletData?.free ?? 0;

  const validateZeroValue = (value) => {
    return value !== 0 || "Value cannot be zero";
  };

  const validateMinUnitsValue = (value) => {
    return (
      value >= currentSymbol.lotMin ||
      `Units should be greater than ${currentSymbol.lotMin ?? `${value}`}`
    );
  };

  const validateMaxUnitsValue = (value) => {
    if (!currentSymbol.marketLotMax) {
      return true;
    } else {
      return (
        value <= currentSymbol.marketLotMax ||
        `Units should be less than ${currentSymbol.marketLotMax}`
      );
    }
  };

  const dispatch = useDispatch();

  const handleFormatBaseAsset = (value) => {
    if (!isFinite(value)) {
      return "0";
    }
    
    const precision = (typeof currentSymbol?.baseAssetPrecision === 'number' && currentSymbol.baseAssetPrecision > 0)
      ? currentSymbol?.baseAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  const getNewTotalValue = (units, orderPrice) => units * (orderPrice ?? 0);

  useEffect(() => {
    setValue(
      orderPriceFieldName,
      isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice
    );
    setValue(
      unitsFieldName,
      isMarketOrder
        ? (freeCoin * 0.1) /
            (isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice)
        : // ? (freeCoin * 0.1) / chartLastPrice
          roundUnitsToTickSize(
            (freeCoin * 0.1) /
              (isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice),
            // (freeCoin * 0.1) / chartLastPrice,
            currentSymbol?.lotTickSize
          ),

      {shouldValidate: true}
    );

    setValue(
      totalFieldName,
      roundToTickSize(freeCoin * 0.1, currentSymbol?.priceTickSize)
    );
    setNewSliderValue(BASIC_INPUTS_DEFAULT_SLIDER_VALUE);
    setValue(sliderName, BASIC_INPUTS_DEFAULT_SLIDER_VALUE);
    clearErrors(totalFieldName);
  }, [chartLastPrice, isMarketOrder]);

  
  //works only with market order
  useEffect(() => {
    if (isBaseOrderMarketOrder) {
      
      setValue(
        orderPriceFieldName,
        isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice
      );

      // setValue(
      //   unitsFieldName,
      //   isMarketOrder
      //     ? (freeCoin * 0.1) /
      //         (isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice)
      //     : // ? (freeCoin * 0.1) / chartLastPrice
      //       roundUnitsToTickSize(
      //         (freeCoin * 0.1) /
      //           (isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice),
      //         // (freeCoin * 0.1) / chartLastPrice,
      //         currentSymbol?.lotTickSize
      //       ),

      //   {shouldValidate: true}
      // );
      
      if (isInitialRender) {
        setValue(totalFieldName, roundToTickSize(freeCoin * 0.1, currentSymbol?.priceTickSize));
        setNewSliderValue(BASIC_INPUTS_DEFAULT_SLIDER_VALUE);
        setValue(sliderName, BASIC_INPUTS_DEFAULT_SLIDER_VALUE);
        
        setIsInitialRender(false); // Mark that the initial render is done
      }
      
      // clearErrors(totalFieldName);
    }
  }, [chartLastPrice, isMarketOrder, limitLastPrice]);

  
  
  const handleChangeOrderPrice = (newOrderPrice) => {
    // Не округляем значение сразу
    const formattedValue = handleFormatQuoteAsset(newOrderPrice);

    setValue(orderPriceFieldName, formattedValue, {shouldValidate: true});

    if (!addEntryEnabled && !takeProfitEnabled && !stopLossEnabled) {
      dispatch(terminalActions.setBaseOrderPrice(formattedValue));
    }
    
    const totalValue = getValues(totalFieldName);
    const unitsRaw = totalValue / +formattedValue;
    const units = unitsRaw.toFixed(currentSymbol.baseAssetPrecision);

    const roundedUnits = roundUnitsToTickSize(
      units,
      currentSymbol?.lotTickSize
    );

    setValue(
      unitsFieldName,
      roundedUnits,
      {shouldValidate: true}
    );
  };

  const handleChangeUnits = (newUnits) => {
    const roundedUnits = roundUnitsToTickSize(
      newUnits,
      currentSymbol?.lotTickSize
    );
    debugger
    setValue(
      unitsFieldName,

      isMarketOrder ? newUnits : roundedUnits,

      {shouldValidate: true}
    );

    const orderPriceValues = getValues(orderPriceFieldName);

    setNewSliderValue(
      Math.round(
        getNewTotalValue(roundedUnits, orderPriceValues / freeCoin) * 100
      )
    );

    setValue(
      sliderName,
      Math.round(
        getNewTotalValue(roundedUnits, orderPriceValues / freeCoin) * 100
      ),
      {shouldValidate: true}
    );

    setValue(
      totalFieldName,
      roundToTickSize(
        roundedUnits * orderPriceValues,
        currentSymbol?.priceTickSize
      ),
      {shouldValidate: true}
    );
  };

  const handleChangeTotal = (newTotal) => {
    const roundedTotal = roundToTickSize(
      newTotal,
      currentSymbol?.priceTickSize
    );

    const orderPriceValues = getValues(orderPriceFieldName);

    setValue(totalFieldName, roundedTotal, {shouldValidate: true});

    setValue(
      unitsFieldName,

      isMarketOrder
        ? roundedTotal / orderPriceValues
        : roundUnitsToTickSize(
            roundedTotal / orderPriceValues,
            currentSymbol?.lotTickSize
          ),
      // roundUnitsToTickSize(roundedTotal / orderPriceValues, unitsTickSize),

      {shouldValidate: true}
    );
    setNewSliderValue(Math.round((roundedTotal / freeCoin) * 100));
    setValue(sliderName, Math.round((roundedTotal / freeCoin) * 100));
  };

  const debouncedHandleSliderChange = debounce((sliderValue) => {
    const newTotal = Math.min((sliderValue / 100) * freeCoin);
    const orderPriceValues = getValues(orderPriceFieldName);
    const roundedTotal = roundToTickSize(
      newTotal,
      currentSymbol?.priceTickSize
    );

    debugger
    setValue(totalFieldName, roundedTotal, {shouldValidate: true});

    setValue(
      unitsFieldName,

      isMarketOrder
        ? newTotal / orderPriceValues
        : roundUnitsToTickSize(
            newTotal / orderPriceValues,
            currentSymbol?.lotTickSize
          ),

      // roundToTickSize(newTotal / orderPriceValues, tickSize),
      {shouldValidate: true}
    );
  }, 300);

  const notZeroValidation = (value) => value !== 0 || "Value cannot be zero";

  const maxFreeCoinValidation = (value) => {
    return (
      value <= freeCoin ||
      `Value cannot exceed ${freeCoin} ${walletData?.symbol}`
    );
  };

  const maxTotalValidation = (value) => {
    return (
      value <= Number(currentSymbol?.maxNotional) ||
      `Order size should be less than ${currentSymbol?.maxNotional}`
    );
  };

  const minTotalValidation = (value) => {
    return (
      value >= Number(currentSymbol?.minNotional) ||
      `Order size should be greater than ${currentSymbol?.minNotional}`
    );
  };

  const unitsInput = useMemo(() => (
    <div>
      <Text style={{fontSize: 12, color: COLORS.primary}} type="secondary">
        {LABEL_UNITS}
      </Text>
      <div>
        <Controller
          name={unitsFieldName}
          control={control}
          defaultValue={handleFormatBaseAsset(
            (freeCoin * 0.1) / chartLastPrice
          )}
          rules={{
            required: "Units field is required",
            validate: {
              notZero: validateZeroValue,
              minValue: validateMinUnitsValue,
              maxValue: validateMaxUnitsValue,
            },
          }}
          render={({field, fieldState: {error}}) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                min={0}
                disabled={formDisabled}
                formatter={handleFormatBaseAsset}
                controls={false}
                size="small"
                suffix={currentSymbol.baseAsset}
                onChange={handleChangeUnits}
              />
              {error && (
                <Text style={{color: "red", fontSize: 10}}>
                  {error.message}
                </Text>
              )}
            </>
          )}
        />
      </div>
    </div>
  ), [currentSymbol.baseAsset, formDisabled]);
  // ), [unitsFieldName, control, freeCoin, chartLastPrice, formDisabled, currentSymbol.baseAsset, handleChangeUnits, handleFormatBaseAsset, validateZeroValue, validateMinUnitsValue, validateMaxUnitsValue]);

  return (
    <>
      <>
        {!isOrderPriceInvisible && (
          <div>
            <Text
              style={{fontSize: 12, color: COLORS.primary}}
              type="secondary"
            >
              {LABEL_ORDER_PRICE}
            </Text>
            <div>
              <Controller
                name={orderPriceFieldName}
                control={control}
                defaultValue={
                  isBaseOrderMarketOrder ? limitLastPrice : chartLastPrice
                }
                rules={{
                  required: "Order price is required",
                  validate: notZeroValidation,
                }}
                render={({field, fieldState: {error}}) => (
                  <>
                    <InputNumber
                      {...field}
                      style={{
                        ...basicInputStyle,
                        border: error ? "1px solid red" : "",
                      }}
                      disabled={disabled || formDisabled}
                      size="small"
                      controls={false}
                      formatter={handleFormatQuoteAsset}
                      parser={(value) => value.replace(/[^0-9.]/g, '')}
                      suffix={currentSymbol.quoteAsset}
                      onChange={handleChangeOrderPrice}
                    />
                    {error && (
                      <Text style={{color: "red", fontSize: 10}}>
                        {error.message}
                      </Text>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        )}
      </>

      {unitsInput}

      <Text style={{fontSize: 12, color: COLORS.primary}} type="secondary">
        {LABEL_TOTAL}
      </Text>
      <div>
        <Controller
          name={totalFieldName}
          control={control}
          defaultValue={freeCoin * 0.1}
          rules={{
            required: "Total field is required",
            validate: {
              notZero: notZeroValidation,
              maxFreeCoin: maxFreeCoinValidation,
              minValue: minTotalValidation,
              maxValue: maxTotalValidation,
            },
          }}
          render={({field, fieldState: {error}}) => (
            <>
              <InputNumber
                {...field}
                style={basicInputStyle}
                size="small"
                controls={false}
                disabled={formDisabled}
                suffix={currentSymbol.quoteAsset}
                formatter={handleFormatQuoteAsset}
                onChange={handleChangeTotal}
              />
              <Text style={{color: "red", fontSize: 10}}>{error?.message}</Text>
            </>
          )}
        />
      </div>

      <RangeContainer
      fieldDisabled={formDisabled}
        name={sliderName}
        defaultValue={BASIC_INPUTS_DEFAULT_SLIDER_VALUE}
        onSliderChange={debouncedHandleSliderChange}
        valueNew={newSliderValue}
      />
    </>
  );
};