import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import './AdditionalEntrySection.css';
import { useSelector } from 'react-redux';
import { getChartLastPrice } from '../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { Toggler } from '../../../shared/ui/Toggler/Toggler.tsx';

// import { GenericSwitcher } from '../../../widgets/GenericSwitcher/GenericSwitcher';

import { EntrySection } from '../EntrySection/EntrySection';
import {
  ADDITIONAL_ENTRY_SECTION_TYPE,
  ADDITIONAL_ENTRY_TOGGLER,
  ADDITIONAL_ENTRY_TOGGLER_TITLE,
  FIRST_ENTRY_SWITCHER,
  FIRST_ENTRY_SWITCHER_OPTIONS,
  LIMIT,
  LIMIT_VALUE,
} from '../exchange-form.constants.js';
import { marginTopSmall } from '../exchange-form.styles.ts';
import { GenericSwitcher } from '../widgets/GenericSwitcher/GenericSwitcher.jsx';

export const AdditionalEntrySection = ({
  formDisabled
}) => {
  const {
    setValue, watch, 
  } = useFormContext();

  const lastPrice = useSelector(getChartLastPrice);
  // const lastPrice = useSelector((state) => state.global.lastPrice);
  
  const isAdditionalEntryToggled = watch(ADDITIONAL_ENTRY_TOGGLER);

  const [safetyOrders, setSafetyOrders] = useState([]);

  const handleAddSafetyOrder = (newOrderData) => {
    const newOrder = {
      ...newOrderData,
      id: Date.now(),
    };

    setSafetyOrders([...safetyOrders, newOrder]);
  };

  const handleDeleteSafetyOrder = (orderId) => {
    setSafetyOrders(
      safetyOrders.filter((order) => order.id !== Number(orderId)),
    );
  };

  useEffect(() => {
    if (isAdditionalEntryToggled === false) {
      setValue(FIRST_ENTRY_SWITCHER, LIMIT_VALUE);
    }
  }, [isAdditionalEntryToggled]);
  
  useEffect(() => {
    setSafetyOrders([]);
    setValue(ADDITIONAL_ENTRY_TOGGLER, false);
  }, [lastPrice]);
  

  return (
    <div>
      <div>
        <Toggler
          disabled={formDisabled}
          togglerElementSectionName={ADDITIONAL_ENTRY_TOGGLER}
          title={ADDITIONAL_ENTRY_TOGGLER_TITLE}
        />
      </div>

      {isAdditionalEntryToggled && (
        <div>
          <div style={marginTopSmall}>
            <GenericSwitcher
              options={FIRST_ENTRY_SWITCHER_OPTIONS}
              controlName={FIRST_ENTRY_SWITCHER}
            />
          </div>

          <EntrySection
            onAddOrder={handleAddSafetyOrder}
            onDeleteOrder={handleDeleteSafetyOrder}
            orders={safetyOrders}
            sectionType={ADDITIONAL_ENTRY_SECTION_TYPE}
          />
        </div>
      )}
    </div>
  );
};
