

import { Switch } from 'antd';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './Toggler.css';

interface TogglerProps {
  togglerElementSectionName: string;
  title: string;
  titleStyle?: any;
  disabled: boolean;
}

export const Toggler: React.FC<TogglerProps> = ({
  togglerElementSectionName,
  title,
  titleStyle,
  disabled,
}) => {
  const {
    control, setValue, 
  } = useFormContext();
  
  const handleChange = (checked: boolean) => {
    setValue(togglerElementSectionName, checked);
  };

  return (
    <div className='toggler-container'>
      <Controller
        name={togglerElementSectionName}
        control={control}
        defaultValue={false}
        render={({
          field, 
        }) => (
          <Switch
            disabled={disabled}
            size='small'
            checked={field.value}
            onChange={(checked) => {
              field.onChange(checked);
              handleChange(checked);
            }}
          />
        )}
      />

      <span className='switch-text' style={titleStyle}>
        {title}
      </span>
    </div>
  );
};
