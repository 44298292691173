//@ts-nocheck
import { Box } from '@mui/material';
import { setShortExchangesData } from 'entities/exchange/helpers/set-short-exchanges-data';
import { getShortExchangesData } from 'entities/exchange/model/selectors/get-short-exchanges-data/get-short-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { getAllExchangeSymbols } from 'entities/terminal/model/selectors/get-all-exchange-symbols/get-all-exchange-symbols';
import { getChosenExchange } from 'entities/terminal/model/selectors/get-chosen-exchange/get-chosen-exchange';
import { getCurrentSymbol } from 'entities/terminal/model/selectors/get-current-symbol/get-current-symbol';
import { getDailyChange } from 'entities/terminal/model/selectors/get-daily-change/get-daily-change';
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode.ts';
import { userActions } from 'entities/user/model/slices/user-slice';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { calculateMarketOptions, getFilteredTradingPairs, getUniqueSymbolCodes } from 'pages/trading-terminal-page/terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart } from 'widgets';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { getDemoAccount } from 'shared/helpers/get-demo-account.ts';
import { getAuthToken, getSkyrexUuid, getUserEmail } from 'shared/helpers/storage-helper';
import { Loader, SingleSelect, Table, Text } from 'shared/ui';
//@ts-ignore
import { TVChartContainer } from '../../../../pages/trading-terminal-page/trading-chart/TVChartContainer/index.jsx';
import { ActiveTrades as ActiveTradesComponent } from '../components';
import { TradingPanel } from '../components/trading-panel/trading-panel.tsx';
import { columns } from '../consts/columns';
import { isValidSymbol } from '../helpers/helpers.ts';
import { filters, graphicWrapper, tableWrapper, wrapper } from '../styles';


export const TradingTerminal = () => {
  const dispatch = useDispatch();
  
  const allConnectedExchanges = useSelector(getShortExchangesData);
  const chosenExchange = useSelector(getChosenExchange);
  const allExchangeSymbols = useSelector(getAllExchangeSymbols);
  const dailyChange = useSelector(getDailyChange);
  const isDemoMode = useSelector(getIsDemoMode);

  const [exchangeAccountUuid, setExchangeAccountUuid] = useState(null);
  const [exchangeCode, setExchangeCode] = useState(null);
  const [defaultExchange, setDefaultExchange] = useState(null);
  const [defaultMarket, setDefaultMarket] = useState(null);
  const [defaultTraidingPair, setDefaultTraidingPair] = useState(null);
  const [isSectionLoading, setIsSectionLoading] = useState(false);

  const [transformedExchanges, setTransformedExchanges] = useState([]);

  const [previousMarket, setPreviousMarket] = useState(null);
  const [previousTradingPair, setPreviousTradingPair] = useState(null);
  

  const userWallet = chosenExchange?.balances;
  
  const uniqueSymbolCodes = useMemo(() => {
    const result = getUniqueSymbolCodes(allExchangeSymbols);
    return result;
  }, [allExchangeSymbols]);

  const marketOptions = useMemo(() => {
    return calculateMarketOptions(userWallet, uniqueSymbolCodes);
  }, [userWallet, uniqueSymbolCodes]);

  const filteredTradingPairs = useMemo(() => {
    return getFilteredTradingPairs(allExchangeSymbols, defaultMarket, userWallet);
  }, [uniqueSymbolCodes, defaultMarket, userWallet]);
  
  const handleSelectExchange = (exchangeAccountUuid) => {
    const selectedExchange = allConnectedExchanges.find((exchange) => exchange.exchangeAccountUuid === exchangeAccountUuid);
    setPreviousMarket(defaultMarket);
    setPreviousTradingPair(defaultTraidingPair);
    setExchangeAccountUuid(selectedExchange.exchangeAccountUuid);
    setExchangeCode(selectedExchange.exchangeCode);
  };

  const handleSelectMarket = (value, label, key) => {
    setDefaultMarket(label);
  };

  const handleSelectTradingPair = (value, label) => {
    const currentSymbol = allExchangeSymbols.find((element) => element.symbol === label?.formattedPair);
    dispatch(terminalActions.setCurrentSymbol(currentSymbol));
    setDefaultTraidingPair(label);
  };
  
  const getExchangesData = async () => {
    try {
      const authToken = getAuthToken();
      const skyrexUserUuid = getSkyrexUuid();
      const skyrexUserEmail = getUserEmail();

      if (authToken) {
        dispatch(userActions.auth(true));
        dispatch(userActions.setSkyrexUuid(skyrexUserUuid ?? ''));
        dispatch(userActions.setUserEmail(skyrexUserEmail ?? ''));
      }

      const exchangeAccounts = await setShortExchangesData(skyrexUserUuid ?? '');
      const filteredAccounts = getDemoAccount(exchangeAccounts, isDemoMode);

      dispatch(exchangeActions.setAllExchangesShortData(filteredAccounts));
      setExchangeCode(isDemoMode ? 'binance' : (filteredAccounts[0]?.exchangeCode ?? 'binance'));
      setExchangeAccountUuid(filteredAccounts[0]?.exchangeAccountUuid);
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchExchangeSymbols = async (exchangeCode) => {
    try {
      const exchangeSymbols = await getExchangeSymbols(exchangeCode);
      dispatch(terminalActions.setExchangeSymbols(exchangeSymbols));
    } catch (error) {
      console.log(error, '=====error');
      dispatch(terminalActions.setExchangeSymbols([]));
    }
  };

  const getSingleAccountFinanceData = async (exchangeAccountUuid) => {
    try {
      setIsSectionLoading(true);
      const responseWithFinancialData = await getExchanges([exchangeAccountUuid]);
      const chosenExchange = responseWithFinancialData?.data?.accounts[0];
      dispatch(terminalActions.setChosenExchange(chosenExchange));
      dispatch(terminalActions.setWalletData(chosenExchange?.balances));
      setDefaultExchange(chosenExchange);
    } catch (error) {
      console.log('===error in single account finance data');
    } finally {
      setIsSectionLoading(false);
    }
  };

  const getSnapshotsArray = async (chosenExchange, exchangeAccountUuid) => {
    try {
      if (!exchangeAccountUuid || !chosenExchange) {
        dispatch(terminalActions.setDailyChange(
          {
            usdtDailyChangePercent: '0',
            usdtDailyChangeValue: 0,
          },
        ));
        return;
      }

      const skyrexUserUuid = getSkyrexUuid();
      const getDailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid, exchangeAccountUuid);
      dispatch(terminalActions.setDailyChange(getDailyChange));
    } catch (error) {
      console.log('===error snapshots');
    }
  };
  
  //handle form disable props
  //tradingpair as prop or from state?
  //onFormSubmitSuccess ??
  
  useEffect(() => {
    if (exchangeAccountUuid) {
      getSingleAccountFinanceData(exchangeAccountUuid);
    }
  }, [allConnectedExchanges, exchangeAccountUuid]);

  useEffect(() => {
    if (exchangeCode) {
      fetchExchangeSymbols(exchangeCode);
    }
  }, [exchangeCode]);

  useEffect(() => {
    if (chosenExchange) {
      getSnapshotsArray(chosenExchange, exchangeAccountUuid);
    }
  }, [chosenExchange, exchangeAccountUuid]);

  useEffect(() => {
    getExchangesData();
  }, [isDemoMode]);
  
  useEffect(() => {
    const currentSymbol = allExchangeSymbols.find((element) => element.symbol === defaultTraidingPair?.formattedPair);
    dispatch(terminalActions.setCurrentSymbol(currentSymbol));
  }, [defaultTraidingPair]);
    
  useEffect(() => {
    if (chosenExchange) {
      const marketOptions = uniqueSymbolCodes.map((token, index) => {
        const walletBalance = userWallet?.find((el) => el.symbol === token.quoteAsset)?.free || '0';
        const formattedValue = currencyFormatter(walletBalance, token.chartPrecision);
        
        return {
          label: token.quoteAsset,
          value: formattedValue,
        };
      });
  
      const previousMarketOption = marketOptions.find(option => option.label === previousMarket?.label);
      setDefaultMarket(previousMarketOption || marketOptions.find((el) => el.label === 'USDT') || marketOptions[0]);
      setDefaultExchange(chosenExchange);
    }
  }, [chosenExchange, userWallet, uniqueSymbolCodes, previousMarket]);
  
  useEffect(() => {
    if (filteredTradingPairs.length !== 0) {
      const previousPairOption = filteredTradingPairs.find(pair => pair.formattedPair === previousTradingPair?.formattedPair);
      const defaultPair = previousPairOption || filteredTradingPairs.find((el) => el.formattedPair === 'BTCUSDT') || filteredTradingPairs[0];
  
      setDefaultTraidingPair(defaultPair);
    }
  }, [filteredTradingPairs, previousTradingPair]);
  

  useEffect(() => {
    const mappedExchanges = allConnectedExchanges.map(exchange => ({
      label: exchange.accountName,
      value: exchange.exchangeAccountUuid,
      exchangeCode: exchange.exchangeCode,
    }));

    setTransformedExchanges(mappedExchanges);
  }, [allConnectedExchanges]);

  useEffect(() => {
  }, [defaultExchange, defaultMarket, defaultTraidingPair]);
  
  
  const currentSymbol = useSelector(getCurrentSymbol);
  
  const userWalletData = userWallet?.find(
    (el) => el.symbol === currentSymbol?.quoteAsset,
  );

  const userWalletBaseAsset = userWallet?.find(
    (el) => el.symbol === currentSymbol?.baseAsset,
  );
  
  useEffect(() => {
    dispatch(terminalActions.setUserWalletCurrentTradingSymbol(userWalletData));
    dispatch(terminalActions.setUserWalletBaseAsset(userWalletBaseAsset));
  }, [currentSymbol, userWalletData, userWalletBaseAsset]);
  
  return (
    <Box sx={wrapper}>
      <Box sx={tableWrapper}>
        <Box sx={filters}>
          <SingleSelect
            label='Exchange account'
            options={transformedExchanges}
            select={{
              value: defaultExchange?.accountName || '' 
                ? {
                  value: defaultExchange?.accountName,
                  label: defaultExchange?.exchangeCode,
                }
                : null,
              placeholder: 'Connect account',
              onChange: handleSelectExchange,
            }}
            optionRender={(option) => {
              return (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Text type='success'>
                    {option.label}
                  </Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text type='secondary'>
                      {getCapitalizedExchangeTitle(option?.data?.exchangeCode?.split(' ')[0])}
                    </Text>
                  </Box>
                </Box>);
            }}
            labelRender={(props) => {
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                >
                  <Text type='success'>{props.value?.toString()}</Text>
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text type='secondary'>{getCapitalizedExchangeTitle(props.label)}</Text>
                  </Box>
                </Box>
              );
            }}
          />

          <SingleSelect
            label='Market'
            showSearch={true}
            // передать массив доступных монет
            options={marketOptions}
            select={{
              value: defaultMarket,
              placeholder: 'Select market',
              onChange: handleSelectMarket,
            }}
            optionRender={(option) => {
              return (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Text type='success'>
                    {option.label}
                  </Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>
                      {option?.value?.split('-')[0]}
                    </Text>

                    <Text type='secondary'>
                      {option.label?.toString().split(' ')[0]}
                    </Text>
                  </Box>
                </Box> 
              );
            }}
            labelRender={(props) => {
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                >
                  <Text type='success'>{props.label}</Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>{props?.value?.split('-')[0]}</Text>
                    <Text type='secondary'>{props.label}</Text>
                  </Box>
                </Box>
              );
            }}
          />

          <SingleSelect
            showSearch={true}
            label='Trading Pair'
            options={filteredTradingPairs}
            select={{
              value: defaultTraidingPair,
              placeholder: 'Select trading pair',
              onChange: handleSelectTradingPair,
            }}
            optionRender={(option) => {
              return (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Text type='success'>
                    {option.label}
                  </Text>

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>
                      {option?.value?.split('-')[0]}
                    </Text>

                    <Text type='secondary'>
                      {option.label?.toString().split(' ')[0]}
                    </Text>
                  </Box>
                </Box>
              );  
            }}
            labelRender={(props) => {
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                >
                  <Text type='success'>{props.label}</Text>
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    <Text>{props.value?.split('-')[0]}</Text>
                    <Text type='secondary'>{props.label?.toString().split(' ')[0]}</Text>
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
        {isSectionLoading && (
          <Loader isContentOverflow={true} />
        )}
        <Table
          itemsCount={5}
          columns={columns}
          items={userWallet ?? []}
          graphic={(
            <Box
              display='block'
              width={209}
            >
              <PieChart
                data={userWallet ? userWallet?.map((balance) => (
                  {
                    value: balance.usdt.total,
                    amountForTooltip: balance.total,
                    name: balance.symbol,
                    totalUsdt: balance.usdt.total,
                    totalBtc: balance.btc.total,
                    overallBtcValue: chosenExchange?.totalBtc,
                    overallUsdtValue: chosenExchange?.totalUsdt,
                    overallBtcShare: (+balance.btc.total / +chosenExchange?.totalBtc) * 100,
                    overallUsdtShare: (+balance.usdt.total / +chosenExchange?.totalUsdt) * 100,
                  }
                )) : []}
                totalBtc={chosenExchange?.totalBtc}
                totalUsdt={chosenExchange?.totalUsdt}
                usdtDailyChangePercent={chosenExchange?.balances?.length ? dailyChange?.usdtDailyChangePercent : '0'}
                btcDailyChangePercent={chosenExchange?.balances?.length ? dailyChange?.usdtDailyChangePercent : '0'}
              />
            </Box>
          )}
        />
      </Box>

      <Box sx={graphicWrapper}>
        <Box sx={{
          display: 'flex', height: '100%', 
        }}>
          <Box sx={{
            flexGrow: 1, overflow: 'hidden', 
          }}>
            <TVChartContainer
              exchangeName={exchangeCode}
              tradingPair={defaultTraidingPair}
              market={defaultMarket}
            />
          </Box>
          
          {isValidSymbol(currentSymbol) ? (
            <TradingPanel
              currentSymbol={currentSymbol}
              userWalletData={userWalletData}
              userWalletBaseAsset={userWalletBaseAsset}
              exchangeAccountUuid={'212'}
            />
          ) : (
            <Loader />
          )}
          
        </Box>
      </Box>

      <ActiveTradesComponent />
    </Box>
  );
};

