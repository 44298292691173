import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, SingleSelect, Switch, Text, Title } from 'shared/ui';
import { segments } from '../consts';
import { IStopLoss } from '../interfaces';
import { header, headerTitle, priceInner, priceTitle, priceWrapper, wrapper } from '../styles';

export const StopLoss = (props: IStopLoss) => {
  const {
    setIsSkipStopLoss,
    onStopLossOrderPriceChange,
    onStopLossTriggerPriceChange,
    onStopLossOrderPricePercentChange,
  } = props;

  const {
    control,
    formState: {
      errors, 
    },
    setValue,
    trigger,
    watch,
  } = useFormContext();
  
  const currentSymbol = watch('currentSymbol');
  const isStopLossEnabled = watch('stopLossEnabled');
  const stopLossTriggerPricePercent = watch('stopLossTriggerPricePercent');
  const quoteAsset = watch('quoteAsset');
  
  const stopLossOrderType = watch('stopLossOrderType');
  
  const changeSegmentHandler = (value: string) => {
    setValue('stopLossOrderType', value as 'cond.limit' | 'cond.market');
  };
  
  const stopLossPriceRecalculation = watch('stopLossPriceRecalculation');
  const changePriceRecalculationHandler = (value: string) => {
    setValue('stopLossPriceRecalculation', value as 'average' | 'fixed');
  };

  const renderOrderPriceField = () => {
    if (stopLossOrderType === 'cond.market') {
      return null;
    }

    return (
      <Box sx={priceWrapper}>
        <Text
          type='secondary'
          styles={priceTitle}
        >
          {'Order price'}
        </Text>

        <Box sx={priceInner}>
          
          <Controller
            name='stopLossOrderPrice'
            control={control}
            rules={{
              required: 'This field is required', 
              validate: (value) => {
                const numValue = Number(value);
                if (isNaN(numValue)) return 'Value should be a number';
                if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                return true;
              },
            }}
            render={({
              field, 
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                    field.onChange(value);
                    onStopLossOrderPriceChange(value);
                  }
                  trigger('stopLossOrderPrice');
                }}
                onBlur={(value) => {
                  const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                  field.onChange(formattedValue);
                  onStopLossOrderPriceChange(formattedValue);
                  trigger('stopLossOrderPrice');
                }}
                icon={quoteAsset}
                status={errors.stopLossOrderPrice ? 'error' : undefined}
              />
            )}
          />
          
          <Controller
            name='stopLossOrderPricePercent'
            control={control}
            render={({
              field, 
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  onStopLossOrderPricePercentChange(value);
                }}
                maxWidth={58}
                icon='%'
              />
            )}
          />
        </Box>
        {errors.stopLossOrderPrice && (
          <Text type='danger' styles={{
            fontSize: '12px', marginTop: '4px', 
          }}>
            {errors.stopLossOrderPrice.message as string}
          </Text>
        )}
      </Box>
    );
  };
  
  const renderTriggerPriceField = () => {

    return (
      <Box sx={priceWrapper}>
        <Text
          type='secondary'
          styles={priceTitle}
        >
          {'Trigger price'}
        </Text>

        <Box sx={priceInner}>
          <Controller
            name='stopLossTriggerPrice'
            control={control}
            rules={{
              required: 'This field is required',
              validate: (value) => {
                const numValue = Number(value);
                if (isNaN(numValue)) return 'Value should be a number';
                if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                return true;
              },
            }}
            render={({
              field,
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                    field.onChange(value);
                    onStopLossTriggerPriceChange(value);
                  }
                  trigger('stopLossTriggerPrice');
                }}
                onBlur={(value) => {
                  const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                  field.onChange(formattedValue);
                  onStopLossTriggerPriceChange(formattedValue);
                  trigger('stopLossTriggerPrice');
                }}
                icon={quoteAsset}
                addonBefore={`${stopLossTriggerPricePercent}%`}
                status={errors.stopLossTriggerPrice ? 'error' : undefined}
              />
            )}
          />
          
          <Controller
            name='stopLossTriggerPriceType'
            control={control}
            render={({
              field, 
            }) => (
              <SingleSelect
                maxWidth='max-content'
                select={{
                  value: field.value,
                  placeholder: 'Last',
                  onChange: (value) => {
                    field.onChange(value);
                    trigger('stopLossTriggerPriceType');
                  },
                }}
                options={[
                  {
                    label: 'Last', value: 'last', 
                  },
                  {
                    label: 'Bid', value: 'bid', 
                  },
                  {
                    label: 'Ask', value: 'ask', 
                  },
                ]}
              />
            )}
          />
        </Box>
        {errors.stopLossTriggerPrice && (
          <Text type='danger' styles={{
            fontSize: '12px', marginTop: '4px', 
          }}>
            {errors.stopLossTriggerPrice.message as string}
          </Text>
        )}
      </Box>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          value={isStopLossEnabled}
          onChange={setIsSkipStopLoss}
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              Stop loss
            </Title>
          )}
          size='small'
        />

        <Segmented
          value={stopLossPriceRecalculation}
          onChange={changePriceRecalculationHandler}
          options={[{
            label: 'Average',
            value: 'average',
          }, {
            label: 'Fixed',
            value: 'fixed',
          }]}
        />
      </Box>

      {isStopLossEnabled && (
        <>
          <Segmented
            value={stopLossOrderType}
            onChange={changeSegmentHandler}
            options={segments}
            block={true}
          />
          {renderTriggerPriceField()}
          {renderOrderPriceField()}
        </>
      )}
    </Box>
  );
};
