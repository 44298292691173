import { ConfigProvider, Table, Typography } from 'antd';
import React from 'react';
import './OrderInformationTable.css';
import {
  tradesTableStyles,
  tradesTitleSideBuyStyle,
  tradesTitleSideSellStyle,
  tradesTitleStyle,
} from '../../ExchangeForm/exchange-form.styles.ts';

const {
  Title, 
} = Typography;

// Define the column structure for the tables
const columns = [
  {
    width: '150px',
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    width: '130px',
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
  },
  {
    width: '100px',
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    width: '100px',
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    width: '100px',
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const orderPriority = {
  'base_order': 1,
  'additional_base_order': 2,
  'take_profit': 3,
  'stop_loss': 4,
};

const viewTypeMapping = {
  'base_order': 'Base order',
  'additional_base_order': 'Additional base orders',
  'take_profit': 'Take profit targets',
  'stop_loss': 'Stop loss',
};

const sideMapping = {
  'BUY': 'Buy',
  'SELL': 'Sell',
};

const typeMapping = {
  'CONDITIONAL_LIMIT': 'Cond.limit',
  'CONDITIONAL_MARKET': 'Cond.market',
  'LIMIT': 'Limit',
  'MARKET': 'Market',
};

const statusMapping = {
  'active': 'active',
  'completed': 'completed',
  'request_completed': 'pending',
  'new': 'pending',
  'await_send': 'pending',
};


const getTitleStyle = (side) => {
  return side === 'BUY' ? tradesTitleSideBuyStyle : tradesTitleSideSellStyle;
};

const getPrice = (order) => {
  if (order.status === 'active' || order.status === 'new' || order.status === 'await_send' || order.status === 'request_completed' ) {
    if (order.orderType === 'LIMIT' || order.orderType === 'CONDITIONAL_LIMIT') {
      return order.limitPrice;
    } else if (order.orderType === 'CONDITIONAL_MARKET') {
      return order.triggerPrice;
    } else if (order.orderType === 'MARKET') {
      return order.executionPrice;
    }
  } else if (order.status === 'completed') {
    return order.executionPrice;
  }
};

const getUnits = (order) => {
  if (order.status === 'active' || order.status === 'new' || order.status === 'await_send' || order.status === 'request_completed' ) {
    return order.originalBase ?? null;
  } else if (order.status === 'completed') {
    return order.executionBase ?? 0;
  } 
};

const getTotal = (price, units) => {
  return isNaN((parseFloat(price) * parseFloat(units)).toFixed(2)) ? null : (parseFloat(price) * parseFloat(units)).toFixed(2);
};


export const TradeOrderComponent = ({
  trade, symbolSettings, 
}) => {
  const groupedOrders = trade?.orders.reduce((acc, order) => {
    const price = getPrice(order) ?? '';
    const units = getUnits(order) ?? '';
    const total = getTotal(price, units) ?? '';
    const side = order.side;
    
    const data = {
      key: order.orderUuid,
      price,
      units,
      total,
      type: typeMapping[order.orderType],
      status: statusMapping[order.status],
      side: side,
    };

    if (acc[order.viewType]) {
      acc[order.viewType].push(data);
    } else {
      acc[order.viewType] = [data];
    }
    return acc;
  }, {});
  
  
  const sortedGroupedOrders = Object.entries(groupedOrders)
    .sort(([viewTypeA], [viewTypeB]) => orderPriority[viewTypeA] - orderPriority[viewTypeB])
    .reduce((acc, [viewType, orders]) => {
      acc[viewType] = orders;
      return acc;
    }, {});
  
  
  
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: tradesTableStyles,
        },
      }}
    >
      <div
        style={{
          overflowX: 'auto',
          maxHeight: '400px',
          overflowY: 'auto',
        }}
      >
        {Object.entries(sortedGroupedOrders).map(([viewType, orders], index) => (
          <div key={index} style={{
            marginTop: '40px', 
          }}>
            <Title style={tradesTitleStyle} level={4}>
              {viewTypeMapping[viewType] || viewType}
              <span style={getTitleStyle(orders[0].side)}>{' ' + sideMapping[orders[0].side]}</span>
            </Title>
            <Table
              style={{
                marginTop: '30px', 
              }}
              columns={columns}
              dataSource={orders}
              pagination={false}
            />
          </div>
        ))}
      </div>
    </ConfigProvider>
  );
};
