export const coinsTogglerTextStyle = {
  width: '64px',
  height: '15px',
  fontFamily: 'Inter',
  fontSize: '11px',
  fontWeight: '400',
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: 'rgba(58, 58, 60, 1)',
  marginLeft: '8px',
  whiteSpace: 'nowrap',
};
