import { Slider, InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import {
  rangeContainerInputBoxStyles,
  rangeContainerSliderStyles,
} from '../exchange-form.styles.ts';

export const TakeProfitRange = ({
  name,
  defaultValue,
  onSliderChange,
  valueNew,
  maxValue,
}) => {
  const [max, setMax] = useState(100);

  const {
    control,
    getValues,
    formState: {
      errors, 
    },
  } = useFormContext();

  const {
    Text, 
  } = Typography;

  const [sliderValue, setSliderValue] = useState(defaultValue);

  const isTakeProfit = name === 'takeProfit.volume';

  const currentSymbol = useSelector(getCurrentSymbol);

  const validateMin = (value) => {
    const pricePercent = getValues('takeProfit.orderPricePercent') || 0;

    const baseOrderTotalValue = getValues('baseOrder.total') || 0;

    const sliderValues = getValues(name) || 0;

    const targetOrderSize =
      baseOrderTotalValue * (1 + pricePercent / 100) * (sliderValues / 100);

    return (
      Number(currentSymbol?.minNotional) < targetOrderSize || 'Order size is less than minimal'
    );
  };

  const sliderValidation = {
    required: isTakeProfit ? 'Volume is required' : false,
    validate: {
      min: validateMin,
      max: (value) => value <= max || `Volume must not exceed ${max}`,
    },
  };

  // Immediate visual update and debounced form state update
  const handleSliderChange = (value) => {
    setSliderValue(value);
    onSliderChange(value); // Call the handler passed from the parent
    // validateMin(value);
  };

  useEffect(() => {
    if (maxValue) {
      setMax(maxValue);
      onSliderChange(maxValue);
      setSliderValue(maxValue);
    }
  }, [maxValue]);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (valueNew !== 0) {
      setSliderValue(valueNew);
    }
  }, [valueNew]);

  return (
    <div style={{
      marginTop: '5px', paddingLeft: '5px', 
    }}>
      {isTakeProfit ? <label htmlFor='total'>Volume</label> : ''}

      <div className='range-container'>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={{
            required: isTakeProfit ? 'Volume is required' : false,
            validate: {
              min: validateMin,
              max: (value) => value <= max || `Volume must not exceed ${max}`,
            },
          }}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <Slider
              {...field}
              style={rangeContainerSliderStyles}
              max={max}
              onChange={handleSliderChange}
              value={sliderValue}
            />
          )}
        />

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({
            field, fieldState: {
              error, 
            }, 
          }) => (
            <InputNumber
              {...field}
              style={rangeContainerInputBoxStyles}
              controls={false}
              prefix='%'
              size='small'
              max={max}
              onChange={handleSliderChange}
              value={sliderValue}
            />
          )}
        />
      </div>

      {errors && errors.takeProfit?.volume && (
        <Text style={{
          color: 'red', fontSize: 11, 
        }}>
          {errors.takeProfit?.volume?.message}
        </Text>
      )}
    </div>
  );
};
