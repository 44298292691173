import { ActivateAccountPage } from 'features/activate-account';
import { OauthBinance, OauthBybit, OauthCryptoCom, OauthOkx } from 'features/oauth/ui/create-oauth';
import { UpdateOauthBinance, UpdateOauthBybit, UpdateOauthCryptoCom, UpdateOauthOkx } from 'features/oauth/ui/update-oauth';
import { ChangePasswordPage } from 'pages/change-password';
import { HomePage } from 'pages/home';
import { LoginPage } from 'pages/login';
import { ManualTradingIntroductionPage as ManualTradingIntroduction, ManualTradingHistoryPage as ManualTradingHistory } from 'pages/manual-trading';
import { TradingTerminal } from 'pages/manual-trading/trading-terminal';
import { MyAccountsPage, SingleAccountPage } from 'pages/my-accounts';
import { NotFoundPage } from 'pages/not-found';
import { RecoverPasswordPage } from 'pages/recover-password';
import { RegisterPage } from 'pages/register/index';
import { SubscriptionPage } from 'pages/subscriptions/subscription';
import { Configurator } from 'pages/trading-bots/configurator';
import { BotsIntroductionPage as MyBotsIntroduction } from 'pages/trading-bots/introduction';
import { MarketplacePage } from 'pages/trading-bots/marketplace';
import { MyBotPage } from 'pages/trading-bots/my-bot';
import { MyBotsPage } from 'pages/trading-bots/my-bots';
import { PublicBotSales } from 'pages/trading-bots/public-bot-sales';
import { BotsTradingHistoryPage as BotsTradingHistory } from 'pages/trading-bots/trading-history';
import { TerminalPage } from 'pages/trading-terminal-page';
import { TwoFactorPage } from 'pages/two-factor-auth';
import { RouteProps } from 'react-router-dom';

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean;
  title?: string;
};

export enum AppRoutes {
  HOME = 'home',
  MY_ACCOUNTS = 'my_accounts',
  MY_ACCOUNTS_ID = 'my_accounts_id',
  MY_BOT_ID = 'my_bot_id',
  SUBSCRIPTIONS = 'subscriptions',
  INVITE_FRIENDS = 'invite_friends',
  SUPPORT = 'support',
  ACTIVATE_ACCOUNT = 'activate_account',
  SETTINGS = 'settings',
  EXIT = 'exit',
  LOGIN = 'login',
  REGISTER = 'register',
  TWO_FACTOR = 'two_factor',
  RECOVER_PASSWORD = 'recover_password',
  CHANGE_PASSWORD = 'change_password',
  BYBIT_CREATE_OAUTH = 'bybit_create_oauth',
  BINANCE_CREATE_OAUTH = 'binance_create_oauth',
  OKX_CREATE_OAUTH = 'okx_create_oauth',
  BYBIT_UPDATE_OAUTH = 'bybit_update_oauth',
  BINANCE_UPDATE_OAUTH = 'binance_update_oauth',
  OKX_UPDATE_OAUTH = 'okx_update_oauth',
  CRYPTOCOM_OAUTH = 'cryptocom_oauth',
  CRYPTO_COM_UPDATE_OAUTH = 'crypto_com_update_oauth',
  MANUAL_TRADING_TRADING_TERMINAL = 'manual_trading_trading_terminal',
  MANUAL_TRADING_TRADING_TERMINAL_V2 = 'manual_trading_trading_terminal_v2',
  MANUAL_TRADING_TRADING_HISTORY = 'manual_trading_trading_history',
  MANUAL_TRADING_INTRODUCTION = 'manual_trading_introduction',
  TRADING_BOTS_MY_BOTS = 'trading_bots_my_bots',
  TRADING_BOTS_ACTIVE_TRADES = 'trading_bots_active_trades',
  TRADING_BOTS_TRADING_HISTORY = 'trading_bots_trading_history',
  TRADING_BOTS_MARKETPLACES = 'trading_bots_marketplace',
  TRADING_BOTS_SALES = 'trading_bots_sales',
  TRADING_BOTS_INTRODUCTION = 'trading_bots_introduction',
  TRADING_BOTS_CONFIGURATOR = 'bots_configurator',
  TRADING_BOTS_CONFIGURATOR_ID = 'bots_configurator_id',
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.LOGIN]: '/login',
  [AppRoutes.REGISTER]: '/register',
  [AppRoutes.TWO_FACTOR]: '/two-factor',
  [AppRoutes.RECOVER_PASSWORD]: '/recover-password',
  [AppRoutes.HOME]: '/home',
  [AppRoutes.ACTIVATE_ACCOUNT]: '/user/activate',
  [AppRoutes.MY_ACCOUNTS]: '/my-accounts',
  [AppRoutes.MY_ACCOUNTS_ID]: '/my-accounts/:id',
  [AppRoutes.SUBSCRIPTIONS]: '/subscriptions',
  [AppRoutes.INVITE_FRIENDS]: '/invite-friends',
  [AppRoutes.SUPPORT]: '/support',
  [AppRoutes.EXIT]: '/exit',
  [AppRoutes.CRYPTOCOM_OAUTH]: '/my-accounts/oauthCallback/crypto-com',
  [AppRoutes.BYBIT_CREATE_OAUTH]: '/oauth/create/bybit',
  [AppRoutes.BINANCE_CREATE_OAUTH]: '/oauth/create/binance',
  [AppRoutes.OKX_CREATE_OAUTH]: '/oauth/create/okx',
  [AppRoutes.CRYPTO_COM_UPDATE_OAUTH]: '/my-accounts/oauthCallback/crypto-com',
  [AppRoutes.BYBIT_UPDATE_OAUTH]: '/oauth/update/bybit',
  [AppRoutes.BINANCE_UPDATE_OAUTH]: '/oauth/update/binance',
  [AppRoutes.OKX_UPDATE_OAUTH]: '/oauth/update/okx',
  [AppRoutes.CHANGE_PASSWORD]: '/password/reset',
  [AppRoutes.SETTINGS]: '/settings',
  [AppRoutes.MANUAL_TRADING_TRADING_TERMINAL]:
    '/manual-trading/trading-terminal',
  [AppRoutes.MANUAL_TRADING_TRADING_TERMINAL_V2]:
    '/manual-trading/trading-terminal-v2',
  [AppRoutes.MANUAL_TRADING_TRADING_HISTORY]: '/manual-trading/trading-diary',
  [AppRoutes.MANUAL_TRADING_INTRODUCTION]: '/manual-trading/introduction',
  [AppRoutes.TRADING_BOTS_MY_BOTS]: '/trading-bots/my-bots',
  [AppRoutes.MY_BOT_ID]: '/trading-bots/my-bots/bot/:id',
  [AppRoutes.TRADING_BOTS_ACTIVE_TRADES]: '/trading-bots/active-trades',
  [AppRoutes.TRADING_BOTS_TRADING_HISTORY]: '/trading-bots/trading-diary',
  [AppRoutes.TRADING_BOTS_MARKETPLACES]: '/trading-bots/marketplace',
  [AppRoutes.TRADING_BOTS_SALES]: '/trading-bots/sales',
  [AppRoutes.TRADING_BOTS_INTRODUCTION]: '/trading-bots/introduction',
  [AppRoutes.TRADING_BOTS_CONFIGURATOR]: '/trading-bots/create',
  [AppRoutes.TRADING_BOTS_CONFIGURATOR_ID]: '/trading-bots/my-bots/bot/:id/edit',
  [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: (
      <LoginPage />
    ),
  },
  [AppRoutes.REGISTER]: {
    path: RoutePath.register,
    element: (
      <RegisterPage />
    ),
  },
  [AppRoutes.TWO_FACTOR]: {
    path: RoutePath.two_factor,
    element: (
      <TwoFactorPage />
    ),
  },
  [AppRoutes.RECOVER_PASSWORD]: {
    path: RoutePath.recover_password,
    element: (
      <RecoverPasswordPage />
    ),
  },
  [AppRoutes.ACTIVATE_ACCOUNT]: {
    path: RoutePath.activate_account,
    element: (
      <ActivateAccountPage />
    ),
    title: 'Activate account',
  },
  [AppRoutes.HOME]: {
    path: RoutePath.home,
    element: (
      <HomePage />
    ),
    authOnly: true,
    title: 'Home',
  },
  [AppRoutes.MY_ACCOUNTS]: {
    path: RoutePath.my_accounts,
    element: (
      <MyAccountsPage />
    ),
    authOnly: true,
    title: 'My accounts',
  },
  [AppRoutes.CRYPTOCOM_OAUTH]: {
    path: RoutePath.cryptocom_oauth,
    element: (
      <OauthCryptoCom />
    ),
    authOnly: true,
  },
  [AppRoutes.CRYPTO_COM_UPDATE_OAUTH]: {
    path: RoutePath.crypto_com_update_oauth,
    element: (
      <UpdateOauthCryptoCom />
    ),
    authOnly: true,
  },
  [AppRoutes.BYBIT_CREATE_OAUTH]: {
    path: RoutePath.bybit_create_oauth,
    element: (
      <OauthBybit />
    ),
    authOnly: true,
    title: 'ByBit',
  },
  [AppRoutes.BINANCE_CREATE_OAUTH]: {
    path: RoutePath.binance_create_oauth,
    element: (
      <OauthBinance />
    ),
    authOnly: true,
    title: 'Binance',
  },
  [AppRoutes.OKX_CREATE_OAUTH]: {
    path: RoutePath.okx_create_oauth,
    element: (
      <OauthOkx />
    ),
    authOnly: true,
    title: 'Okx',
  },
  [AppRoutes.BYBIT_UPDATE_OAUTH]: {
    path: RoutePath.bybit_update_oauth,
    element: (
      <UpdateOauthBybit />
    ),
    authOnly: true,
    title: 'ByBit',
  },
  [AppRoutes.BINANCE_UPDATE_OAUTH]: {
    path: RoutePath.binance_update_oauth,
    element: (
      <UpdateOauthBinance />
    ),
    authOnly: true,
    title: 'Binance',
  },
  [AppRoutes.OKX_UPDATE_OAUTH]: {
    path: RoutePath.okx_update_oauth,
    element: (
      <UpdateOauthOkx />
    ),
    authOnly: true,
    title: 'Okx',
  },
  [AppRoutes.MY_ACCOUNTS_ID]: {
    path: RoutePath.my_accounts_id,
    element: (
      <SingleAccountPage />
    ),
    authOnly: true,
    title: 'Connect exchange account',
  },
  [AppRoutes.MY_BOT_ID]: {
    path: RoutePath.my_bot_id,
    element: (
      <MyBotPage />
    ),
    authOnly: true,
    title: 'My bot',
  },
  [AppRoutes.SUBSCRIPTIONS]: {
    path: RoutePath.subscriptions,
    element: (
      <SubscriptionPage />
    ),
    authOnly: true,
    title: 'Subscriptions',
  },
  [AppRoutes.INVITE_FRIENDS]: {
    path: RoutePath.invite_friends,
    element: (
      <> invite friends </>
    ),
    authOnly: true,
  },
  [AppRoutes.SETTINGS]: {
    path: RoutePath.settings,
    element: <> settings </>,
    authOnly: true,
  },
  [AppRoutes.EXIT]: {
    path: RoutePath.exit,
    element: <> exit </>,
    authOnly: true,
  },
  [AppRoutes.CHANGE_PASSWORD]: {
    path: RoutePath.change_password,
    element: (
      <ChangePasswordPage />
    ),
  },
  [AppRoutes.SUPPORT]: {
    path: RoutePath.support,
    element: <> support </>,
    authOnly: true,
  },
  [AppRoutes.MANUAL_TRADING_TRADING_TERMINAL]: {
    path: RoutePath.manual_trading_trading_terminal,
    element: (
      <TerminalPage />
    ),
    authOnly: true,
    title: 'Trading terminal',
  },
  [AppRoutes.MANUAL_TRADING_TRADING_TERMINAL_V2]: {
    path: RoutePath.manual_trading_trading_terminal_v2,
    element: (
      <TradingTerminal />
    ),
    authOnly: true,
    title: 'Trading terminal',
  },
  [AppRoutes.MANUAL_TRADING_TRADING_HISTORY]: {
    path: RoutePath.manual_trading_trading_history,
    element: (
      <ManualTradingHistory />
    ),
    authOnly: true,
    title: 'Trading diary',
  },
  [AppRoutes.MANUAL_TRADING_INTRODUCTION]: {
    path: RoutePath.manual_trading_introduction,
    element: (
      <ManualTradingIntroduction />
    ),
    authOnly: true,
    title: 'Manual trading introduction',
  },
  [AppRoutes.TRADING_BOTS_MY_BOTS]: {
    path: RoutePath.trading_bots_my_bots,
    element: (
      <MyBotsPage />
    ),
    authOnly: true,
    title: 'My bots',
  },
  [AppRoutes.TRADING_BOTS_ACTIVE_TRADES]: {
    path: RoutePath.trading_bots_active_trades,
    element: <> invite frens </>,
    authOnly: true,
  },
  [AppRoutes.TRADING_BOTS_TRADING_HISTORY]: {
    path: RoutePath.trading_bots_trading_history,
    element: (
      <BotsTradingHistory />
    ),
    authOnly: true,
    title: 'Trading diary',
  },
  [AppRoutes.TRADING_BOTS_MARKETPLACES]: {
    path: RoutePath.trading_bots_marketplace,
    element: (
      <MarketplacePage />
    ),
    authOnly: true,
    title: 'Marketplace',
  },
  [AppRoutes.TRADING_BOTS_SALES]: {
    path: RoutePath.trading_bots_sales,
    element: (
      <PublicBotSales />
    ),
    authOnly: true,
  },
  [AppRoutes.TRADING_BOTS_INTRODUCTION]: {
    path: RoutePath.trading_bots_introduction,
    element: (
      <MyBotsIntroduction />
    ),
    authOnly: true,
    title: 'Bots introduction',
  },
  [AppRoutes.TRADING_BOTS_CONFIGURATOR]: {
    path: RoutePath.bots_configurator,
    element: (
      <Configurator />
    ),
    authOnly: true,
    title: 'Bots configurator',
  },
  [AppRoutes.TRADING_BOTS_CONFIGURATOR_ID]: {
    path: RoutePath.bots_configurator_id,
    element: (
      <Configurator isEdit={true} />
    ),
    authOnly: true,
    title: 'Bots configurator',
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: (
      <NotFoundPage />
    ),
    authOnly: true,
  },
};
