import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface UsePriceEffectsProps {
    chartLastPrice: number;
    limitLastPrice: number;
    currentSymbol: any;
    userWallet: any;
  }
  
export const usePriceEffects = ({
  chartLastPrice,
  limitLastPrice,
  currentSymbol,
  userWallet,
}: UsePriceEffectsProps) => {
    
  const {
    setValue, watch, 
  } = useFormContext();
    
  const isSkipBaseOrder = watch('skipBaseOrder');
  const orderType = watch('orderType');
  const lastChangedField = watch('lastChangedField');
    
  // Эффект для market ордеров
  useEffect(() => {
    if (orderType === 'market' && !isSkipBaseOrder) {
      const units = watch('units');
      const total = watch('total');
        
      setValue('orderPrice', formatByPrecisionAndTrim(
        limitLastPrice.toString(), 
        currentSymbol.quoteAssetPrecision, 
        Number(currentSymbol.priceMin), 
        Number(currentSymbol.priceMax),
      ));
        
      if (!isNaN(+units) && lastChangedField !== 'units') {
        const newUnits = +total / limitLastPrice;
        setValue('units', formatByPrecisionAndTrim(
          newUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        ));
      }
        
      if (!isNaN(+total) && lastChangedField !== null && lastChangedField !== 'total') {
        const newTotal = +units * limitLastPrice;
        setValue('total', formatByPrecisionAndTrim(
          newTotal.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.minNotional),
          Number(currentSymbol.maxNotional),
        ));
      }
    }
  }, [orderType, limitLastPrice]);
  
  // Эффект для не-market ордеров
  useEffect(() => {
    if (isSkipBaseOrder) {
      setValue('boughtPrice', formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));
    }
      
    if (orderType !== 'market') {
      handleNonMarketOrderPriceUpdate();
    }
  }, [orderType, chartLastPrice, isSkipBaseOrder]);
  
  const handleNonMarketOrderPriceUpdate = () => {
    const newOrderPrice = chartLastPrice;
    setValue('orderPrice', formatByPrecisionAndTrim(
      newOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ));
      
    if (orderType === 'conditional') {
      setValue('triggerPrice', formatByPrecisionAndTrim(
        newOrderPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));
    }
      
    updateUnitsAndTotal(newOrderPrice);
  };
  
  const updateUnitsAndTotal = (orderPrice: number) => {
    const units = watch('units');
    if (!isNaN(+units) && lastChangedField !== 'units') {
      const total = userWallet.free * 0.1;
        
      if (total > 0) {
        const newUnits = total / orderPrice;
        const formattedUnits = formatByPrecisionAndTrim(
          newUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        );
        setValue('units', formattedUnits);
          
        const newTotal = parseFloat(formattedUnits) * orderPrice;
        setValue('total', formatByPrecisionAndTrim(
          newTotal.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.minNotional),
          Number(currentSymbol.maxNotional),
        ));
        setValue('slider', 10);
      } else {
        setValue('units', '0');
        setValue('total', '0');
        setValue('slider', 0);
      }
    }
  };
};
