import { Input, ConfigProvider, Select, Typography, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import './MultiInput.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { getUserWalletBaseAsset } from '../../../../../entities/terminal/model/selectors/get-user-wallet-base-asset/get-user-wallet-base-asset.ts';
import { terminalActions } from '../../../../../entities/terminal/model/slices/terminal-slice.ts';
import { BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME } from '../BaseOrderSection/BaseOrderInputs/BaseOrderInputs.jsx';
import COLORS from '../colors';
import { roundToTickSize, roundUnitsToTickSize } from '../exchange-form-utils.js';
import {
  MARKET_VALUE,
  SELECT_OPTIONS,
  TRIGGER_PRICE_LABEL,
} from '../exchange-form.constants';
import {
  priceGroupSectionContainerStyles,
  triggerPriceGroupContainer,
  triggerPriceInputStyles,
  triggerPriceSelectStyles,
} from '../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

// Добавьте эту функцию в начало файла, после импортов
const getDecimalPlaces = (tickSize) => {
  const tickSizeString = tickSize.toString();
  const decimalIndex = tickSizeString.indexOf('.');
  return decimalIndex === -1 ? 0 : tickSizeString.length - decimalIndex - 1;
};

export const BaseOrderTriggerPriceGroup = ({
  chartLastPrice,
  priceInputControlName,
  selectControlName,
  baseOrderValue,
  defaultPrice,
  totalFieldName,
  unitsFieldName,
  addEntryEnabled,
  formDisabled,
}) => {
    
  const dispatch = useDispatch(); 
    
  const currentSymbol = useSelector(getCurrentSymbol);

  const userWalletBaseAsset = useSelector(
    getUserWalletBaseAsset,
  );
  const freeCoin = userWalletBaseAsset?.free ?? 0;
  
  const {
    control, setValue, getValues, watch, 
  } = useFormContext();

  const validateZeroValue = (value) => {
    return value !== 0 || 'Value cannot be zero';
  };
  
  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  );

  const isConditional = conditionalOrderSwitcherValue === MARKET_VALUE;

  const handleChange = (value) => {
    setValue(selectControlName, value);
  };

  const handleTriggerPriceChange = (newValue) => {
    const newTriggerPrice = roundToTickSize(newValue, currentSymbol.priceTickSize);

    setValue(priceInputControlName, newTriggerPrice, {
      shouldValidate: true, 
    });

    if (!addEntryEnabled) {
      dispatch(terminalActions.setBaseOrderPrice(newTriggerPrice));
    }
    
    if (isConditional) {
      const totalValue = getValues(totalFieldName);

      const roundedUnits = roundUnitsToTickSize(
        totalValue / newTriggerPrice,
        currentSymbol.lotTickSize,
      );

      setValue(unitsFieldName, roundedUnits, {
        shouldValidate: true, 
      });
    }
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
  
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
  
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
  
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };

  const handleParseQuoteAsset = (value) => {
    return value.replace(/[^0-9.]/g, '');
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 29,
            fontSize: 10,
            width: 20,
          },
          Select: {
            fontSize: 10,
          },
        },
      }}
    >
      <>
        <div style={triggerPriceGroupContainer}>
          <div style={priceGroupSectionContainerStyles}>
            <div>
              <Text
                style={{
                  fontSize: 12, color: COLORS.primary, 
                }}
                type='secondary'
              >
                {TRIGGER_PRICE_LABEL}
              </Text>
              <Controller
                name={priceInputControlName}
                control={control}
                defaultValue={defaultPrice}
                rules={{
                  required: 'Order price is required',
                  validate: validateZeroValue,
                }}
                render={({
                  field, fieldState: {
                    error, 
                  }, 
                }) => (
                  <>
                    <InputNumber
                      {...field}
                      size='middle'
                      suffix={currentSymbol.quoteAsset}
                      style={triggerPriceInputStyles}
                      controls={false}
                      min={0}
                      disabled={formDisabled}
                      max={999999999999}
                      formatter={handleFormatQuoteAsset}
                      parser={handleParseQuoteAsset}
                      onChange={handleTriggerPriceChange}
                    />

                    <Text style={{
                      color: 'red', fontSize: 10, 
                    }}>
                      {error?.message}
                    </Text>
                  </>
                )}
              />
            </div>

            <Controller
              name={selectControlName}
              control={control}
              defaultValue={SELECT_OPTIONS[0]}
              render={({
                field, 
              }) => (
                <Select
                  {...field}
                  style={triggerPriceSelectStyles}
                  disabled={formDisabled}
                  onChange={handleChange}
                  options={SELECT_OPTIONS}
                />
              )}
            />
          </div>
        </div>
      </>
    </ConfigProvider>
  );
};
