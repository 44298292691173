import { Box } from '@mui/material';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import {
  exchangeActions,
} from 'entities/exchange/model/slices/exchange-slice';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import {
  updateExchange,
} from 'features/update-exchange/api/update-exchange';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import { exchangeIcons } from 'shared/consts/exchange-icons';
import {
  getSkyrexUuid,
} from 'shared/helpers/storage-helper';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
} from 'shared/ui/modals';

export const UpdateBitMart = (props: UpdateExchangeProps) => {
  const {
    isModalView,
    exchangeAccountUuid,
    currentExchangeTitle,
    closeExchangeModal,
  } = props;

  const skyrexUserUuid = getSkyrexUuid();
  const [exchangeTitle, setExchangeTitle] = useState<string>(currentExchangeTitle);
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>('');
  const [modal, setModal] = useState<any>({
    isOpened: false,
    isError: false,
    title: 'Failed to update',
    description: 'Check API keys, permissions, IP whitelist or contact support',
    button: {
      title: 'Okay',
      action: () => {
        setModal((prev: any) => ({
          ...prev,
          isOpened: false,
          isError: false,
        }));
      },
    },
  });

  const dispatch = useDispatch();

  const onCloseErrorModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
      isError: false,
    }));
  };

  const onCloseSuccessModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
      isError: false,
    }));
    closeExchangeModal && closeExchangeModal();
  };

  const updateBitMartWithApiKeys = async () => {
    setIsLoading(true);
    try {
      const dataForUpdateOauth = {
        exchangeAccountUuid: exchangeAccountUuid,
        accountName: currentExchangeTitle,
        exchangeCode: 'bitmart',
        apiKey,
        apiSecret,
        passphrase: memo,
      };

      const bitmartUpdateResponse = await updateExchange(dataForUpdateOauth);

      if (bitmartUpdateResponse.success) {
        const exchangeAccounts = await setExchangeData(skyrexUserUuid!);
        dispatch(exchangeActions.setAllExchanges(exchangeAccounts));

        setModal((prev: any) => ({
          ...prev,
          isOpened: true,
          isError: false,
          title: 'Exchange account updated successfully',
          description: 'Now you are in one click to start trading bot or create manual trade using your account',
        }));
        return;
      }

      setModal((prev: any) => ({
        ...prev,
        isOpened: true,
        isError: true,
        description: bitmartUpdateResponse?.data?.message === 'Cannot update API keys because they belong to other exchange account' 
          ? 'API keys belong to another exchange account' 
          : bitmartUpdateResponse?.data?.message,
      }));
    } catch (error) {
      setModal((prev: any) => ({
        ...prev,
        isOpened: true,
        isError: true,
        description: 'Check API keys, permissions, IP whitelist or contact support',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const handleChangeMemo = (value: string) => {
    setMemo(value);
  };

  const infoForm = (
    <Info
      actionBlock={(
        <ActionBlock
          button={{
            label: 'Update BitMart',
            action: () => {},
            disabled: true,
          }}
        />
      )}
    />
  );

  const form = (
    <Form
      type='bybit'
      fields={[{
        disabled: true,
        label: 'Enter any name',
        value: exchangeTitle,
        placeholder: 'Name',
        onChange: handleChangeTitle,
      }, {
        label: 'Paste your API key',
        value: apiKey,
        placeholder: 'Key',
        onChange: handleChangeApiKey,
      }, {
        label: 'Paste your API secret',
        value: apiSecret,
        placeholder: 'Secret',
        mask: true,
        onChange: handleChangeApiSecret,
      }, {
        label: 'Memo',
        value: memo,
        placeholder: 'Input here',
        onChange: handleChangeMemo,
      }]}
      actionBlock={(
        <ActionBlock
          button={{
            label: 'Update BitMart',
            action: updateBitMartWithApiKeys,
          }}
        />
      )}
    />
  );

  return (
    <>
      <EmptySubscription
        isOpen={modal.isOpened}
        handleClose={modal.isError ? onCloseErrorModal : onCloseSuccessModal}
        modalTitle={modal.title}
        modalDescription={modal.description}
        modalButtonTitle={modal.button.title}
        modalButtonAction={modal.button.action}
        isError={modal.isError}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Update</Title>

            <Box sx={platformLogo}>
              {exchangeIcons['bitmart']}
            </Box>

            <Title>exchange</Title>
          </Box>

          <Tabs
            tabs={[{
              label: 'Fast OAuth',
              value: 0,
              disabled: true,
            }, {
              label: 'API keys',
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
            }}
            defaultValue={1}
            maxWidth='475px'
            content={[infoForm, form]}
          />
        </Box>
      </Box>
    </>
  );
};
