import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Box, Typography, Button } from '@mui/material';
import { ModalStatus } from 'shared/hooks/use-modal-state';

interface TradeActionsContentProps {
  type: string;
  chartLastPrice: number;
  onClose: () => void;
  onConfirm: () => void;
}

export const getTradeActionsContent = ({
  type,
  chartLastPrice,
  onClose,
  onConfirm,
}: TradeActionsContentProps) => {
  const getTitle = () => {
    switch (type) {
    case 'addFunds': return 'Add Funds';
    case 'closeByMarket': return 'Close by Market';
    case 'closeByLimit': return 'Close by Limit';
    case 'openByMarket': return 'Open by Market';
    case 'openByLimit': return 'Open by Limit';
    case 'edit': return 'Edit';
    case 'share': return 'Share';
    case 'cancel': return 'Cancel Position';
    default: return '';
    }
  };

  const getDescription = () => {
    switch (type) {
    case 'addFunds': return 'Description for adding funds';
    case 'closeByMarket': return 'Description for closing by market';
    case 'closeByLimit': return 'Description for closing by limit';
    case 'openByMarket': return 'Description for opening by market';
    case 'openByLimit': return 'Description for opening by limit';
    case 'edit': return 'Description for editing';
    case 'cancel': return 'This action will cancel the position on Skyrexio, cancel unfilled orders, and leave assets on the exchange';
    case 'share': return 'Share';
    default: return '';
    }
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        {getTitle()}
      </Typography>
      
      {(type === 'closeByLimit' || type === 'openByLimit') && (
        <Typography variant='body2' gutterBottom>
          Current exchange price: {chartLastPrice}
        </Typography>
      )}
      
      <Typography variant='body1' sx={{
        my: 2, 
      }}>
        {getDescription()}
      </Typography>
      
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', mt: 2, 
      }}>
        <Button variant='outlined' onClick={onClose}>
          Back
        </Button>
        <Button variant='contained' onClick={onConfirm}>
          Confirm
        </Button>
      </Box>
    </>
  );
};

interface ActionResultContentProps {
  status: ModalStatus;
  message: string;
}

export const getActionResultContent = ({
  status,
  message,
}: ActionResultContentProps) => {
  return (
    <div style={{
      textAlign: 'center', padding: '20px', 
    }}>
      {status === 'success' && (
        <CheckCircleOutlined style={{
          fontSize: 48, color: '#52c41a', 
        }} />
      )}
      {status === 'error' && (
        <CloseCircleOutlined style={{
          fontSize: 48, color: '#ff4d4f', 
        }} />
      )}
      <p style={{
        marginTop: 16, 
      }}>{message}</p>
    </div>
  );
};
