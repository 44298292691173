import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import './BaseOrderInputs.css';
import { useSelector } from 'react-redux';
import { BaseOrderTriggerPriceGroup } from '../../TriggerPriceGroup/BaseOrderTriggerPriceGroup.jsx';
import { TriggerPriceGroup } from '../../TriggerPriceGroup/TriggerPriceGroup.jsx';
import {
  ADDITIONAL_ENTRY_TOGGLER,
  BASE_ORDER_SECTION_TYPE,
  BUY_SIDE,
  CONDITIONAL,
  CONDITIONAL_VALUE,
  FIELD_TOTAL,
  FIELD_UNITS,
  LIMIT,
  LIMIT_VALUE,
  MARKET,
  MARKET_VALUE,
  SIDE_OPTION_SWITCHER,
} from '../../exchange-form.constants.js';
import { marginTopSmall } from '../../exchange-form.styles.ts';
import { GenericSwitcher } from '../../widgets/GenericSwitcher/GenericSwitcher.jsx';
import {
  conditionalSwitcherOptions,
  switcherOptions,
} from '../base-order-section.constants';
import { BasicInputs } from './BasicInputs/BasicInputs.tsx';
import { SellBasicInputs } from './BasicInputs/SellBasicInputs.tsx';
import { CoinsExistInputs } from './CoinsExistInputs/CoinsExistInput.tsx';
import { CoinsExistSellSide } from './CoinsExistInputs/CoinsExistSellSide.tsx';

const SECTION_NAME_PREFIX = 'baseOrder';
const SECTION_NAME_TOTAL = `${SECTION_NAME_PREFIX}.total`;
const SECTION_NAME_UNITS = `${SECTION_NAME_PREFIX}.units`;
const SECTION_NAME_ORDER_PRICE = `${SECTION_NAME_PREFIX}.orderPrice`;
const SECTION_NAME_ORDER_PRICE_PERCENT = `${SECTION_NAME_PREFIX}.orderPricePercent`;
const SECTION_NAME_TRIGGER_PRICE = `${SECTION_NAME_PREFIX}.triggerPrice`;
const SECTION_NAME_VOLUME = `${SECTION_NAME_PREFIX}.volume`;
const SECTION_NAME_SELECTOR = `${SECTION_NAME_PREFIX}.select`;

export const BASE_ORDER_SWITCHER_FIELD_NAME = 'baseOrderSwitcher';

export const BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME = 'conditionalSwitcher';

const COINS_EXIST_TOGGLER = 'coinsToggler';

export const BaseOrderInputs = ({
  chartLastPrice,
  formDisabled,
}) => {
  const {
    watch, setValue, getValues, 
  } = useFormContext();

  const coinsExist = watch(COINS_EXIST_TOGGLER);
  const baseOrderSwitcherValue = watch(BASE_ORDER_SWITCHER_FIELD_NAME);
  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  );

  const side = watch(SIDE_OPTION_SWITCHER);

  const isBuySide = side === BUY_SIDE;

  const addEntrySectionEnabled = getValues(ADDITIONAL_ENTRY_TOGGLER);

  useEffect(() => {
    if (!coinsExist && baseOrderSwitcherValue !== CONDITIONAL_VALUE) {
      setValue(BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME, LIMIT_VALUE);
    }
  }, [baseOrderSwitcherValue, setValue, coinsExist]);

  const isMarketOrder = baseOrderSwitcherValue === MARKET_VALUE;
  const isConditionalOrder = baseOrderSwitcherValue === CONDITIONAL_VALUE;

  const isOrderPriceDisabled =
    conditionalOrderSwitcherValue === MARKET_VALUE || isMarketOrder;

  const renderInputs = () => {
    if (coinsExist) {
      return renderInputsForExistingCoins();
    } else {
      return renderInputsForNewOrder();
    }
  };
  
  const renderInputsForExistingCoins = () => {
    return (
      <>
        {isBuySide ?  
          (<CoinsExistInputs 
            formDisabled={formDisabled} 
            baseOrderValue={chartLastPrice}  
          />) : 
          (<CoinsExistSellSide 
            baseOrderValue={chartLastPrice}
            formDisabled={formDisabled}
          />)}
      </>
    );
  };
  
  const renderInputsForNewOrder = () => {
    return (
      <>
        {isConditionalOrder && (
          <div style={marginTopSmall}>
            <BaseOrderTriggerPriceGroup
              formDisabled={formDisabled}
              chartLastPrice={chartLastPrice}
              priceInputControlName={SECTION_NAME_TRIGGER_PRICE}
              selectControlName={SECTION_NAME_SELECTOR}
              baseOrderValue={chartLastPrice}
              defaultPrice={chartLastPrice}
              addEntryEnabled={addEntrySectionEnabled}
              totalFieldName={`${BASE_ORDER_SECTION_TYPE}.${FIELD_TOTAL}`}
              unitsFieldName={`${BASE_ORDER_SECTION_TYPE}.${FIELD_UNITS}`}
            />
            <div style={marginTopSmall}>
              <GenericSwitcher
                options={conditionalSwitcherOptions}
                controlName={BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME}
              />
            </div>
          </div>
        )}

        <div>
          {!isBuySide ? (
            <SellBasicInputs
              formDisabled={formDisabled}
              addEntryEnabled={addEntrySectionEnabled}
              chartLastPrice={chartLastPrice}
              sliderName={SECTION_NAME_VOLUME}
              unitsFieldName={SECTION_NAME_UNITS}
              orderPriceFieldName={SECTION_NAME_ORDER_PRICE}
              totalFieldName={SECTION_NAME_TOTAL}
              disabled={isOrderPriceDisabled}
              selectControlName={SECTION_NAME_SELECTOR}
              isMarketOrder={isMarketOrder}
            />
          ) : (
            <BasicInputs
              formDisabled={formDisabled}
              addEntryEnabled={addEntrySectionEnabled}
              chartLastPrice={chartLastPrice}
              sliderName={SECTION_NAME_VOLUME}
              unitsFieldName={SECTION_NAME_UNITS}
              orderPriceFieldName={SECTION_NAME_ORDER_PRICE}
              totalFieldName={SECTION_NAME_TOTAL}
              disabled={isOrderPriceDisabled}
              selectControlName={SECTION_NAME_SELECTOR}
              isMarketOrder={isMarketOrder}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!coinsExist && (
        <div>
          <GenericSwitcher
            options={switcherOptions}
            controlName={BASE_ORDER_SWITCHER_FIELD_NAME}
          />
        </div>
      )}
      <div className='order-size-container'>{renderInputs()}</div>
    </>
  );
};
