import { 
  CreateExchangeProps, 
  ExchangeProps, 
  UpdateExchangeProps, 
} from 'entities/exchange/model/types/exchange-connect-update.types';
import { CreateExchange } from 'widgets/exchanges/connect-exchange/ui/connect-exchange';
import { UpdateExchange } from 'widgets/exchanges/update-exchange/ui/update-exchange';

export const Bybit = (props: ExchangeProps) => {
  const {
    isUpdate,
    isModalView,
    handleClose,
  } = props;

  const render = () => {
    if (isUpdate) {
      const {
        exchangeAccountUuid,
        currentExchangeTitle,
        closeExchangeModal,
      } = props as UpdateExchangeProps;
      
      return (
        <UpdateExchange
          exchangeType='bybit'
          isModalView={isModalView}
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    }

    const {
      returnToAllExchanges,
    } = props as CreateExchangeProps;
    
    return (
      <CreateExchange
        exchangeType='bybit'
        isModalView={isModalView}
        returnToAllExchanges={returnToAllExchanges}
        handleClose={handleClose}
      />
    );
  };

  return render();
};
