export const conditionalSwitcherOptions = [
  {
    label: 'Limit',
    value: 'LIMIT',
  },
  {
    label: 'Market',
    value: 'MARKET',

  },
];
  
  
export  const switcherOptions = [
  {
    label: 'Limit',
    value: 'LIMIT',
  },
  {
    label: 'Market',
    value: 'MARKET',
  },
  {
    label: 'Conditional',
    value: 'CONDITIONAL',
  },
];
  
  
export const askLastBidOptions = [
  {
    value: 'LAST',
    label: 'Last',
  },
  {
    value: 'BID',
    label: 'Bid',
  },
  {
    value: 'ASK',
    label: 'Ask',
  },
]; 
