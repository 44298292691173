import { Box } from '@mui/material';
import useNotification from 'antd/es/notification/useNotification';
import { Fragment } from 'react/jsx-runtime';
import { ArrowLeft, Copy, ExternalLink } from 'shared/icons';
import { 
  Input, 
  LabelInput, 
  Link, 
  Text, 
  Title, 
} from 'shared/ui';
import { ipAddresses } from '../consts/consts';
import { IField, IFormProps } from '../interfaces';
import { 
  background, 
  backWrapper, 
  blockTitle, 
  contentWrapper, 
  fieldsWrapper, 
  image, 
  text, 
  title, 
} from '../styles';

export const Form = (props: IFormProps) => {
  const {
    type,
    fields,
    actionBlock,
    returnToAllExchanges,
  } = props;
  const [api, contextHolder] = useNotification();
  
  const copyValue = async () => {
    try {
      await navigator.clipboard.writeText(ipAddresses);
      api.success({
        message: 'Copied',
      });
    } catch (err) {
      api.error({
        message: 'Failed to copy',
      });
    }
  };

  const getSecondStep = () => {
    if (type === 'binance') {
      return (
        <Box
          display='flex'
          flexDirection='column'
          gap={1}
        >
          <Text
            type='secondary'
            styles={text}
          >
            2. Turn on IP whitelisting and copy/paste these IP addresses:
          </Text>

          <Input
            value={ipAddresses}
            placeholder='109.68.213.42 188.225.24.146 92.255.78.51 194.187.122.188 5.23.48.98 87.249.44.73 80.90.190.204 80.90.186.118 188.225.85.35 188.225.18.82 77.232.129.33'
            onChange={() => {}}
            disabled
            icon={(
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={copyValue}
              >
                {Copy}
              </div>
            )}
          />
        </Box>
      );
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        gap={1}
      >
        <Text
          type='secondary'
          styles={text}
        >
          2. Select third-party apps and choose Skyrex:
        </Text>

        <img
          style={image}
          src='/images/api-keys-second-step.png'
          alt='screen'
        />
      </Box>
    );
  };

  return (
    <Fragment>
      {contextHolder}

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={5}
      >
        <Title
          level={4}
          styles={title}
        >
          Connect your exchange account securely with API keys
        </Title>

        <Box sx={contentWrapper}>
          <Box
            display='flex'
            flexDirection='column'
            gap={4}
            sx={background}
          >
            <Title
              level={5}
              styles={blockTitle}
            >
              <Box
                display='flex'
                alignItems='center'
                gap={0.5}
              >
                Connect keys securely
                <Box
                  display='flex'
                  alignItems='center'
                  gap={0.5}
                >
                  <Link
                    type='success'
                    styles={blockTitle}
                    href=''
                  >
                    Full guide
                  </Link>

                  {ExternalLink}
                </Box>
              </Box>
            </Title>

            <Box
              display='flex'
              flexDirection='column'
              gap={3}
            >
              <Box
                display='flex'
                alignItems='center'
                gap={0.5}
              >
                <Text
                  type='secondary'
                  styles={text}
                >
                  1. Log in to your exchange account and go

                  <Box
                    display='flex'
                    alignItems='center'
                    gap={0.5}
                  >
                    to

                    <Link
                      type='success'
                      style={text}
                      href=''
                    >
                      API settings
                    </Link>

                    {ExternalLink}
                  </Box>
                </Text>
              </Box>

              {getSecondStep()}

              <Text
                type='secondary'
                styles={text}
              >
                3. Paste generated data in inputs on right
              </Text>
            </Box>
          </Box>

          <Box sx={fieldsWrapper}>
            <Box
              display='flex'
              flexDirection='column'
              gap={2}
            >
              {fields.map((field: IField) => (
                <LabelInput
                  disabled={field.disabled}
                  key={field.label}
                  type={field.type}
                  autoComplete={field.autoComplete}
                  label={field.label}
                  mask={field.mask}
                  value={field.value}
                  placeholder={field.placeholder}
                  onChange={field.onChange}
                />
              ))}
            </Box>
          </Box>
        </Box>
        
        {actionBlock}

        {returnToAllExchanges && (
          <div 
            style={backWrapper} 
            onClick={returnToAllExchanges}
          >
            {ArrowLeft}
            <Text>Back to exchanges</Text>
          </div>
        )}
      </Box>
    </Fragment>
  );
};
