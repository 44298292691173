import { classNames } from '../../lib/classNames/classNames';
import cls from './Button.module.css';

export const Button = (props) => {
  const {
    disabled, className, children, theme, ...otherProps 
  } = props;

  return (
    <button
      disabled={disabled}
      className={classNames(cls.Button, {}, [className, theme])}
      {...otherProps}
    >
      {children}
    </button>
  );
};
