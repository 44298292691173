// @ts-nocheck

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './customSlider.css';
import { EllipsisOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Card,
  Dropdown,
  Typography,
  Button,
  ConfigProvider,
  Slider,
  Table,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'shared/ui';
import { ReactComponent as InfoIcon } from '../../images/information.svg';
// import { ReactComponent as Completed } from '../../images/markOk.svg';
import { manualTradeCancel, manualTradeCloseByLimit, manualTradeCloseByMarket, manualTradeOpenByLimit, manualTradeOpenByMarket, manualTradeRefresh } from '../ExchangeForm/api/trade-actions';
import { CustomModal } from '../ExchangeTable/Modal/Modal.jsx';
// import { CustomModal } from '../Modal/Modal.jsx';
import { ActiveTradesHeader } from './ActiveTradesHeader/ActiveTradesHeader.jsx';
import { TradeOrderComponent } from './OrderInformationTable/OrderInformationTable.jsx';
import { TradeContainer } from './TradeContainer';
import { ReactComponent as Failed } from './images/fail.svg';
import { ReactComponent as Completed } from './images/markOk.svg';
import { ReactComponent as ReloadIcon } from './images/update.svg';
import { ReactComponent as ClockIcon } from './images/waiting.svg';
import {
  dataStyles,
  generateMenu,
  getReturnPercentLabelsColor,
} from './tradesConstants.js';
import { getModalContent, getSliderMarks } from './tradesHelpers.tsx';
import { getAllExchangeSymbols } from 'entities/terminal/model/selectors/get-all-exchange-symbols/get-all-exchange-symbols';
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { getAllActiveTrades } from 'entities/terminal/model/selectors/get-all-active-trades/get-all-active-trades';
import { getActiveTrades } from 'pages/trading-terminal-page/trading-chart/api/get-active-trades';

const {
  Text, 
} = Typography;

interface ActivePositionBlockProps {
  tradingPair: string;
  startDate: Date | string;
  currentStatus: number;
  pnlUSD: number;
  pnlPercent: number;
  valueInMatic: number;
  valueInUsdt: number;
}

export const ActivePositionBlock: React.FC<ActivePositionBlockProps> = ({
  startDate,
  orders,
  currentExchangePrice,
  //когда только размещен и не исполнен может быть null
  returnPercentValue,
  executedBaseFirst,
  executedQuoteFirst,
  trade,
  flagCancelledTrade,
}) => {
  const [tradeState, setTradeState] = useState({
    status: trade?.status,
    type: '',
  });

  const exchangeSymbols = useSelector(getAllExchangeSymbols);
  
  const symbol = trade.baseSymbol + trade.quoteSymbol;
  const currentSymbol = exchangeSymbols.find((el) => el.symbol === symbol);

  const [expanded, setExpanded] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showReturnValues =
    trade.returnPercent !== null &&
    (trade.returnQuote !== null || trade.returnBase !== null);

  const showReturnValuesAccordinglyToTradeState =
    tradeState.status === 'active' || tradeState.status === 'new';

  const hideUiElements =
    tradeState.status === 'cancelled' || tradeState.status === 'completed';

  //привязать к quoteQurrency

  const getModalReturn = (trade) => {
    if (showReturnValues) {
      return trade.sideFirstStep === 'BUY'
        ? !isNaN(trade.returnQuote)
          ? parseFloat(trade.returnQuote ?? 0).toFixed(
            currentSymbol?.chartPrecision,
          )
          : 0
        : !isNaN(trade.returnBase)
          ? parseFloat(trade.returnBase ?? 0).toFixed(
            currentSymbol?.baseCurrencyChartPrecision,
          )
          : 0;
    }
    return null;
  };

  useEffect(() => {
    // Define the interval ID for cleanup
    let intervalId = null;
    // Define a function that wraps `handleUpdateTrade` to include necessary logic
    const periodicUpdate = () => {
      handleUpdateTrade(trade); // Pass `trade` or any other necessary arguments
    };

    // Set up the interval only if certain conditions are met
    if (
      trade?.status === 'active' ||
      trade?.status === 'new' ||
      trade?.status === 'request_сompleted' ||
      trade?.status === 'requested_сancelled'
    ) {
      //@ts-ignore
      intervalId = setInterval(periodicUpdate, 5000); // Set up the interval
    }

    // Cleanup function to clear the interval when the component unmounts or dependencies change
    return () => {
      if (intervalId) clearInterval(intervalId);
    };

    // Specify dependencies for the effect. Include any props or state that, when changed, should restart the interval.
  }, [tradeState.status, trade]);

  const handleCloseTradeByMarket = async (trade) => {
    try {
      setIsModalOpen(false);

      const response = await manualTradeCloseByMarket(trade.smartTradeUuid);
      // const response = await manual_trade_close_by_market(trade.smartTradeUuid);

      if (response.status) {
        if (response.data.failedSmartTradeUuids.length) {
          setTradeState({
            status: 'failed_to_close', type: '', 
          });
        }

          
          
        setTradeState({
          status: 'processing_to_close', type: '', 
        });
        return;
      }
    } catch (error) {
      toast.error('Error when closing order');
    }
  };

  const handleCloseTradeByLimit = async (trade) => {
    try {
      setIsModalOpen(false);

      const response = await manualTradeCloseByLimit(trade.smartTradeUuid);
      // const response = await manual_trade_close_by_limit(trade.smartTradeUuid);
      
      
      if (response) {
        setTradeState({
          status: 'processing_to_close', type: '', 
        });
      }
    } catch (error) {
      toast.error('Error when closing order');
    }
  };

  const handleCancelTrade = async (trade) => {
    try {
      setIsModalOpen(false);
      const response = await manualTradeCancel(trade.smartTradeUuid);
      // const response = await manual_trade_cancel(trade.smartTradeUuid);

      if (response) {
        setTradeState({
          status: 'cancelling', type: '', 
        });
      }
    } catch (error) {
      toast.error('Error when closing order');
    }
  };

  const handleOpenTradeByMarket = async (trade) => {
    try {
      setIsModalOpen(false);
      const response = await manualTradeOpenByMarket(trade.smartTradeUuid);
      // const response = await manual_trade_open_by_market(trade.smartTradeUuid);
      if (response) {
        setTradeState({
          status: 'processing_to_open', type: '', 
        });
      }
    } catch (error) {}
  };

  const handleOpenTradeByLimit = async (trade) => {
    try {
      setIsModalOpen(false);
      
      const response = await manualTradeOpenByLimit(trade.smartTradeUuid);
      // 
      
      
      if (response.success) {
        if (response.data.failedSmartTradeUuids.length) {
          return setTradeState({
            status: 'failed_to_open', type: '', 
          });
        }
        
        setTradeState({
          status: 'processing_to_open', type: '', 
        });
      }
    } catch (error) {}
  };

  const getCurrency = (trade) => {
    if (trade.sideFirstStep === 'BUY') {
      return trade.quoteSymbol;
    } else {
      return trade.baseSymbol;
    }
    
  };

  const getCurrentExchangePrice = (trade) => {
    return parseFloat(trade.currentExchangePrice).toFixed(
      currentSymbol?.chartPrecision,
    );
  };

  const getModalReturnPercent = (trade) => {
    if (showReturnValues) {
      return `${Number(trade.returnPercent).toFixed(2)}%`;
    }
    return null;
  };

  const isCloseTrade = false;
  const isOpenTrade = true;

  const closeByMarketModalContent = getModalContent(
    'close_by_market',
    `${getModalReturn(trade)}`,
    `${getModalReturnPercent(trade)}`,
    `${getCurrency(trade)}`,
    isCloseTrade,
    () => handleCloseTradeByMarket(trade),
  );

  const closeByLimitModalContent = getModalContent(
    'close_by_limit',
    `${getModalReturn(trade)}`,
    `${getModalReturnPercent(trade)}`,
    `${getCurrency(trade)}`,
    isCloseTrade,
    () => handleCloseTradeByLimit(trade),
  );

  const cancelModalContent = getModalContent(
    'cancel',
    `${getModalReturn(trade)}`,
    `${getModalReturnPercent(trade)}`,
    `${getCurrency(trade)}`,
    isCloseTrade,
    () => handleCancelTrade(trade),
  );

  const openByLimitModalContent = getModalContent(
    'open_by_limit',
    getCurrentExchangePrice(trade),
    '',
    `${getCurrency(trade)}`,
    isOpenTrade,
    () => handleOpenTradeByLimit(trade),
  );

  const openByMarketModalContent = getModalContent(
    'open_by_market',
    getCurrentExchangePrice(trade),
    '',
    `${getCurrency(trade)}`,
    isOpenTrade,
    () => handleOpenTradeByMarket(trade),
  );

  const modalContentTypeMap = {
    close_by_market: closeByMarketModalContent,
    close_by_limit: closeByLimitModalContent,
    cancel: cancelModalContent,
    open_by_market: openByMarketModalContent,
    open_by_limit: openByLimitModalContent,
  };

  const getModalContentType = (type) => {
    return modalContentTypeMap[type];
  };

  //@ts-ignore
  const {
    marks, lowest, highest, entryMark, 
  } = getSliderMarks(
    orders,
    currentExchangePrice,
    returnPercentValue,
    executedQuoteFirst,
    executedBaseFirst,
    currentSymbol,
    trade,
  );

  const showProgressOnBar = (trade) => {
    const baseOrderCompleted = trade.returnPercent;

    return baseOrderCompleted ? true : false;
  };

  const formatSliderValue = (value, precision) => {
    return parseFloat(parseFloat(value).toFixed(precision));
  };

  const [sliderValue, setSliderValue] = useState([
    Number(formatSliderValue(currentExchangePrice, 2)),
    Number(formatSliderValue(entryMark, 2)),
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    setSliderValue([
      showProgressOnBar(trade)
        ? Number(formatSliderValue(currentExchangePrice, 2))
        : undefined,
      showProgressOnBar(trade)
        ? Number(formatSliderValue(entryMark, 2))
        : undefined,
    ]);
  }, [currentExchangePrice, entryMark]);

  const formattedStartDate =
    typeof startDate === 'string'
      ? startDate
      : moment(startDate).format('MM.DD.YYYY');
  const formattedStartTime =
    typeof startDate === 'string' ? '' : moment(startDate).format('hh:mm:ss A');

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleUpdateTrade = async (trade) => {
    try {
      
      const responseFromRefreshTrade = await manualTradeRefresh(trade.smartTradeUuid);
      // const data = await manual_trade_update(trade.smartTradeUuid);
      
      const isTradeCompleted = responseFromRefreshTrade?.data.smartTrades[0]?.status === 'completed';

      if (isTradeCompleted) {
        setTradeState({
          status: 'closed', type: '', 
        });
        
        dispatch(terminalActions.closeTrade(responseFromRefreshTrade?.data.smartTrades[0]));
        // dispatch(close_trade(data[0]));
        return;
      }

      const isTradeCancelled = responseFromRefreshTrade?.data.smartTrades[0]?.status === 'cancelled';

      if (isTradeCancelled) {
        setTradeState({
          status: 'cancelled', type: '', 
        });
        
        dispatch(terminalActions.closeTrade(responseFromRefreshTrade?.data.smartTrades[0]));
        // dispatch(close_trade(data[0]));
        return;
      }

      const processToCancelTrade = responseFromRefreshTrade?.data.smartTrades[0]?.status === 'requested_cancelled';

      if (processToCancelTrade) {
        setTradeState({
          status: 'cancelling', type: '', 
        });
        dispatch(terminalActions.updateTrade(responseFromRefreshTrade?.data.smartTrades[0]));
        // dispatch(update_trade(data[0]));
        return;
      }

      const processToClose = responseFromRefreshTrade?.data.smartTrades[0]?.status === 'request_completed';

      if (processToClose) {
        const tradeOrderForClose = responseFromRefreshTrade?.data.smartTrades[0]?.orders?.filter(
          (trade) => trade.viewType === 'force_order_step_second',
        );

        const tradeClosedError = tradeOrderForClose?.find(
          (trade) => trade.status === 'create_failed',
        );

        if (tradeClosedError) {
          setTradeState({
            status: 'failed_to_close', type: '', 
          });
          dispatch(terminalActions.updateTrade(responseFromRefreshTrade?.data.smartTrades[0]));
          return;
        }

        setTradeState({
          status: 'processing_to_close', type: '', 
        });
        dispatch(terminalActions.updateTrade(responseFromRefreshTrade?.data.smartTrades[0]));
        return;
      }

      //ветка активного ордера
      if (responseFromRefreshTrade?.data.smartTrades[0]?.status === 'new' || responseFromRefreshTrade?.data.smartTrades[0]?.status === 'active') {
        const tradeOrderForOpen = responseFromRefreshTrade?.data.smartTrades[0]?.orders?.filter(
          (trade) => trade.viewType === 'force_order_step_first',
        );

        const tradeOpenedError = tradeOrderForOpen?.find(
          (trade) => trade.status === 'create_failed',
        );

        if (tradeOpenedError) {
          setTradeState({
            status: 'failed_to_open', type: '', 
          });
          dispatch(terminalActions.updateTrade(responseFromRefreshTrade?.data.smartTrades[0]));
          return;
        }

        setTradeState({
          status: 'active', type: '', 
        });
        dispatch(terminalActions.updateTrade(responseFromRefreshTrade?.data.smartTrades[0]));
        return;
      }
    } catch (error) {
      console.error('Error updating trade:', error);
    }
  };

  const handleCloseByMarket = (trade) => {
    setModalContent(getModalContentType(trade));
    setIsModalOpen(true);
  };

  const handleCloseByLimit = (trade) => {
    setModalContent(getModalContentType(trade));
    setIsModalOpen(true);
  };

  const handleCancelTradeModal = (trade) => {
    setModalContent(getModalContentType(trade));
    setIsModalOpen(true);
  };

  const handleOpenByMarket = (trade) => {
    setModalContent(getModalContentType(trade));
    setIsModalOpen(true);
  };

  const handleOpenByLimit = (trade) => {
    setModalContent(getModalContentType(trade));
    setIsModalOpen(true);
  };

  const tradeMenu = generateMenu(
    trade,
    handleCloseByMarket,
    handleCloseByLimit,
    handleCancelTradeModal,
    handleOpenByMarket,
    handleOpenByLimit,
  );

  const sliderStyles = {
    handleSize: -1,
    handleSizeHover: 0,
    handleColorDisabled: 'rgba(0,0,0,0)',
    trackBgDisabled: getReturnPercentLabelsColor(returnPercentValue),
    dotSize: 5,
    dotBorderColor: 'rgba(0,0,0,0)',
    dotActiveBorderColor: 'rgba(0,0,0,0)',
    controlSize: 10,
    railSize: 9,
  };

  const cardStyles = {
    width: '100%',
    textAlign: 'left',
    height: expanded ? 'auto' : '200px', // Adjust height based on expanded state
    transition: 'height 0.3s ease', // Optional: animate the height change
  };

  const cardContentWrapperStyles = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
  };

  const infoBlockStyle = {
    width: '400px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const sideMapping = {
    BUY: 'Buy',
    SELL: 'Sell',
  };

  const getReturnValue = (trade) => {
    const tradeSide = trade.sideFirstStep;

    const roundedBase = parseFloat(trade.returnBase).toFixed(
      currentSymbol?.baseCurrencyChartPrecision,
    );

    const roundedQuote = parseFloat(trade.returnQuote).toFixed(
      currentSymbol?.chartPrecision,
    );

    const returnString =
      tradeSide === 'BUY'
        ? `${roundedQuote} ${trade.quoteSymbol}`
        : `${roundedBase} ${trade.baseSymbol}`;

    return returnString;
  };

  const renderTradeState = (tradeState) => {
    switch (tradeState.status) {
    case 'processing_to_open':
      return (
        <div style={infoBlockStyle}>
          <div>{<ClockIcon />}</div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Position is being opened
          </div>
        </div>
      );
    case 'failed_to_open':
      return (
        <div style={infoBlockStyle}>
          <div>{<Failed />}</div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Failed to open, contact support
          </div>
        </div>
      );
    case 'processing_to_close':
      return (
        <div style={infoBlockStyle}>
          <div>
            {' '}
            <ClockIcon />
          </div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Position is being closed
          </div>
        </div>
      );
    case 'failed_to_close':
      return (
        <div style={infoBlockStyle}>
          <div>{<Failed />}</div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Failed to close, contact support
          </div>
        </div>
      );
    case 'cancelling':
      return (
        <div style={infoBlockStyle}>
          <div>
            {' '}
            <ClockIcon />
          </div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Position is being cancelled
          </div>
        </div>
      );
    case 'cancelled':
      return (
        <div style={infoBlockStyle}>
          <div>
            {' '}
            <Completed />
          </div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Position is cancelled
          </div>
        </div>
      );
    case 'closed':
      return (
        <div style={infoBlockStyle}>
          <div>
            {' '}
            <Completed />
          </div>
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            Position is closed
          </div>
        </div>
      );

    case 'active':
      return (
        <div
          style={{
            width: '350px',
          }}
        >
          <Slider
            className='my-slider'
            style={{
              cursor: 'default',
            }}
            range
            min={lowest}
            max={highest}
            tooltip={{
              open: false,
            }}
            marks={marks}
            value={sliderValue}
            disabled={true}
          />
        </div>
      );

    default:
      // Handle unexpected trade states or as a fallback
      return null;
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins',
        },
        components: {
          Slider: sliderStyles,
          Card: {
            borderRadiusLG: 17,
          },
        },
      }}
    >
      <Card bordered={true} style={cardStyles}>
        <div style={cardContentWrapperStyles}>
          <div
            style={{
              cursor: 'pointer', marginBottom: '10px', 
            }}
            onClick={toggleExpand}
          >
            {expanded ? <UpOutlined /> : <DownOutlined />}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              marginBottom: '15px',
            }}
          >
            <Text
              style={{
                fontSize: window.innerWidth <= 480 ? '14px' : '16px',
                color: 'rgba(0, 0, 0, 0.85)',
                marginRight: '16px',
              }}
            >
              <span>{trade.baseSymbol}</span>
              <span
                style={{
                  color: '#2ECD99',
                }}
              >
                {trade.quoteSymbol}
              </span>
            </Text>
          </div>

          <div>
            <Text style={dataStyles}>{formattedStartDate}</Text>
            <Text style={dataStyles}>{formattedStartTime}</Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              margin: '8px',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                display: 'block',
                whiteSpace: 'nowrap',
                color: '#9E9E9E',
              }}
            >{`${parseFloat(trade.originalBase).toFixed(
                currentSymbol?.baseCurrencyChartPrecision,
              )} ${trade.baseSymbol}`}</Text>
            <Text
              style={{
                fontSize: '12px',
                display: 'block',
                whiteSpace: 'nowrap',
                color: '#9E9E9E',
              }}
            >{`${parseFloat(trade.originalQuote).toFixed(
                currentSymbol?.chartPrecision,
              )} ${trade.quoteSymbol}`}</Text>
          </div>

          {/* <div style={{
            height: '120px',
            width: '500px',
            borderRadius: '12px',
            backgroundColor: '#F8FBF9',
            display: 'flex',
            justifyContent: 'center'
          }}> */}

          {renderTradeState(tradeState)}

          <div style={{
            margin: '8px', 
          }}>
            <Text
              style={{
                display: 'block',
                visibility: !showReturnValues ? 'hidden' : 'visible',
                color: getReturnPercentLabelsColor(trade.returnPercent),
              }}
            >
              {getReturnValue(trade)}
            </Text>
            <Text
              style={{
                display: 'block',
                visibility: !showReturnValues ? 'hidden' : 'visible',
                color: getReturnPercentLabelsColor(trade.returnPercent),
              }}
            >
              {`${Number(trade.returnPercent).toFixed(2)}%`}
            </Text>
          </div>
          {/* )} */}

          <div
            style={{
              visibility: hideUiElements ? 'hidden' : 'visible',
            }}
          >
            <Dropdown overlay={tradeMenu} trigger={['click']}>
              <a
                className='ant-dropdown-link'
                onClick={(e) => e.preventDefault()}
              >
                <EllipsisOutlined
                  rotate={90}
                  style={{
                    fontSize: '20px', color: '#2ECD99', 
                  }}
                />
              </a>
            </Dropdown>
            <Button
              onClick={() => handleUpdateTrade(trade)}
              style={{
                border: 'none', boxShadow: 'none', marginLeft: '10px', 
              }}
              icon={<ReloadIcon />}
            />
          </div>
        </div>
        {expanded && (
          <div
            style={{
              marginTop: '50px',
            }}
          >
            <TradeOrderComponent trade={trade} symbolSettings={currentSymbol} />
          </div>
        )}
      </Card>
      
      {modalContent && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          {...modalContent}
        />
      )}
    </ConfigProvider>
  );
};

export const ActiveTrades: React.FC = ({
  exchangeAccountUuid, fetchTrigger,
}) => {
  const dispatch = useDispatch();
  const activeTrades = useSelector(getAllActiveTrades);
  
  const [tradesState, setTradesState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTradesLoading, setIsTradesLoading] = useState(fetchTrigger);
  const [lastFetchTime, setLastFetchTime] = useState(0);
  
  const fetchUserManualTrades = useCallback(async () => {
    const now = Date.now();
    if (now - lastFetchTime < 5000) {
      return; // Пропускаем запрос, если прошло менее 5 секунд
    }
    
    try {
      setIsLoading(true);
      const trades = await getActiveTrades();
      
      setTradesState(trades);
      dispatch(terminalActions.setAllActiveTrades(trades));
      setLastFetchTime(now);
    } catch (error) {
      console.error('Ошибка при получении активных сделок:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, lastFetchTime]);
  
  useEffect(() => {
    setIsLoading(!fetchTrigger);
  }, [fetchTrigger]);
  
  useEffect(() => {
    fetchUserManualTrades();
  }, [exchangeAccountUuid, fetchTrigger, fetchUserManualTrades]);
  
  const sortedTrades = useMemo(() => {
    return [...tradesState].sort((a, b) => {
      const dateA = new Date(a.createdDate);
      const dateB = new Date(b.createdDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [tradesState]);
  
  if (isLoading) {
    return <Loader isContentOverflow={true} />;
  }
  
  return (
    <TradeContainer sortedTrades={sortedTrades} fetchTrigger={isTradesLoading} />
  );
};
