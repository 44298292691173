import { Box } from '@mui/material';
import { Button } from 'antd';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useFormSubmission } from 'pages/manual-trading/trading-terminal/hooks/use-form-submission';
import { SideProps } from 'pages/manual-trading/trading-terminal/types/order-panel.types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ConfirmTransaction, Text, Title, Tooltip } from 'shared/ui';
import { useEntryEffects } from '../../buy/hooks/use-entry-effects';
import { useErrorEffects } from '../../buy/hooks/use-error-effects';
import { useFormUpdates } from '../../buy/hooks/use-form-updates';
import { useSectionTogglers } from '../../buy/hooks/use-section-togglers';
import { useSymbolEffects } from '../../buy/hooks/use-symbol-effects';
import { AdditionalEntrySell } from '../components/additional-entry-sell';
import BaseOrderSell from '../components/base-order-sell';
import { StopLossSell } from '../components/stop-loss-sell';
import { TakeProfitSell } from '../components/take-profit-sell';
import { useAdditionalEntrySellInputsLogic } from '../hooks/use-additional-entry-sell-inputs';
import { useBaseOrderSellInputsLogic } from '../hooks/use-base-order-sell-inputs-logic';
import { usePriceEffects } from '../hooks/use-price-effects-sell';
import { useStopLossSellInputsLogic } from '../hooks/use-stop-loss-sell-inputs';
import { useTakeProfitSellInputsLogic } from '../hooks/use-take-profit-sell-inputs';
import { InfoIcon, Wallet } from '../icons';
import { inner, price, priceWrapper, title, tooltipText, wrapper } from '../styles';

export const Sell = (props: SideProps) => {
  const {
    currentSymbol,
    userWallet = {
      free: '0',
    },
    userWalletBaseAsset = {
      free: '0',
    },
    exchangeAccountUuid,
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const limitLastPrice = useSelector(getLimitLastPrice);
  
  const {
    setValue, watch, getValues, setError, trigger, 
  } = useFormContext();
  
  const isSkipBaseOrder = watch('skipBaseOrder');
  const orderType = watch('orderType');
  
  const {
    formState: {
      isModalVisible, confirmationData, collectedData, 
    },
    handlers: {
      handleFormSubmit, setIsModalVisible, isFormValidExceptAdditionalEntries, 
    },
  } = useFormSubmission({
    isSkipBaseOrder,
    exchangeAccountUuid,
  });
  
  const {
    setIsSkipAdditionalEntry,
    setIsSkipTakeProfit,
    setIsSkipStopLoss,
  } = useSectionTogglers();
  
  useSymbolEffects({
    currentSymbol,
  });
  
  useEntryEffects();
  
  useErrorEffects();
  

  
  const baseOrderSellInputsLogic = useBaseOrderSellInputsLogic(
    currentSymbol, 
    userWallet, 
    userWalletBaseAsset, 
    watch, 
    setValue,
    isSkipBaseOrder,
    chartLastPrice,
    limitLastPrice,
  );
  
  const {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
    setInitialValues,
  } = baseOrderSellInputsLogic;

  const additionalEntrySellInputsLogic = useAdditionalEntrySellInputsLogic(
    currentSymbol, 
    userWallet, 
    userWalletBaseAsset, 
    watch, 
    setValue,
    getValues,
    setError,
    isSkipBaseOrder,
  );
  
  const {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  } = additionalEntrySellInputsLogic;
  
  const takeProfitInputsLogic = useTakeProfitSellInputsLogic(currentSymbol, watch, setValue, getValues, setError);
  
  const {
    handleTakeProfitOrderPricePercentChange,
    handleTakeProfitTriggerPriceChange,
    handleTakeProfitOrderPriceChange,
    handleAddTakeProfitEntry,
    handleRemoveTakeProfitEntry,
    updateTakeProfitValues,
  } = takeProfitInputsLogic;
  
  const stopLossInputsLogic = useStopLossSellInputsLogic(currentSymbol, watch, setValue);
  
  const {
    handleStopLossOrderPricePercentChange,
    handleStopLossTriggerPriceChange,
    handleStopLossOrderPriceChange,
    updateStopLossValues,
  } = stopLossInputsLogic;
  
  useFormUpdates({
    updateAdditionalEntryValues,
    updateTakeProfitValues,
    updateStopLossValues,
  });
  
  useEffect(() => {
    setInitialValues();
    trigger();
  }, [orderType, chartLastPrice, isSkipBaseOrder, setInitialValues]);
  
  usePriceEffects({
    chartLastPrice,
    limitLastPrice,
    currentSymbol,
    userWalletBaseAsset,
  });
  
  return (
    <>
      <Box sx={wrapper} component='form' onSubmit={handleFormSubmit}>
        <Box sx={inner}>
          <Title styles={title}>
            {isSkipBaseOrder ? `Sold ${currentSymbol?.baseAsset}` : `Sell ${currentSymbol?.baseAsset}`}
          </Title>

          <Box sx={priceWrapper}>
            <Box
              width={12}
              height={12}
            >
              {Wallet}
            </Box>

            <Text
              type='success'
              styles={price}
            >
              {!isSkipBaseOrder ? `${
                formatByPrecisionAndTrim(
                  userWalletBaseAsset.free.toString(), 
                  currentSymbol?.baseAssetPrecision, 
                  Number(currentSymbol.lotMin), 
                  Number(currentSymbol.lotMax),
                )}
                  ${currentSymbol?.baseAsset}`
                : `${
                  formatByPrecisionAndTrim(
                    userWallet.free.toString(),
                    currentSymbol?.quoteAssetPrecision,
                    Number(currentSymbol.minNotional),
                    Number(currentSymbol.maxNotional),
                  )}
                  ${currentSymbol?.quoteAsset}`
              }
            </Text>

            <Tooltip
              title={(
                <Box maxWidth={188}>
                  <Title
                    level={5}
                    styles={tooltipText}
                  >
                    {currentSymbol?.baseAsset} amount currently <br /> available on Exchange account
                  </Title>
                </Box>
              )}
            >
              <Box
                width={12}
                height={12}
              >
                {InfoIcon}
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <BaseOrderSell
          isSkipBaseOrder={isSkipBaseOrder}
          onTriggerPriceChange={handleTriggerPriceChange}
          onOrderPriceChange={handleOrderPriceChange}
          onBoughtPriceChange={handleBoughtPriceChange}
          onTotalChange={handleTotalChange}
          onUnitsChange={handleUnitsChange}
          onSliderChange={handleSliderChange}
          onSkipBaseTotalChange={handleSkipBaseTotalChange}
          onSkipBaseUnitsChange={handleSkipBaseUnitsChange}
          onSkipBaseSliderChange={handleSkipBaseSliderChange}
        />

        <AdditionalEntrySell
          setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
          onAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
          onAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
          onAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
          onAdditionalUnitsChange={handleAdditionalUnitsChange}
          onAdditionalTotalChange={handleAdditionalTotalChange}
          onAdditionalSliderChange={handleAdditionalSliderChange}
          onAddEntry={handleAddEntry}
          onRemoveEntry={handleRemoveEntry}
        />

        <TakeProfitSell
          setIsSkipTakeProfit={setIsSkipTakeProfit}
          onTakeProfitOrderPriceChange={handleTakeProfitOrderPriceChange}
          onTakeProfitTriggerPriceChange={handleTakeProfitTriggerPriceChange}
          onTakeProfitOrderPricePercentChange={handleTakeProfitOrderPricePercentChange}
          onAddEntry={handleAddTakeProfitEntry}
          onRemoveEntry={handleRemoveTakeProfitEntry}
        />

        <StopLossSell
          setIsSkipStopLoss={setIsSkipStopLoss}
          onStopLossTriggerPriceChange={handleStopLossTriggerPriceChange}
          onStopLossOrderPriceChange={handleStopLossOrderPriceChange}
          onStopLossOrderPricePercentChange={handleStopLossOrderPricePercentChange}
        />
        
        <Button
          htmlType='submit'
          type='primary'
          disabled={!isFormValidExceptAdditionalEntries()}
        >
          Create trade
        </Button>
      </Box>
    
      <ConfirmTransaction
        isOpened={isModalVisible}
        closeHandler={() => setIsModalVisible(false)}
        confirmationTableData={confirmationData}
        collectedDataForSubmit={collectedData}
      />
    
    </>
  );
};

export default Sell;
