import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface UseSymbolEffectsProps {
    currentSymbol: any;
  }
  
export const useSymbolEffects = ({ 
  currentSymbol, 
}: UseSymbolEffectsProps) => {
  
  const {
    setValue, 
  } = useFormContext();
  
  const setIsSkipBaseOrder = useCallback((value: boolean) => {
    setValue('skipBaseOrder', value);
  }, [setValue]);
  
  useEffect(() => {
    setValue('currentSymbol', currentSymbol);
    setValue('addEntryEnabled', false);
    setValue('takeProfitEnabled', false);
    setValue('stopLossEnabled', false);
    setValue('additionalEntries', []);
    setValue('takeProfitEntries', []);
    setValue('baseAsset', currentSymbol.baseAsset);
    setValue('quoteAsset', currentSymbol.quoteAsset);
    setIsSkipBaseOrder(false);
  }, [
    currentSymbol,
    currentSymbol?.baseAsset, 
    currentSymbol?.quoteAsset, 
    setValue,
    setIsSkipBaseOrder,
  ]);
};
