import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getChartLastPrice } from '../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { Toggler } from '../../../shared/ui/Toggler/Toggler.tsx';
import { EntrySection } from '../EntrySection/EntrySection';
import {
  FIRST_ENTRY_SWITCHER,
  LIMIT,
  LIMIT_VALUE,
  TAKE_PROFIT_SECTION_TYPE,
  TAKE_PROFIT_SWITCHER,
  TAKE_PROFIT_SWITCHER_OPTIONS,
  TAKE_PROFIT_TOGGLER,
  TAKE_PROFIT_TOGGLER_TITLE,
  TAKE_PROFIT_USELESS_SWITCHER,
  USELESS_SWITCHER_OPTIONS,
} from '../exchange-form.constants.js';
import {
  marginTopSmall,
  sectionContainerStyles,
  tpSlContainerStyle,
} from '../exchange-form.styles.ts';
import { GenericSwitcher } from '../widgets/GenericSwitcher/GenericSwitcher.jsx';

export const TakeProfitSection = ({
  formDisabled,
}) => {
  const {
    setValue, watch, 
  } = useFormContext();

  const isTakeProfitToggled = watch(TAKE_PROFIT_TOGGLER);

  const lastPrice = useSelector(getChartLastPrice);

  useEffect(() => {
    if (isTakeProfitToggled === false) {
      setValue(FIRST_ENTRY_SWITCHER, LIMIT_VALUE);
    }
  }, [isTakeProfitToggled]);

  useEffect(() => {
    setValue(TAKE_PROFIT_TOGGLER, false);
  }, [lastPrice]);

  const [takeProfitOrders, setTakeProfitOrders] = useState([]);

  const [forceUpdate, setForceUpdate] = useState(0);

  const handleAddTakeProfitOrder = (newOrderData) => {
    const newOrder = {
      ...newOrderData,
      id: Date.now(),
    };
    setTakeProfitOrders([...takeProfitOrders, newOrder]);
  };

  const handleDeleteTakeProfitOrder = (orderId) => {

    setTakeProfitOrders((prevOrders) => 
      prevOrders.filter((order) => order.id !== Number(orderId)));

    setForceUpdate(forceUpdate + 1); 
  };

  const handleRemoveAllOrders = () => {
    setTakeProfitOrders([]);
  };
   
  return (
    <>
      <div style={sectionContainerStyles}>
        <Toggler
          disabled={formDisabled}
          togglerElementSectionName={TAKE_PROFIT_TOGGLER}
          title={TAKE_PROFIT_TOGGLER_TITLE}
        />

        {isTakeProfitToggled && (
          <GenericSwitcher
            options={USELESS_SWITCHER_OPTIONS}
            controlName={TAKE_PROFIT_USELESS_SWITCHER}
            containerStyle={tpSlContainerStyle}
          />
        )}
      </div>

      {isTakeProfitToggled && (
        <div>
          <div style={marginTopSmall}>
            <GenericSwitcher
              options={TAKE_PROFIT_SWITCHER_OPTIONS}
              controlName={TAKE_PROFIT_SWITCHER}
            />
          </div>

          <div>
            <EntrySection
              onAddOrder={handleAddTakeProfitOrder}
              onDeleteOrder={handleDeleteTakeProfitOrder}
              orders={takeProfitOrders}
              sectionType={TAKE_PROFIT_SECTION_TYPE}
              handleRemoveAllOrders={handleRemoveAllOrders}
            />
          </div>
        </div>
      )}
    </>
  );
};
