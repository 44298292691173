import { createSlice } from '@reduxjs/toolkit';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { addNewTrade } from '../services/add-new-trade';
import { setActiveTrades } from '../services/set-active-trades';
import { ChosenExchangeSymbolDetails, NewTerminalSchema, SmartTrade } from '../types/new-terminal-schema';

const initialState: NewTerminalSchema = {
  isLoading: false,
  activeTrades: [] as SmartTrade[],
  error: '',
  chosenExchangeSymbolDetails: {} as ChosenExchangeSymbolDetails,
  smartTrade: {} as SmartTrade,
  limitLastPrice: 0,
  chartLastPrice: 0,
  userWalletQuoteAssetInfo: {} as WalletData,
  userWalletBaseAssetInfo: {} as WalletData,
};

export const newTerminalSlice = createSlice({
  name: 'newTerminal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setActiveTrades.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setActiveTrades.fulfilled, (state, action) => {
      state.isLoading = false;
      state.activeTrades = action.payload;
    });
    builder.addCase(setActiveTrades.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ?? 'Unknown error';
    });
    
    builder.addCase(addNewTrade.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addNewTrade.fulfilled, (state, action) => {
      state.activeTrades.unshift(action.payload);
      state.isLoading = false;
    });
    builder.addCase(addNewTrade.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload ?? 'Oops error when creating trade';
    });
  },
});

export const {
  actions: newTerminalActions,
  reducer: newTerminalReducer,
} = newTerminalSlice;

