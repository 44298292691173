import { Box } from '@mui/material';
import { createDemoExchangeAccount } from 'entities/exchange/api/create-demo-exchange-account';
import { getExchangeAccounts } from 'entities/exchange/api/get-exchange-accounts';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { userActions } from 'entities/user/model/slices/user-slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSkyrexUuid, setDemoMode } from 'shared/helpers/storage-helper';
import { Verify } from 'shared/icons';
import { Link, MainButton, Text, Title } from 'shared/ui';
import { enableDemoAccount } from '../../../entities/user/api';
import { Tabs } from '../components';
import { IChooseExchange } from '../interfaces';
import {
  contentActions,
  contentButton,
  contentButtons,
  contentInner,
  contentLinks,
  inner,
  subTitle,
  text,
  title,
  titleWrapper,
  verifyIcon,
  wrapper,
} from '../styles';

export const ChooseExchange = (props: IChooseExchange) => {
  const {
    onChoose,
    changeStep,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleCreateCryptoAccount = () => {
    window.open('https://www.gate.io/referral/invite/SKYREXIO_0_102', '_blank', 'noopener,noreferrer');
  };

  const handleSwitchDemoAccount = async () => {
    setLoading(true);

    const response = await enableDemoAccount();
    if (!response?.data?.skyrexUserUuid) {
      toast.error('Cannot enable demo mode');
      return;
    }

    const accountsResponse = await getExchangeAccounts();
    const accounts = accountsResponse?.data?.accounts;

    const demoAccount = accounts?.find((account: any) => account.exchangeCode === 'demo');
    if (demoAccount) {
      changeStep?.(2);
      onChoose?.('');

      dispatch(exchangeActions.setAllExchanges(demoAccount));
      dispatch(userActions.setIsDemoMode(true));
      setDemoMode('demo');
      return;
    }
    
    const demoAccountResponse = await createDemoExchangeAccount();
    if (!demoAccountResponse?.data?.fields) {
      toast.error('Cannot create demo account');
      return;
    }

    const createdAccounts = await setExchangeData(getSkyrexUuid()!);
    dispatch(exchangeActions.setAllExchanges(createdAccounts));
    setLoading(false);
  };

  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={title}>
          Select exchange
        </Title>

        <Box sx={subTitle}>
          <Text>
            Connect your exchange account securely with fast OAuth or API keys 
          </Text>
        </Box>
      </Box>

      <Box sx={inner}>
        <Tabs
          onChoose={onChoose}
          description={(
            <Text
              type='secondary'
              styles={text}
            >
              Skyrexio is an official exchanges partner with no withdrawal or transfer access

              <span style={verifyIcon}>
                {Verify}
              </span>
            </Text>
          )}
        />

        <Box sx={contentInner}>
          <Box sx={contentActions}>
            <Text styles={title}>
              Don't have an exchange?
            </Text>

            <Box sx={contentButtons}>
              <MainButton
                size='large'
                styles={contentButton}
                onClick={handleCreateCryptoAccount}
              >
                Create Gate.io account
              </MainButton>

              <MainButton
                size='large'
                styles={contentButton}
                onClick={handleSwitchDemoAccount}
                loading={loading}
              >
                Switch to demo account
              </MainButton>
            </Box>
          </Box>

          <Box sx={contentLinks}>
            <Text>
              <Link href='https://www.gate.io/referral/invite/SKYREXIO_0_102'>
                Sign up
              </Link>
              {' '}
              at Gate.io with Skyrexio to get 15 USDT bonus and 500 USDT rebate
            </Text>

            <Text>
              Don’t see your exchange?
              {' '}
              <Link href='https://kamvvjb40tw.typeform.com/to/HvdNnr8Q?typeform-source=app.skyrexio.com'>
                Suggest us to add
              </Link>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
