import { bybitResolutionMap, okxResolutionMap, resolutionMap } from './constants.js';

export const resolutionToExchangeInterval = (resolution, exchange) => {

  
  
  if (exchange === 'bybit') {
    const bybitInterval = bybitResolutionMap[resolution];
 
    if (bybitInterval) {
      return bybitInterval;
    } else {
      return resolution;
    }
  }
  
  if (exchange === 'okx') {
    const okxInterval = okxResolutionMap[resolution];
    if (!okxInterval) {
      throw new Error('Resolution is not recognized ' + resolution);
    }
    return okxInterval;
  }
  
  
  if (exchange === 'gateio') {
    
    switch (resolution) {
    case '1': return '1m';
    case '5': return '5m';
    case '15': return '15m';
    case '30': return '30m';
    case '60': return '1h';
    case '240': return '4h';
    case '1D': return '1d';
    case '1W': return '7d';
    case '1M': return '30d';
    default: return '1h';
    }
  }
  
  if (exchange === 'crypto-com') {
    switch (resolution) {
    case '1': return '1m';
    case '5': return '5m';
    case '15': return '15m';
    case '30': return '30m';
    case '60': return '1h';
    case '120': return '2h';
    case '240': return '4h';
    case '720': return '12h';
    case '1D': return '1D';
    case '1W': return '7D';
    case '2W': return '14D';
    case '1M': return '1M';
    default: throw new Error('Resolution is not recognized for Crypto.com: ' + resolution);
    }
  }
  
  const binanceInterval = resolutionMap[resolution];
  if (!binanceInterval) {
    throw new Error('Resolution is not recognized ' + resolution);
  }
  return binanceInterval;
};
