import { Input, ConfigProvider, Select, Typography, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import './MultiInput.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseOrderPrice } from '../../../../../entities/terminal/model/selectors/get-base-order-price/get-base-order-price.ts';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol.ts';
import { BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME } from '../BaseOrderSection/BaseOrderInputs/BaseOrderInputs.jsx';
import COLORS from '../colors';
import { roundToTickSize } from '../exchange-form-utils.js';
import {
  BASE_ORDER_ORDER_PRICE_FIELD_NAME,
  MARKET_VALUE,
  SELECT_OPTIONS,
  STOP_LOSS_SWITCHER,
  TAKE_PROFIT_SWITCHER,
  TRIGGER_PRICE_LABEL,
} from '../exchange-form.constants';
import {
  priceGroupSectionContainerStyles,
  triggerPriceGroupContainer,
  triggerPriceInputStyles,
  triggerPriceSelectStyles,
} from '../exchange-form.styles.ts';

const {
  Text, 
} = Typography;

export const SectionsTriggerPriceGroup = ({
  priceInputControlName,
  selectControlName,
  //chartLastPrice
  baseOrderValue,
  defaultPrice,
  stopLoss,
}) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: {
      errors, 
    },
  } = useFormContext();
  
  const dispatch = useDispatch();

  const baseOrderPrice = useSelector(getBaseOrderPrice);

  const conditionalOrderSwitcherValue = watch(
    BASE_ORDER_CONDITIONAL_SWITCHER_FIELD_NAME,
  );

  const takeProfitSwitcherValue = watch(TAKE_PROFIT_SWITCHER);
  const stopLossSwitcherValue = watch(STOP_LOSS_SWITCHER);
  
  const TRIGGER_PRICE_PERCENT = 'stopLoss.triggerPricePercent';

  const baseOrderPriceField = watch(BASE_ORDER_ORDER_PRICE_FIELD_NAME);

  const isConditional = conditionalOrderSwitcherValue === MARKET_VALUE;

  const currentSymbol = useSelector(getCurrentSymbol);

  // useEffect(() => {
  //   clearErrors(priceInputControlName);
  // }, [stopLossSwitcherValue]);
  
  const handleChange = (value) => {
    setValue(selectControlName, value);
  };

  const handleFormatQuoteAsset = (value) => {
    if (!value && value !== 0) return '';
    
    const precision = (typeof currentSymbol?.quoteAssetPrecision === 'number' && currentSymbol.quoteAssetPrecision > 0)
      ? currentSymbol?.quoteAssetPrecision
      : 8; // Используем 8 знаков по умолчанию, если precision не определен
    
    // Преобразуем значение в число с фиксированным количеством знаков после запятой
    const formattedValue = Number(value).toFixed(precision);
    
    // Удаляем незначащие нули в конце
    return formattedValue.replace(/\.?0+$/, '');
  };
  
  // const handleFormatQuoteAsset = (value) => {
  //   const roundedValue = roundToTickSize(value, currentSymbol.priceTickSize);

  //   const formattedRounded = new Intl.NumberFormat('en-US', {
  //     maximumSignificantDigits: currentSymbol.quoteAssetPrecision,
  //     useGrouping: false,
  //   }).format(roundedValue);

  //   const formattedValue = new Intl.NumberFormat('en-US', {
  //     maximumSignificantDigits: currentSymbol.quoteAssetPrecision,
  //     useGrouping: false,
  //   }).format(value);

  //   return formattedValue;
  // };

  const calculatePercentage = (price, basePrice) => {
    return Math.round((price / basePrice - 1) * 100);
  };

  const handleTriggerPriceChange = (newValue) => {
    // Update inputValue in form
    clearErrors(priceInputControlName);
    const orderPriceRounded = roundToTickSize(
      newValue,
      currentSymbol.priceTickSize,
    );

    setValue(priceInputControlName, orderPriceRounded, {
      shouldValidate: true, 
    });

    const newPercentage = calculatePercentage(
      orderPriceRounded,
      baseOrderValue,
    );

    // Calculate and update triggerPercent
    // const newTriggerPercent = calculateTriggerPercent(baseOrderValue, newValue);
    setValue(
      TRIGGER_PRICE_PERCENT,
      newPercentage > 99 ? '>99%' : `${newPercentage}%`, {
        shouldValidate: true, 
      },
    );
  };

  useEffect(() => {
    const defaultRoundedPrice = roundToTickSize(
      defaultPrice,
      currentSymbol.priceTickSize,
    );
    setValue(priceInputControlName, defaultRoundedPrice);

    const initialTriggerPercent = calculatePercentage(
      defaultRoundedPrice,
      baseOrderValue,
    );
    setValue(TRIGGER_PRICE_PERCENT, initialTriggerPercent);
  }, [baseOrderValue, defaultPrice]);

  useEffect(() => {
    const priceField = getValues(priceInputControlName);
    const pricePercent = Math.round(
      (priceField / baseOrderPriceField - 1) * 100,
    );
    setValue(TRIGGER_PRICE_PERCENT, `${pricePercent}%`, {
      shouldValidate: true, 
    });
    // setValue(TRIGGER_PRICE_PERCENT, pricePercent, {shouldValidate: true});
  }, [baseOrderPriceField]);

  const triggerPercent = watch(TRIGGER_PRICE_PERCENT);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 29,
            fontSize: 10,
            width: 20,
          },
          Select: {
            fontSize: 10,
          },
        },
      }}
    >
      <>
        <div style={triggerPriceGroupContainer}>
          <div style={priceGroupSectionContainerStyles}>
            <div>
              <Text
                style={{
                  fontSize: 12, color: COLORS.primary, 
                }}
                type='secondary'
              >
                {TRIGGER_PRICE_LABEL}
              </Text>

              <Controller
                name={priceInputControlName}
                control={control}
                defaultValue={defaultPrice}
                rules={{
                  required: 'field is required',
                  //@ts-ignore
                  validate: {
                    notZero: value => value !== 0 || 'Value cannot be 0',
                  },
                }}
                render={({
                  field, fieldState: {
                    error, 
                  }, 
                }) => (
                  <InputNumber
                    {...field}
                    addonBefore={triggerPercent}
                    size='middle'
                    suffix={currentSymbol.quoteAsset}
                    style={triggerPriceInputStyles}
                    // The InputNumber component should already be controlled by React Hook Form.
                    // Ensure this is correctly set up to reflect the current field value and onChange.
                    controls={false}
                    formatter={handleFormatQuoteAsset}
                    onChange={handleTriggerPriceChange}
                  />
                )}
              />
            </div>

            <Controller
              name={selectControlName}
              control={control}
              defaultValue={SELECT_OPTIONS[0]}
              render={({
                field, 
              }) => (
                <Select
                  {...field}
                  style={triggerPriceSelectStyles}
                  onChange={handleChange}
                  options={SELECT_OPTIONS}
                />
              )}
            />
          </div>
        </div>

        {errors && stopLoss && errors.stopLoss?.triggerPrice && (
          <Text style={{
            color: 'red', fontSize: 11, 
          }}>
            {errors.stopLoss?.triggerPrice?.message}
          </Text>
        )}
      </>
    </ConfigProvider>
  );
};
