const SliderMark = ({
  label,
  value,
  isExchangePrice = false,
  labelColor,
  valueColor,
  distance,
  isTakeProfit = true,
  exchangeSide = false,
}) => {
  
  const labelStyles = {
    color: labelColor || (label === 'SL' ? 'red' : '#2ECD99'),
    fontSize: '9px',
    whiteSpace: 'nowrap',
  };

  const valueStyles = {
    color: valueColor || 'black',
    fontSize: '9px',
    marginLeft: '3px',
  };

  
  const isExchangeSideStyles = exchangeSide 
    ?  {
      right: '3px', 
    }
    : {
      left: '3px', 
    };
  
  
  const exchangePriceStyles = isExchangePrice ? {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: '4px',
    position: 'absolute',
    top: '-15px',
    zIndex: 1,
    ...isExchangeSideStyles,
  } : {};
  

  const markStyles = {
    display: 'flex',
    flexDirection: isExchangePrice ? 'column-reverse' : 'column',
    alignItems: 'center',
    cursor: 'default',
  };

  const positionStyles = isTakeProfit
    ? {
      right: '3px', 
    }
    : {
      left: '3px', 
    };
    
  const labelValueContainerStyles = isExchangePrice
    ? exchangePriceStyles
    : {
      display: 'flex',
      alignItems: 'baseline',
      marginTop: '4px',
      position: 'absolute',
      top: `${distance - 15}px`,
      ...positionStyles,
      zIndex: 1,
    };  
    

  return (
    <div style={markStyles}>
      <div
        style={{
          height: `${distance-8}px`,
          width: '1px',
          backgroundColor: '#9E9E9E',
          position: 'relative',
          zIndex: 0,
        }}
      />
      <div
        style={labelValueContainerStyles}
      >
        <span style={labelStyles}>
          {isExchangePrice ? `${label} %` : label}
        </span>
        <span style={valueStyles}>{value}</span>
      </div>
      
    </div>
  );
};

export default SliderMark;
