import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getUserSkyrexUuid } from 'entities/user/model/selectors/get-user-skyrex-uuid/get-user-skyrex-uuid';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getExchanges,
  getExchangesIds,
} from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import {
  getWrapperStyles,
  logo,
  platformLogo,
  title,
} from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import { exchangeIcons } from 'shared/consts/exchange-icons';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
} from 'shared/ui/modals';

export const CreateBitMart = (props: CreateExchangeProps) => {
  const {
    isModalView,
    returnToAllExchanges,
    handleClose,
  } = props;
  
  const uuid = useSelector(getUserSkyrexUuid);

  const [exchangeTitle, setExchangeTitle] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>('');
  const [error, setError] = useState<any>({
    isOpened: false,
    title: 'Failed to connect',
    description: 'Check API keys, permissions, IP whitelist or contact support',
    button: {
      title: 'Okay',
      action: () => {
        setError((prev: any) => ({
          ...prev,
          isOpened: false,
          title: 'Failed to connect',
          description: 'Check API keys, permissions, IP whitelist or contact support',
        }));
      },
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleClose?.();
  };

  const connectBitMartWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForConnectOauth = {
        uuid,
        exchangeTitle,
        exchangeName: 'bitmart',
        apiKey,
        apiSecret,
        passphrase: memo,
      };

      const bitmartConnectResponse = await connectExchange(dataForConnectOauth);

      if (bitmartConnectResponse.success) {
        const exchangesIdsResponse = await getExchangesIds(uuid);
        if (exchangesIdsResponse.success) {
          const {
            accounts,
          } = exchangesIdsResponse.data;
          const accountsIds = accounts.map(
            (account: ExchangeAccount) => account.exchangeAccountUuid,
          );

          const allAccountsData = await getExchanges(accountsIds);
          dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
          setIsLoading(false);
          setIsModalOpen(true);
          
          return;
        }
      }

      if (bitmartConnectResponse.data.message === 'Maximum number of active accounts reached. Please upgrade subscription') {
        setError((prev: any) => ({
          ...prev,
          isOpened: true,
          description: 'Maximum connected exchange accounts is reached',
          button: {
            ...prev.button,
            action: () => {
              navigate('/subscriptions');
            },
          },
        }));
        return;
      }

      setError((prev: any) => ({
        ...prev,
        isOpened: true,
        description: bitmartConnectResponse.data.message,
      }));
    } catch (error) {
      setError((prev: any) => ({
        ...prev,
        isOpened: true,
        title: 'Something\'s wrong',
        description: 'We will fix it soon, try again later or contact support',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };
  
  const handleChangeMemo = (value: string) => {
    setMemo(value);
  };

  const infoForm = (
    <Info
      returnToAllExchanges={returnToAllExchanges}
      actionBlock={(
        <ActionBlock
          button={{
            label: 'Connect BitMart',
            action: () => {},
            disabled: true,
          }}
          createAccount={{
            label: 'Sign up at BitMart with Skyrexio and get bonuses',
            link: 'https://www.bitmart.com/invite/VShg8M/en',
          }}
        />
      )}
    />
  );

  const form = (
    <Form
      returnToAllExchanges={returnToAllExchanges}
      type='binance'
      fields={[{
        label: 'Enter any name',
        value: exchangeTitle,
        placeholder: 'Name',
        onChange: handleChangeTitle,
      }, {
        label: 'Paste your API key',
        value: apiKey,
        placeholder: 'Key',
        onChange: handleChangeApiKey,
      }, {
        label: 'Paste your API secret',
        mask: true,
        value: apiSecret,
        placeholder: 'Secret',
        onChange: handleChangeApiSecret,
      }, {
        label: 'Memo',
        value: memo,
        placeholder: 'Input here',
        onChange: handleChangeMemo,
      }]}
      actionBlock={(
        <ActionBlock
          button={{
            label: 'Connect BitMart',
            action: connectBitMartWithApiKeys,
          }}
          createAccount={{
            label: 'Sign up at BitMart with Skyrexio and get bonuses',
            link: 'https://www.bitmart.com/invite/VShg8M/en',
          }}
        />
      )}
    />
  );

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />

      <EmptySubscription
        isOpen={error.isOpened}
        handleClose={() => {
          setError((prev: any) => ({
            ...prev,
            isOpened: false,
          }));
        }}
        modalTitle={error.title}
        modalDescription={error.description}
        modalButtonTitle={error.button.title}
        modalButtonAction={error.button.action}
        isError={error.isOpened}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title styles={title}>
              Connect
            </Title>

            <Box sx={platformLogo}>
              {exchangeIcons['bitmart']}
            </Box>

            <Title styles={title}>
              exchange
            </Title>
          </Box>

          <Tabs
            tabs={[{
              label: 'Fast OAuth',
              value: 0,
              disabled: true,
            }, {
              label: 'API keys',
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
            }}
            defaultValue={1}
            maxWidth='475px'
            content={[infoForm, form]}
          />
        </Box>
      </Box>
    </>
  );
};
