import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getChartLastPrice } from '../../../../../entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price.ts';
import { Toggler } from '../../../shared/ui/Toggler/Toggler.tsx';
import { EntrySection } from '../EntrySection/EntrySection';
import {
  CONDLIMIT,
  CONDLIMIT_VALUE,
  STOP_LOSS_SECTION_TYPE,
  STOP_LOSS_SWITCHER,
  STOP_LOSS_SWITCHER_OPTIONS,
  STOP_LOSS_TOGGLER,
  STOP_LOSS_TOGGLER_TITLE,
  STOP_LOSS_USELESS_SWITCHER,
  USELESS_SWITCHER_OPTIONS,
} from '../exchange-form.constants.js';
import {
  marginTopSmall,
  sectionContainerStyles,
  tpSlContainerStyle,
} from '../exchange-form.styles.ts';
import { GenericSwitcher } from '../widgets/GenericSwitcher/GenericSwitcher.jsx';

export const StopLossSection = ({
  formDisabled,
}) => {
  const {
    setValue, watch, 
  } = useFormContext();

  const isStopLossToggled = watch(STOP_LOSS_TOGGLER);

  const lastPrice = useSelector(getChartLastPrice);
  
  useEffect(() => {
    if (isStopLossToggled === false) {
      setValue(STOP_LOSS_SWITCHER, CONDLIMIT_VALUE);
    }
  }, [isStopLossToggled]);

  useEffect(() => {
    setValue(STOP_LOSS_TOGGLER, false);
  }, [lastPrice]);
  
  return (
    <>
      <div style={sectionContainerStyles}>
        <Toggler
          disabled={formDisabled}
          togglerElementSectionName={STOP_LOSS_TOGGLER}
          title={STOP_LOSS_TOGGLER_TITLE}
        />

        {isStopLossToggled && (
          <GenericSwitcher
            options={USELESS_SWITCHER_OPTIONS}
            controlName={STOP_LOSS_USELESS_SWITCHER}
            containerStyle={tpSlContainerStyle}
          />
        )}
      </div>

      {isStopLossToggled && (
        <div>
          <div style={marginTopSmall}>
            <GenericSwitcher
              options={STOP_LOSS_SWITCHER_OPTIONS}
              controlName={STOP_LOSS_SWITCHER}
            />
          </div>

          <div>
            <EntrySection sectionType={STOP_LOSS_SECTION_TYPE} />
          </div>
        </div>
      )}
    </>
  );
};
