import {
  EllipsisOutlined,
  ReloadOutlined,
  FundOutlined,
  StockOutlined,
  EditOutlined,
  ShareAltOutlined,
  CloseCircleOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';

export const currentExchangePriceStyles = {
  display: 'flex',
  position: 'absolute',
  top: '-50px',
};

export const getReturnPercentLabelsColor = (currentExchangePercent) => {
  return Number(currentExchangePercent) >= 0 ? '#2ECD99' : '#F32222';
};

export const dataStyles = {
  fontSize: '12px',
  display: 'block',
  color: '#9E9E9E',
};

export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: 'Order Type',
    dataIndex: 'orderType',
    key: 'orderType',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

export const ordersData = [
  {
    key: '1',
    type: 'Base order',
    orderType: 'Cond market',
    price: '0.027241 USDT',
    amount: '256.3 MATIC',
    total: '7.00 USDT',
    status: 'Completed',
  },
];

export const generateMenu = (
  trade,
  onCloseTradeByMarket,
  onCloseTradeByLimit,
  onCancelTrade,
  handleOpenByMarket,
  handleOpenByLimit,
) => {
  
  const baseOrder = trade?.orders?.find(
    (order) => order.viewType === 'base_order',
  );
  
  const forceOrderStepFirst = trade.orders.find((el) => el.viewType === 'force_order_step_first');
  const isForceOrderCompleted = forceOrderStepFirst?.status === 'completed';
  
  const isTradeCompleted = baseOrder?.status === 'completed' || isForceOrderCompleted;

  return (
    <Menu>
      <Menu.Item key='0' icon={<FundOutlined />} disabled={true}>
        Add funds
      </Menu.Item>

      {isTradeCompleted ? (
        <Menu.Item
          key='1'
          icon={<StockOutlined />}
          onClick={() => onCloseTradeByMarket('close_by_market')}
        >
          Close by Market
        </Menu.Item>
      ) : (
        <Menu.Item
          key='1'
          icon={<StockOutlined />}
          onClick={() => handleOpenByMarket('open_by_market')}
        >
          Open by Market
        </Menu.Item>
      )}

      {isTradeCompleted ? (
        <Menu.Item
          key='2'
          icon={<StockOutlined />}
          onClick={() => onCloseTradeByLimit('close_by_limit')}
        >
          Close by Limit
        </Menu.Item>
      ) : (
        <Menu.Item
          key='2'
          icon={<StockOutlined />}
          onClick={() => handleOpenByLimit('open_by_limit')}
        >
          Open by Limit
        </Menu.Item>
      )}

      <Menu.Item key='3' icon={<EditOutlined />} disabled={true}>
        Edit
      </Menu.Item>
      <Menu.Item key='4' icon={<ShareAltOutlined />} disabled={true}>
        Share
      </Menu.Item>

      <Menu.Item
        key='5'
        icon={<CloseCircleOutlined />}
        onClick={() => onCancelTrade('cancel')}
      >
        Cancel trade
      </Menu.Item>
    </Menu>
  );
};

export const smartTradeData = [
  {
    exchangeAccountUuid: '39aca843-85dd-44c7-a5d7-662d865aff06',
    smartTradeUuid: '0cdabec8-5163-48eb-ae90-09a2dbc1a354',
    status: 'active',
    baseSymbol: 'BTC',
    quoteSymbol: 'USDT',
    sideFirstStep: 'BUY',
    createdDate: '2024-02-28 16:21:47',
    lastChangeDate: '2024-02-28 16:21:50',
    finishedDate: null,
    originalBase: '0.00399900',
    originalQuote: '155.57954931',
    executedBaseFirst: '0.00099900',
    executedQuoteFirst: '61.07954931',
    executedBaseSecond: '0.00000000',
    executedQuoteSecond: '0.00000000',
    returnPercent: '-0.19206495',
    returnBase: null,
    returnQuote: '-0.11731240',
    currentExchangePrice: '61145.49000000',
    orders: [
      {
        viewType: 'stop_loss',
        orderType: 'CONDITIONAL_LIMIT',
        orderUuid: '0a5e400f-4255-49ba-a98b-395b6129e94f',
        side: 'SELL',
        status: 'await_send',
        triggerPrice: '27000.00000000',
        limitPrice: '27000.00000000',
        originalBase: null,
        executionPrice: null,
        executionBase: null,
        executionQuote: null,
        filledPercent: null,
      },
      {
        viewType: 'base_order',
        orderType: 'MARKET',
        orderUuid: '95a7676c-70ad-460f-8233-e05d033d736d',
        side: 'BUY',
        status: 'completed',
        triggerPrice: null,
        limitPrice: null,
        originalBase: '0.00099984',
        executionPrice: '61140.69000000',
        executionBase: '0.00099900',
        executionQuote: '61.07954931',
        filledPercent: '99.91567191',
      },
      {
        viewType: 'additional_base_order',
        orderType: 'CONDITIONAL_MARKET',
        orderUuid: 'b8d160b9-bf4f-48a7-8c30-a4305703fe0f',
        side: 'BUY',
        status: 'await_send',
        triggerPrice: '30000.00000000',
        limitPrice: null,
        originalBase: null,
        executionPrice: null,
        executionBase: null,
        executionQuote: null,
        filledPercent: null,
      },
      {
        viewType: 'additional_base_order',
        orderType: 'CONDITIONAL_LIMIT',
        orderUuid: '2ff0dd5f-b028-40e8-98b1-c262aa431fe4',
        side: 'BUY',
        status: 'await_send',
        triggerPrice: '32000.00000000',
        limitPrice: '32000.00000000',
        originalBase: null,
        executionPrice: null,
        executionBase: null,
        executionQuote: null,
        filledPercent: null,
      },
      {
        viewType: 'additional_base_order',
        orderType: 'LIMIT',
        orderUuid: '3e045b63-65d8-4798-b915-b6e2ac7f046a',
        side: 'BUY',
        status: 'active',
        triggerPrice: null,
        limitPrice: '32500.00000000',
        originalBase: '0.00100000',
        executionPrice: null,
        executionBase: '0.00000000',
        executionQuote: '0.00000000',
        filledPercent: '0.00000000',
      },
      {
        viewType: 'take_profit',
        orderType: 'CONDITIONAL_MARKET',
        orderUuid: '2b7ecd2b-49c9-4b22-82fa-6f73a0f2561c',
        side: 'SELL',
        status: 'await_send',
        triggerPrice: '70000.00000000',
        limitPrice: null,
        originalBase: null,
        executionPrice: null,
        executionBase: null,
        executionQuote: null,
        filledPercent: null,
        percent: '50.00000000',
      },
      {
        viewType: 'take_profit',
        orderType: 'LIMIT',
        orderUuid: '9c6064f3-a11e-4b31-a853-78f303d730fa',
        side: 'SELL',
        status: 'active',
        triggerPrice: null,
        limitPrice: '69000.00000000',
        originalBase: '0.00049900',
        executionPrice: null,
        executionBase: '0.00000000',
        executionQuote: '0.00000000',
        filledPercent: '0.00000000',
        percent: '50.00000000',
      },
    ],
  },
];

export const tradeModalData = {
  form: {
    baseSymbol: 'BTC',
    quoteSymbol: 'USDT',
    exchangeAccountUuid: 'ce708f45-adb8-4b5c-9c97-357dffb2f9c4',
    baseOrder: {
      side: 'BUY',
      type: 'LIMIT',
      limitPrice: 69874.1,
      unitsBase: 0.000499,
    },
    additionalBaseOrders: [
      {
        side: 'BUY',
        type: 'LIMIT',
        limitPrice: 66380.4,
        unitsBase: null,
      },
    ],
    stopLoss: {
      side: 'SELL',
      type: 'CONDITIONAL_LIMIT',
      typeTriggerPrice: 'LAST',
      triggerPrice: 62886.69,
      limitPrice: 62886.69,
    },
    takeProfits: [
      {
        side: 'SELL',
        type: 'LIMIT',
        limitPrice: 76861.51,
        percent: 100,
      },
      {
        side: 'SELL',
        type: 'LIMIT',
        limitPrice: 76861.51,
        percent: 100,
      },
    ],
  },
};
