// @ts-nocheck

import React, { FC } from 'react';
import './CurrencyWallet.css';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentSymbol } from '../../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol';
import { getUserWalletBaseAsset } from '../../../../../entities/terminal/model/selectors/get-user-wallet-base-asset/get-user-wallet-base-asset';
import { getWalletData } from '../../../../../entities/terminal/model/selectors/get-wallet-data/get-wallet-data';
import { BUY_SIDE, COINS_TOGGLER, SIDE_OPTION_SWITCHER } from '../exchange-form.constants';
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';

interface CurrencyWalletProps {
  availableCoinsCurrencyName: string;
  availableCoinsAmount: number;
  targetCoinCurrencyName: string;
}

export const CurrencyWallet: FC<CurrencyWalletProps> = ({}) => {
  const {
    watch, 
  } = useFormContext();

  const coinsExist = watch(COINS_TOGGLER, false);

  const walletData = useSelector(getUserWalletSelectedSymbol);
  const currentSymbol = useSelector(getCurrentSymbol);
  const userWalletBaseAsset = useSelector(getUserWalletBaseAsset);
  
  const side = watch(SIDE_OPTION_SWITCHER);
  
  const getSideTypeActionString = (skipBaseOrderToggler) => {
    const isBuySide = side === BUY_SIDE;
    
    
    if (skipBaseOrderToggler) {
      if (isBuySide) {
        return `Bought ${currentSymbol?.baseAsset ?? ''}`;
      } else {
        return `Sold ${currentSymbol?.baseAsset ?? ''}`;
      }
    } else {
      if (isBuySide) {
        return `Buy ${currentSymbol?.baseAsset ?? ''}`;
      } else {
        return `Sell ${currentSymbol?.baseAsset ?? ''}`;
      }
    }
  };
  
  const getSideTypeWalletCurrency = (skipBaseOrderToggler) => {
    const isBuySide = side === BUY_SIDE;
    
    
    if (skipBaseOrderToggler) {
      if (isBuySide) {
        return `${Number(userWalletBaseAsset?.free ?? '')?.toFixed(currentSymbol?.baseCurrencyChartPrecision)} ${currentSymbol?.baseAsset ?? ''}`;
      } else { 
        return `${Number(walletData?.free ?? '')?.toFixed(currentSymbol?.chartPrecision)} ${currentSymbol?.quoteAsset ?? ''}`;
      }
    } else {
      if (isBuySide) {
        return `${Number(walletData?.free ?? '')?.toFixed(currentSymbol?.chartPrecision)} ${currentSymbol?.quoteAsset ?? ''}`;
      } else {
        return `${Number(userWalletBaseAsset?.free ?? '')?.toFixed(currentSymbol?.baseCurrencyChartPrecision)} ${currentSymbol?.baseAsset ?? ''}`;
      }
    }
    
  };
  
  return (
    <div className='flex-container'>
      <span className='left-text'>
        {getSideTypeActionString(coinsExist)}
      </span>
      <div className='right-container'>
        <img src='/images/wallet.svg' alt='Wallet Icon' className='icon' />
        <span className='right-text'>
          {getSideTypeWalletCurrency(coinsExist)}
        </span>
        <img src='/images/info.svg' alt='Info Icon' className='icon' />
      </div>
    </div>
  );
};
