export function prepareDataForCreateTrade(data: any): any {
  const processFields = (obj: any) => {
    const numericFields = ['unitsBase', 'limitPrice', 'triggerPrice', 'boughtPrice'];
    const result: any = {};
  
    const typeMapping: { [key: string]: string; } = {
      'limit': 'LIMIT',
      'market': 'MARKET',
      'cond.limit': 'CONDITIONAL_LIMIT',
      'cond.market': 'CONDITIONAL_MARKET',
      'Skip first step': 'SKIP_FIRST_STEP',
    };
      
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'total') {
        continue;
      }
        
      if (numericFields.includes(key) && typeof value === 'string') {
        result[key] = parseFloat(value);
      } else if (key === 'typeTriggerPrice' && typeof value === 'string') {
        result[key] = value.toUpperCase();
      } else if (key === 'type' && typeof value === 'string') {
        result[key] = typeMapping[value] || value;
      } else if (Array.isArray(value)) {
        result[key] = value.map(item => processFields(item));
      } else if (typeof value === 'object' && value !== null) {
        result[key] = processFields(value);
      } else {
        result[key] = value;
      }
    }
  
    return result;
  };
  
  return processFields(data);
}
