import { Box } from '@mui/material';
import { MainButton } from 'shared/ui';
import { platfroms } from '../consts';
import { ITabs } from '../interfaces';
import { background, binanceLogo, bybitLogo, cryptoLogo, gateLogo, icon, inner, item, okxLogo } from '../styles';

const iconStyles: any = {
  binance: binanceLogo,
  okx: okxLogo,
  bybit: bybitLogo,
  gate: gateLogo,
  crypto: cryptoLogo,
  bitmart: binanceLogo,
};

export const Tabs = (props: ITabs) => {
  const {
    onChoose,
    description,
  } = props;

  const onClickHandler = (platform: string) => () => {
    onChoose(platform);
  };

  return (
    <Box sx={background}>
      <Box sx={inner}>
        {platfroms.map((platfrom) => (
          <MainButton
            key={platfrom.title}
            styles={item}
            ghost={true}
            onClick={onClickHandler(platfrom.title)}
          >
            <Box sx={icon}>
              <div style={iconStyles[platfrom.title]}>
                {platfrom.icon}
              </div>
            </Box>
          </MainButton>
        ))}
      </Box>

      {description}
    </Box>
  );
};
